import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8174d550"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-input-div" }
const _hoisted_2 = { class: "input-error" }
const _hoisted_3 = ["value", "change", "placeholder", "required", "readonly", "disabled"]
const _hoisted_4 = {
  key: 0,
  class: "error-tag"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      class: _normalizeClass([_ctx.color, _ctx.customClass, 'label'])
    }, _toDisplayString(_ctx.label) + _toDisplayString(_ctx.required ? '*' : ''), 3),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("input", {
        class: _normalizeClass(["form-text-input input", _ctx.highlight ? 'highlight' : '']),
        value: _ctx.modelValue,
        change: _ctx.isItErrors(),
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
        placeholder: _ctx.placeholder,
        required: _ctx.required,
        readonly: _ctx.readOnly,
        disabled: _ctx.disabled
      }, null, 42, _hoisted_3),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true),
      (_ctx.error !== '')
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.error), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}