import { Check, Checks } from '@/types/BankDetailsResults';
import { translate } from '@/i18n';
import { getStatusIcon } from '@/composables/formatKeyValuePairWithFunc';

export function useBankDetails() {
  const getChecksData = ['BANK_CODE', 'BRANCH_CODE', 'ACCOUNT_NUMBER', 'KEY'];
  const getChecksDataIbanBic = ['IBAN', 'BIC'];

  const getBankData = (checks: Checks) => {
    return {
      title: {
        value: translate('DOCUMENT_CONTROL.TABLE.RESULT.BANK_DETAILS.DIRECT_DEBIT'),
        class: 'bank-details-results__title'
      },
      bank_name: {
        value: checks.BANK_NAME,
        class: ''
      },
      bank_address: {
        value: checks.BANK_ADDRESS,
        class: 'bank-details-results__address'
      }
    };
  };

  const getChecks = (status: string) => {
    return getStatusIcon(status);
  };

  const getBankDetailsChecks = (checks: Checks) => {
    const checksDisplayed = ['DOCUMENT_ANALYZED', 'IBAN_VALIDITY', 'ACCOUNT_NUMBER_VALIDITY', 'BIC_CODE_FOUND', 'IBAN_FORMAT_VALID', 'IBAN_FOUND', 'IBAN_NOT_PUBLIC', 'TYPE_RECOGNIZED', 'QUALITY_ABOVE_MINIMUM_THRESHOLD'];
    return checks.CHECKS.filter((value: Check) => checksDisplayed.includes(value.check));
  };

  return { getBankData, getChecksData, getChecksDataIbanBic, getChecks, getBankDetailsChecks };
}