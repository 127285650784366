<script setup lang="ts">
import { translate } from "@/i18n";
import { phone } from "phone";
import { computed, ref } from "vue";
import { useStore } from "vuex";

import { TextInputType, getRegex } from "@/types/TextInputType";
import type { AccountantVerificationData } from "@/types/WarningListType";
import {
  applySirenFormat,
  formatSiren,
  getCountryOptions,
  getSirenRegex,
} from "@/utils/formatRegistrationNumber";

import { phoneIndicator } from "@/utils/phoneIndicator";

import Button from "@/components/button/Button.vue";
import ContentCard from "@/components/card/ContentCard.vue";
import PhoneInput from "@/components/input/PhoneInput.vue";
import TextInput from "@/components/input/TextInput.vue";
import CustomSelect from "@/components/select/CustomSelect.vue";

const emit = defineEmits<{
  "accountant-verification": [value: AccountantVerificationData];
}>();

const store = useStore();

const formData = ref({
  REGISTRATION_NUMBER: formatSiren("FR", ""),
  PHONE: "+33",
  EMAIL: "",
  COUNTRY: "FR",
});

const disableButton = ref(false);

const permissionsSorted = computed(() => store.getters.permissionsSorted);

const coutryOptions = computed(() =>
  getCountryOptions(permissionsSorted.value)
);

const SIREN_REGEX = computed(() => getSirenRegex(formData.value.COUNTRY));

const phoneNumber = computed(() => {
  const isPhoneIndicator = phoneIndicator.some(
    (el) => el.value === formData.value.PHONE
  );
  if (!formData.value.PHONE || isPhoneIndicator) return "";
  return formData.value.PHONE;
});

const isFormValid = computed(() => {
  const siren = formData.value.REGISTRATION_NUMBER;
  const email = formData.value.EMAIL;

  const isPhoneValid = !!phoneNumber.value
    ? phone(phoneNumber.value).isValid
    : true;
  const isEmailValid = !!email
    ? getRegex(TextInputType.EMAIL).test(email)
    : true;
  const isSirenValid = !!siren
    ? getRegex(SIREN_REGEX.value).test(siren.replaceAll(" ", ""))
    : true;

  return (
    (!!siren || !!email || !!phoneNumber.value) &&
    isPhoneValid &&
    isSirenValid &&
    isEmailValid
  );
});

const accountantVerification = () => {
  if (!isFormValid.value) return;

  disableButton.value = true;

  const data = {};

  const fields = {
    COUNTRY: !!formData.value.REGISTRATION_NUMBER && formData.value.COUNTRY,
    EMAIL: formData.value.EMAIL,
    REGISTRATION_NUMBER: formData.value.REGISTRATION_NUMBER?.replaceAll(
      " ",
      ""
    ),
    PHONE: phoneNumber.value,
  };

  Object.entries(fields).forEach(([key, value]) => {
    if (!!value) {
      Object.assign(data, { [key]: value });
    }
  });

  emit("accountant-verification", data);
};

const onSirenInput = (event: KeyboardEvent) => {
  formData.value.REGISTRATION_NUMBER = applySirenFormat(
    event,
    formData.value.REGISTRATION_NUMBER
  );
};
</script>

<template>
  <ContentCard
    :title="translate('ACCOUNTANT_VERIFICATION')"
    class="accountant-verification"
  >
    <form
      class="box-input-accountant-verification box-input-siren-form"
      @submit.prevent="accountantVerification"
    >
      <div class="box-input-siren-container">
        <div class="box-input-siren-input">
          <CustomSelect
            :label="translate('COMPANY.COUNTRY')"
            :options="coutryOptions"
            v-model="formData.COUNTRY"
            :disabled="disableButton"
          />
          <TextInput
            class="box-input-siren-form-input"
            :label="
              translate(`COMPANY.REGISTRATION_NUMBER.${formData.COUNTRY}`)
            "
            :type="SIREN_REGEX"
            @input="onSirenInput"
            v-model="formData.REGISTRATION_NUMBER"
            :disabled="disableButton"
          />
        </div>
        <div class="box-input-siren-input">
          <PhoneInput
            v-model:phone-value="formData.PHONE"
            :disable-phone-value="disableButton"
          />
          <TextInput
            class="box-input-siren-form-input"
            :label="translate('IDENTITY.EMAIL')"
            :type="TextInputType.EMAIL"
            v-model="formData.EMAIL"
            :disabled="disableButton"
          />
        </div>
        <div class="phone-input-message">
          <span>{{ translate("PHONE_INDICATION_MESSAGE") }}</span>
          <div>
            <span class="phone-input-message__example"
              >{{ translate("EXAMPLE") }} :</span
            ><span> +33612345678</span>
          </div>
        </div>
        <Button
          class="box-input-siren-form-button"
          type-button="submit"
          :label="translate('BUTTONS.VALIDATE')"
          :disabled="!isFormValid || disableButton"
        />
      </div>
    </form>
  </ContentCard>
</template>
