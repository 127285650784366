import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4ad35efd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "batch-page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BoxInputScoreBatch = _resolveComponent("BoxInputScoreBatch")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BoxInputScoreBatch, {
      class: "batch-input",
      "user-email": _ctx.user.email,
      "user-company": _ctx.user.company
    }, null, 8, ["user-email", "user-company"])
  ]))
}