<script setup lang="ts">
import {ref} from "vue";
import Sticker from "@/components/sticker/Sticker.vue";

const props = withDefaults(defineProps<{
  title: string,
  idModal: string,
  labelOpenModalButton: string,
  modalButtonDisabled: boolean,
  tooltipTitle: string,
  checkedDisplayContent: boolean,
  sticker: {
    label: string,
    background: string,
    textColor: string
  },
  isPadlock: boolean,
  printTitle: boolean,
  isBackButton: boolean
}>(), {
  title: '',
  idModal: '',
  labelOpenModalButton: '',
  modalButtonDisabled: false,
  tooltipTitle: '',
  checkedDisplayContent: true,
  sticker: {
    label: '',
    background: '',
    textColor: ''
  },
  isPadlock: false,
  printTitle: true,
  isBackButton: false
});

const emit = defineEmits(['content-card-closed', 'back-button-clicked']);

const checkedDisplayCt = ref(props.checkedDisplayContent);

const switchDisplay = () => {
  checkedDisplayCt.value = !checkedDisplayCt.value;
  emit('content-card-closed');
};

const handleBackClick = (event: Event) => {
  event.stopPropagation();
  emit('back-button-clicked');
};
</script>
<template>
  <div class="content-card breakinside">
    <div :class="`content-card-header ${printTitle ? '' : 'no-print'}`" @click="switchDisplay">
      <img v-if="isBackButton"
           class="content-card-header-back"
           @click="handleBackClick"
           :src="require('@/../public/images/expand-left-clear.png')"
           alt="back">
      <Sticker :label="sticker?.label" :background="sticker?.background" :text-color="sticker?.textColor"/>
      <label class="content-card-header-title" :id="checkedDisplayCt">{{ title }}</label>
      <template v-if="!isPadlock">
        <img v-if="checkedDisplayCt" class="content-card-header-arrow no-print"
             :src="require('@/../public/images/unfold.png')"
             alt="unfold">
        <img v-else class="content-card-header-arrow no-print" :src="require('@/../public/images/fold.png')" alt="fold">
      </template>
      <img v-else class="content-card-header-arrow content-card-header-padlock"
           :src="require('@/../public/images/padlock-grey.png')"
           alt="padlock-grey">
    </div>
    <div v-if="checkedDisplayCt && !isPadlock" class="content-card-content">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.content-card-header {
  &-back {
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin-right: 0.5rem;
  }
}
</style>