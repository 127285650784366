<script setup lang="ts">
import { translate } from '@/i18n';
import Button from '@/components/button/Button.vue';
import { capitalize } from 'lodash';

const props = withDefaults(
  defineProps<{
    ssoModes: Array<string>
  }>(), {});

const emit = defineEmits(['connexion-sso']);

const connexionSSO = (ssoMode: string) => {
  emit('connexion-sso', ssoMode);
};
</script>

<template>
  <div class="sso-input">
    <div class="sso-input__divider">
      <hr>
      <span>{{ translate('OR') }}</span>
      <hr />
    </div>
    <h5 class="sso-input__title">{{ translate('SSO.TITLE') }}</h5>
    <div class="sso-input__actions">
      <Button v-for="ssoMode in ssoModes" :label="capitalize(ssoMode)" :picture="`${ssoMode.toLowerCase()}.jpg`"
              @click="connexionSSO(ssoMode)" />
    </div>
  </div>
</template>