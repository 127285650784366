<script setup lang="ts">
import ContentCard from '@/components/card/ContentCard.vue';
import { formatLocaleDate, translate } from '@/i18n';
import ResultCard from '@/components/card/ResultCard.vue';

withDefaults(defineProps<{
  obData: any,
  isRecovery: boolean,
  canOpenBanking: boolean
}>(), {
  isRecovery: false,
  canOpenBanking: false
});

const translateAlertName = (name: string) => {
  switch (name) {
    case 'Absence of income':
      return translate('OPEN_BANKING.ALERT.ABSENCE_OF_INCOME.NAME');
    case 'Presence of rejects':
      return translate('OPEN_BANKING.ALERT.PRESENCE_OF_REJECTS.NAME');
    case 'Presence of unpaids':
      return translate('OPEN_BANKING.ALERT.PRESENCE_OF_UNPAIDS.NAME');
    case 'Presence of pensions':
      return translate('OPEN_BANKING.ALERT.PRESENCE_OF_PENSIONS.NAME');
    case 'Presence of gamblings':
      return translate('OPEN_BANKING.ALERT.PRESENCE_OF_GAMBLINGS.NAME');
    case 'Presence of allowances':
      return translate('OPEN_BANKING.ALERT.PRESENCE_OF_ALLOWANCES.NAME');
    case 'Presence of seizure fees':
      return translate('OPEN_BANKING.ALERT.PRESENCE_OF_SEIZURE_FEES.NAME');
    case 'Month negative balance':
      return translate('OPEN_BANKING.ALERT.MONTH_NEGATIVE_BALANCE.NAME');
    case 'Bank with extreme risk level':
      return translate('OPEN_BANKING.ALERT.BANK_WITH_EXTREME_RISK_LEVEL.NAME');
    case 'Bank with high risk level':
      return translate('OPEN_BANKING.ALERT.BANK_WITH_HIGH_RISK_LEVEL.NAME');
    case 'Transaction history date':
      return translate('OPEN_BANKING.ALERT.TRANSACTION_HISTORY_DATE.NAME');
    case 'Presence of cash collections':
      return translate('OPEN_BANKING.ALERT.PRESENCE_OF_CASH_COLLECTIONS.NAME');
    case 'Presence of cryptocurrencies':
      return translate('OPEN_BANKING.ALERT.PRESENCE_OF_CRYPTOCURRENCIES.NAME');
    case 'Transaction history count':
      return translate('OPEN_BANKING.ALERT.TRANSACTION_HISTORY_COUNT.NAME');
    case 'Presence of credits per month':
      return translate('OPEN_BANKING.ALERT.PRESENCE_OF_CREDITS_PER_MONTH.NAME');
    case 'Presence of intervention fees':
      return translate('OPEN_BANKING.ALERT.PRESENCE_OF_INTERVENTION_FEES.NAME');
    case 'Presence of withdrawals':
      return translate('OPEN_BANKING.ALERT.PRESENCE_OF_WITHDRAWALS.NAME');
    case 'Presence of internationals transfers':
      return translate('OPEN_BANKING.ALERT.PRESENCE_OF_INTERNATIONALS_TRANSFERS.NAME');
    case 'Transaction history for analysis.':
      return translate('OPEN_BANKING.ALERT.TRANSACTION_HISTORY_FOR_ANALYSIS.NAME');
    case 'Absence of taxe':
      return translate('OPEN_BANKING.ALERT.ABSENCE_OF_TAXE.NAME');
    case 'Loss of salary':
      return translate('OPEN_BANKING.ALERT.LOSS_OF_SALARY.NAME');
    case 'Absence of social fees':
      return translate('OPEN_BANKING.ALERT.ABSENCE_OF_SOCIAL_FEES.NAME');
    case 'Presence of autotransfers':
      return translate('OPEN_BANKING.ALERT.PRESENCE_OF_AUTOTRANSFERS.NAME');
    case 'Presence of unemployment allowances':
      return translate('OPEN_BANKING.ALERT.PRESENCE_OF_UNEMPLOYMENT_ALLOWANCES.NAME');
    case 'Account with banking supervision':
      return translate('OPEN_BANKING.ALERT.ACCOUNT_WITH_BANKING_SUPERVISION.NAME');
    case 'Account newly created':
      return translate('OPEN_BANKING.ALERT.ACCOUNT_NEWLY_CREATED.NAME');
    case 'Presence of fraudulent transfers':
      return translate('OPEN_BANKING.ALERT.PRESENCE_OF_FRAUDULENT_TRANSFERS.NAME');
    case 'New salary':
      return translate('OPEN_BANKING.ALERT.NEW_SALARY.NAME');
    case 'Number of currencies':
      return translate('OPEN_BANKING.ALERT.NUMBER_OF_CURRENCIES.NAME');
    case 'Balance Decreasing Trend':
      return translate('OPEN_BANKING.ALERT.BALANCE_DECREASING_TREND.NAME');
    default:
      return name;
  }
};

const translateAlertDescription = (description: string) => {
  switch (description) {
    case 'No income transaction has been detected':
      return translate('OPEN_BANKING.ALERT.ABSENCE_OF_INCOME.DESCRIPTION');
    case 'At least 1 transaction(s) with rejects has been detected':
      return translate('OPEN_BANKING.ALERT.PRESENCE_OF_REJECTS.DESCRIPTION');
    case 'At least 1 transaction(s) with unpaids has been detected':
      return translate('OPEN_BANKING.ALERT.PRESENCE_OF_UNPAIDS.DESCRIPTION');
    case 'At least 1 transaction(s) with pensions has been detected':
      return translate('OPEN_BANKING.ALERT.PRESENCE_OF_PENSIONS.DESCRIPTION');
    case 'At least 1 transaction(s) with gamblings has been detected':
      return translate('OPEN_BANKING.ALERT.PRESENCE_OF_GAMBLINGS.DESCRIPTION');
    case 'At least 1 transaction(s) with allowances has been detected':
      return translate('OPEN_BANKING.ALERT.PRESENCE_OF_ALLOWANCES.DESCRIPTION');
    case 'At least 1 transaction(s) with seizure fees has been detected':
      return translate('OPEN_BANKING.ALERT.PRESENCE_OF_SEIZURE_FEES.DESCRIPTION');
    case 'We have detected one, or more, days with negative balance':
      return translate('OPEN_BANKING.ALERT.MONTH_NEGATIVE_BALANCE.DESCRIPTION');
    case 'We have detected one, or more, account(s) from a bank considered to be extremely risky':
      return translate('OPEN_BANKING.ALERT.BANK_WITH_EXTREME_RISK_LEVEL.DESCRIPTION');
    case 'We have detected one, or more, account(s) from a bank considered to be very risky':
      return translate('OPEN_BANKING.ALERT.BANK_WITH_HIGH_RISK_LEVEL.DESCRIPTION');
    case 'Less than 2 month(s) of transaction history were transmitted for this analysis':
      return translate('OPEN_BANKING.ALERT.TRANSACTION_HISTORY_DATE.DESCRIPTION');
    case 'At least 1 transaction(s) with cash collections has been detected':
      return translate('OPEN_BANKING.ALERT.PRESENCE_OF_CASH_COLLECTIONS.DESCRIPTION');
    case 'At least 1 transaction(s) with cryptocurrencies has been detected':
      return translate('OPEN_BANKING.ALERT.PRESENCE_OF_CRYPTOCURRENCIES.DESCRIPTION');
    case 'Less than 10 transaction(s) were transmitted for this analysis':
      return translate('OPEN_BANKING.ALERT.TRANSACTION_HISTORY_COUNT.DESCRIPTION');
    case 'At least 3 transaction(s) with credits per month has been detected':
      return translate('OPEN_BANKING.ALERT.PRESENCE_OF_CREDITS_PER_MONTH.DESCRIPTION');
    case 'At least 1 transaction(s) with intervention fees has been detected':
      return translate('OPEN_BANKING.ALERT.PRESENCE_OF_INTERVENTION_FEES.DESCRIPTION');
    case 'At least 7 transaction(s) with withdrawals per month has been detected':
      return translate('OPEN_BANKING.ALERT.PRESENCE_OF_WITHDRAWALS.DESCRIPTION');
    case 'At least 1 transaction(s) with internationals transfers has been detected':
      return translate('OPEN_BANKING.ALERT.PRESENCE_OF_INTERNATIONALS_TRANSFERS.DESCRIPTION');
    case 'Not enough transaction were transmitted for good time analysis.':
      return translate('OPEN_BANKING.ALERT.TRANSACTION_HISTORY_FOR_ANALYSIS.DESCRIPTION');
    case 'Less than 1 month(s) of transaction history were transmitted for this analysis':
      return translate('OPEN_BANKING.ALERT.TRANSACTION_HISTORY_DATE_ONE_MONTH.DESCRIPTION');
    case 'No taxe transaction has been detected':
      return translate('OPEN_BANKING.ALERT.ABSENCE_OF_TAXE.DESCRIPTION');
    case 'Loss of salary has been detected':
      return translate('OPEN_BANKING.ALERT.LOSS_OF_SALARY.DESCRIPTION');
    case 'No social fees transaction has been detected':
      return translate('OPEN_BANKING.ALERT.ABSENCE_OF_SOCIAL_FEES.DESCRIPTION');
    case 'At least 50% of global incomes or outcomes are used for autotransfers':
      return translate('OPEN_BANKING.ALERT.PRESENCE_OF_AUTOTRANSFERS.DESCRIPTION');
    case 'At least 1 transaction(s) with unemployment allowances has been detected':
      return translate('OPEN_BANKING.ALERT.PRESENCE_OF_UNEMPLOYMENT_ALLOWANCES.DESCRIPTION');
    case 'We have detected one, or more, account(s) with banking supervision':
      return translate('OPEN_BANKING.ALERT.ACCOUNT_WITH_BANKING_SUPERVISION.DESCRIPTION');
    case 'We have detected one, or more, account(s) newly created':
      return translate('OPEN_BANKING.ALERT.ACCOUNT_NEWLY_CREATED.DESCRIPTION');
    case 'At least 1 transaction(s) with fraudulent transfers has been detected':
      return translate('OPEN_BANKING.ALERT.PRESENCE_OF_FRAUDULENT_TRANSFERS.DESCRIPTION');
    case 'New salary has been detected':
      return translate('OPEN_BANKING.ALERT.NEW_SALARY.DESCRIPTION');
    case 'At least 2 different currencies have been detected':
      return translate('OPEN_BANKING.ALERT.NUMBER_OF_CURRENCIES.DESCRIPTION');
    case 'We have detected a highly decreasing of the balance over time':
      return translate('OPEN_BANKING.ALERT.BALANCE_DECREASING_TREND.DESCRIPTION');
    default:
      return description;
  }
};

</script>

<template>
  <ContentCard v-if="!canOpenBanking" :checked-display-content="false" :is-padlock="true"
               :class="isRecovery ? 'container-openbanking-recovery container-openbanking-alerts' : 'container-openbanking-alerts'"
               :title="translate('OPEN_BANKING.ALERTS.TITLE')" />
  <template v-else>
    <ContentCard
      :class="isRecovery ? 'container-openbanking-recovery container-openbanking-alerts' : 'container-openbanking-alerts'"
      :title="translate('OPEN_BANKING.ALERTS.TITLE')">
      <div v-if="obData.alerts.length !== 0" class="container-openbanking-alerts-content">
        <div v-for="(alert, index) in obData.alerts" :key="index">
          <ResultCard :title="translateAlertName(alert.name)" class="container-openbanking-alerts-content-result">
            <div class="container-openbanking-alerts-content-result-description">
              <span>{{ translateAlertDescription(alert.description) }}</span>
            </div>
            <template v-if="alert.details !== null">
              <div v-for="(transaction, key) in alert.details.transactions" :key="key"
                   class="container-openbanking-alerts-content-result-transaction">
                <div>
                  <span>{{ formatLocaleDate(transaction.date) }}</span>
                </div>
                <div>
                  <span>{{ `${transaction.value} ${transaction.currency_code}` }}</span>
                </div>
              </div>
            </template>
          </ResultCard>
        </div>
      </div>
      <div v-else class="container-openbanking-alerts-message">
        <span>{{ translate('OPEN_BANKING.ALERTS.MESSAGE') }}</span>
      </div>
    </ContentCard>
  </template>
</template>
