<script setup lang="ts">
import Loader from '@/components/loader/Loader.vue';
import ContentCard from '@/components/card/ContentCard.vue';
import { formatLocaleDate, translate } from '@/i18n';
import ScoreCircleChart from '@/components/ScoreCircleChart.vue';
import StarRatings from '@/components/star-ratings/StarRatings.vue';
import { EReputationType, Review } from '@/types/EReputationType';
import { isNil } from 'lodash';

const props = withDefaults(
  defineProps<{
    showEReputationLoader: boolean,
    eReputationData: EReputationType,
    color: string,
    scoringEReputation: number,
    permissions: Array<string>,
    canEReputation: boolean
  }>(),
  {
    showEReputationLoader: false,
    color: '#D9D9D9',
    scoringEReputation: 0,
    canEReputation: false,
    eReputationData: {
      allWordsWordcloud: '',
      adjectivesWordcloud: '',
      companyAddress: '',
      googleLink: '',
      meanRate: 0,
      mostImportantWords: [''],
      mostRecentReviews: [{
        date: '',
        origin: '',
        rating: 0,
        review: '',
        username: '',
        translated_review: '',
        original_review: ''
      }],
      numberOfReviewsStudied: 0,
      phoneNumber: '',
      reliability: 0,
      topScam: false,
      totalReviews: 0,
      websiteLink: ''
    }
  }
);

const reviews = (review: Review) => {
  return review.hasOwnProperty('translated_review') ? review.translated_review : review.review;
};

const displayLogoOrigin = (origin: string) => {
  return `${origin.toLowerCase()}-logo.png`;
};
</script>

<template>
  <ContentCard v-if="!canEReputation" :title="translate('COMPANY.E_REPUTATION')" class="e-reputation"
               :checked-display-content="false" :is-padlock="true" />
  <ContentCard v-else :title="translate('COMPANY.E_REPUTATION')"
               class="e-reputation">
    <Loader v-if="showEReputationLoader" />
    <div v-else-if="eReputationData.totalReviews === 0 || scoringEReputation[0]?.isCancelled"
         class="e-reputation-error-message">
      <span>{{
          translate('ERRORS.E_REPUTATION')
        }}
      </span>
    </div>
    <div v-else class="e-reputation__content">
      <div class="e-reputation__content__first-block">
        <ScoreCircleChart
          v-if="permissions?.includes('KIT_B2B_E_REPUTATION_SCORE') && !scoringEReputation[0]?.isCancelled"
          :circle-value="Math.round(<number>scoringEReputation)" :circle-width="'35%'"
          :circleColor="color" class="e-reputation__content__first-block__score"
          :helper-text-display="false" :margin-circle="'0'" />
        <div v-if="eReputationData.meanRate && permissions?.includes('KIT_B2B_E_REPUTATION_RATE')"
             class="e-reputation__content__first-block__advice">
          <StarRatings v-model="eReputationData.meanRate" />
          <span>{{
              `${eReputationData.meanRate.toFixed(2)} / 5 ${translate('ON')} ${eReputationData.totalReviews} ${translate('ADVICE')}`
            }}</span>
        </div>
        <div v-else-if="!isNil(eReputationData.meanRate) && permissions?.includes('KIT_B2B_E_REPUTATION_RATE')"
             class="e-reputation__content__first-block__grade-not-found">
          <span>{{ translate('E_REPUTATION.AVERAGE_GRADE_NOT_FOUND') }}</span>
          <span>{{ `${eReputationData.totalReviews} ${translate('ADVICE')}` }}</span>
        </div>
      </div>
      <div class="e-reputation__content__second-block">
        <div
          v-if="eReputationData.mostRecentReviews && eReputationData.mostRecentReviews.length > 0  && permissions?.includes('KIT_B2B_E_REPUTATION_REVIEWS')"
          class="e-reputation__content__second-block__reviews">
            <span class="e-reputation__content__second-block__reviews__title">{{
                `${translate('E_REPUTATION.LIST_TEN_LAST_REVIEWS')} :`
              }}</span>
          <div v-for="(advice, key) in eReputationData.mostRecentReviews" :key="key"
               class="e-reputation__content__second-block__reviews__content breakinside">
            <div class="e-reputation__content__second-block__reviews__content__information">
                <span class="e-reputation__content__second-block__reviews__content__information__origin">
                  <img
                    class="e-reputation__content__second-block__reviews__content__information__origin__logo"
                    :src="require(`../../../public/images/${displayLogoOrigin(advice.origin)}`)"
                    :alt="`${displayLogoOrigin(advice.origin)}`" />{{
                    `${advice.origin}`
                  }}</span>
              <div class="e-reputation__content__second-block__reviews__content__information__right-block">
                <span>{{ `${formatLocaleDate(advice.date)}` }}</span>
                <StarRatings v-model="advice.rating" :star-size="20" />
              </div>
            </div>
            <span>{{ `${reviews(advice) ? reviews(advice) : translate('NO_COMMENT')}` }}</span>
          </div>
        </div>
        <div>
          <div v-if="eReputationData.adjectivesWordcloud && permissions?.includes('KIT_B2B_E_REPUTATION_ADJECTIVES')"
               class="e-reputation__content__second-block__cloud-adjectives breakinside">
            <span>{{ translate('COMPANY.ADJECTIVES_E_REPUTATION') }}</span>
            <div v-html="eReputationData.adjectivesWordcloud" />
          </div>
          <div
            v-if="eReputationData.allWordsWordcloud && permissions?.includes('KIT_B2B_E_REPUTATION_WORD_CLOUD')"
            class="e-reputation__content__second-block__cloud-word breakinside">
            <span>{{ translate('COMPANY.WORDING_CLOUD_E_REPUTATION') }}</span>
            <div v-html="eReputationData.allWordsWordcloud" />
          </div>
        </div>
      </div>
    </div>
  </ContentCard>
</template>