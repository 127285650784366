<script setup lang="ts">
import ContentCard from "@/components/card/ContentCard.vue";
import {translate} from "@/i18n";
import {computed, onMounted, ref} from "vue";
import TextInput from "@/components/input/InlineTextInput.vue";
import CustomSelect from "@/components/select/CustomSelect.vue";
import PhoneInput from "@/components/input/PhoneInput.vue";
import PhoneInputMessage from "@/components/message/PhoneInputMessage.vue";
import Button from "@/components/button/Button.vue";
import {TextInputType} from "@/types/TextInputType";
import {getInstantPaymentAuthorizedIbans} from "@/apiCalls/openbankingApi";
import {isEmpty, size} from "lodash";
import AmountInput from "@/components/input/AmountInput.vue";
import {Option} from "@/types/Option";

const emit = defineEmits(["generate-pis-link", "back-button-clicked"]);

const props = withDefaults(
    defineProps<{
      newInstantPaymentLoader: boolean;
    }>(), {
      newInstantPaymentLoader: false
    }
);

const request = ref({});
const phoneCountry = ref("+33");
const currencies = computed(() => ([{name: 'EUR', value: 'EUR'}]));
const phone = ref({disabled: false, value: "+33"});
const email = ref('');
const label = ref('');

const ibans = ref([] as Option[]);
const selectedIban = ref('');
const amount = ref('0.00');

const isButtonDisabled = computed(() => {
  const isAmountValid = amount.value && Number(amount.value) >= 1;
  const isIbanSelected = selectedIban.value;
  const hasPhoneOrEmail = phone.value.value !== "+33" || email.value.length > 0;
  const isLabelValid = label.value.length > 0 && label.value.length <= 250;

  return !isAmountValid || !isIbanSelected || !hasPhoneOrEmail || !isLabelValid;
});

const startNewInstantPayment = () => {
  if (isButtonDisabled.value) return;
  const phoneNumber = phone.value.value === phoneCountry.value
      ? {value: "", disabled: true}
      : phone.value;
  request.value = {
    mail: email.value || null,
    phoneNumber: isEmpty(phoneNumber.value) ? null : phoneNumber.value,
    country: 'FR',
    type: 'PIS',
    paymentProfile: {
      iban: selectedIban.value,
      name: ibans.value.find((i) => i?.value === selectedIban.value)?.name,
      amount: Number(amount.value),
      currencyCode: 'EUR',
      label: label.value,
    }
  };

  emit('generate-pis-link', request.value);
};

const handleBackButton = () => {
  emit('back-button-clicked');
}

const fetchAuthorizedIbans = async () => {
  const res = await getInstantPaymentAuthorizedIbans();
  const _ibans = res?.data?.ibans || [];
  ibans.value = _ibans.map((v: { name: string, iban: string }) => ({
    name: v.name,
    value: v.iban
  }));
  if (size(ibans.value) > 0) selectedIban.value = ibans?.value[0]?.value;
}

onMounted(async () => {
  await fetchAuthorizedIbans();
});
</script>

<template>
  <ContentCard :is-back-button="true" @back-button-clicked="handleBackButton" class="instant-payment"
               :title="translate('OPEN_BANKING.INSTANT_PAYMENT.SUBTITLE')">
    <div class="instant-payment-form">
      <span class="instant-payment-form-header">
        {{ translate('OPEN_BANKING.INSTANT_PAYMENT.FORM.FIRST_HEADER') }}
      </span>
      <CustomSelect
          class="instant-payment-form-select-account"
          :label="translate('OPEN_BANKING.INSTANT_PAYMENT.FORM.ACCOUNT')"
          v-model="selectedIban"
          :options="ibans"
          :disabled="false"
      />
      <div class="instant-payment-form-text-input">
        <AmountInput
            class="text"
            v-model="amount"
            :label="translate('OPEN_BANKING.INSTANT_PAYMENT.FORM.AMOUNT')"
            :disabled="false" />
        <CustomSelect
            class="select"
            :options="currencies"
            v-model="currencies[0].value"
            :disabled="true"
            label=""
        />
      </div>
      <div class="instant-payment-form-text-input">
        <TextInput
            class="text"
            :type="TextInputType.TEXT"
            v-model="label"
            :label="translate('OPEN_BANKING.INSTANT_PAYMENT.FORM.TITLED')"
        />
      </div>
      <div class="horizontal-divider"/>
      <span class="instant-payment-form-header">
        {{ translate('OPEN_BANKING.INSTANT_PAYMENT.FORM.SECOND_HEADER') }}
      </span>
      <div class="instant-payment-form-bottom-container">
        <PhoneInput
            v-model:phoneValue="phone.value"
            v-model:phoneCountry="phoneCountry"
            :required="true"
            :title="translate('IDENTITY.PHONE_NUMBER')"
            :disable-phone-value="phone.disabled"
        />
        <div class="instant-payment-form-text-input">
          <TextInput
              class="text"
              :type="TextInputType.EMAIL"
              v-model="email"
              :label="translate('IDENTITY.EMAIL')"
          />
        </div>
      </div>
      <div class="openbanking-informations__message">
        <PhoneInputMessage/>
      </div>
      <Button
          @click="startNewInstantPayment"
          :label="translate('BUTTONS.VALIDATE')"
          :disabled="isButtonDisabled"
          :loading="newInstantPaymentLoader"
          class="openbanking-informations__form__action"
      />
    </div>
  </ContentCard>
</template>