import { translate } from '@/i18n';
import { isNil } from 'lodash';

export interface ObData {
  // for open banking data
  startStudyPeriod: string;
  endStudyPeriod: string;
  topProAccount: boolean;
  scope: string | null;
  bankName: string;
  holderName: string;
  accountName: string;
  iban: string;
  topSecondaryAccount: string;
  monthSalaries: number;
  monthRents: number;
  monthEndRatio: number;
  balance: number;
  score: number;
  topBankingSupervision: string;
  topDeferredDebit: string;
  monthEndBalance: number;
  globalTransactionsCount: number;
  monthTransactionsCount: number;

  // for open banking details
  monthIncomesAmountMax: number;
  monthIncomesAmountMin: number;
  averageMonthlyEntries: number;
  monthOutcomesCreditsMean: number;
  monthOutcomesCreditsDateMean: number;
  monthOutcomesPensionsDateMean: number;
  balancePerDay: any;
  balancePerMonth: any;
  recommendedDebitDate: string;
  recommendedDebitAmount: number;
  monthNegativeBalanceMin: number;
  maxBalance: number;
  monthNegativeBalanceMax: number;
  remainsToLive: number;
  monthNegativeBalanceMean: number;
  debtRatio: number;
  monthOutcomesCreditsCountMean: number;
  netIncome: number;
  turnover: number;
  // monthSavings: number;
  monthOutcomesCreditsMin: number;
  monthOutcomesCreditsMax: number;
  monthOutcomesCreditsCountMin: number;
  monthOutcomesCreditsCountMax: number;
  monthOutcomesRentsMin: number;
  monthOutcomesRentsMax: number;
  monthOutcomesUtilitiesMin: number;
  monthOutcomesUtilitiesMax: number;
  bankCardPresence: string;
  bankCardQuantity: number;
  bankCardNumbers: string[] | '-';

  // for credit card & debits
  bankDirectDebitPresence: string;

  //multi requests
  multiRequests: {
    iban: number
  };

  obTaskId: string;

  // Continuous OpenBanking
  continuousAccess: boolean;
  refreshCount: number;
  isRefreshable: boolean;
  dateTimeFirstRefresh: string;
  dateTimeLastRefresh: string;
  dateTimeDeactivation: string;
  dateTimeSubmission: string;
  dateTimeFirstAggregation: string;
  dateTimeLastAggregation: string;
  dateTimeConsentExpiration: string;

  //outcomes
  monthOutcomesRentsAmountMean: string;
  monthOutcomesRentsAmountSum: string;
  monthOutcomesRentsAmountYearlyEstimation: string;
  monthOutcomesSalariesAmountMean: string;
  monthOutcomesSalariesAmountSum: string;
  monthOutcomesSalariesAmountYearlyEstimation: string;
  monthOutcomesCreditsAmountMean: string;
  monthOutcomesCreditsAmountSum: string;
  monthOutcomesCreditsAmountYearlyEstimation: string;
  monthOutComesCreditsAmountMin: string;
  monthOutComesCreditsAmountMax: string;
  monthOutcomesTaxesAmountMean: string;
  monthOutcomesTaxesAmountSum: string;
  monthOutcomesTaxesAmountYearlyEstimation: string;
  monthOutcomesAutoTransfersAmountMean: string;
  monthOutcomesAutoTransfersAmountSum: string;
  monthOutcomesAutoTransfersAmountYearlyEstimation: string;
  monthOutcomesAmountMean: string;
  monthOutcomesAmountSum: string;
  monthOutcomesAmountYearlyEstimation: string;
  monthOutcomesCreditsKeywords: string;
  monthOutcomesUtilitiesAmountMean: string;
  monthOutcomesUtilitiesAmountSum: string;
  monthOutcomesUtilitiesAmountYearlyEstimation: string;
  monthOutcomesSavingsAmountMean: string;
  monthOutcomesSavingsAmountSum: string;
  monthOutcomesSavingsAmountYearlyEstimation: string;
  monthOutcomesTransfersAmountMean: string;
  monthOutcomesTransfersAmountSum: string;
  monthOutcomesTransfersAmountYearlyEstimation: string;
  monthOutcomesSocialFeesMean: string;
  monthOutcomesSocialFeesSum: string;
  monthOutcomesSocialFeesYearlyEstimation: string;
  monthOutcomesOtherExpensesInclVatAmountMean: string;
  monthOutcomesOtherExpensesInclVatAmountSum: string;
  monthOutcomesOtherExpensesInclVatAmountYearlyEstimation: string;

  //incomes
  monthIncomesTransfersAmountMean: string;
  monthIncomesTransfersAmountSum: string;
  monthIncomesTransfersAmountYearlyEstimation: string;
  monthIncomesSalariesAmountMean: string;
  monthIncomesSalariesAmountSum: string;
  monthIncomesSalariesAmountYearlyEstimation: string;
  monthIncomesAllowancesAmountMean: string;
  monthIncomesAllowancesAmountSum: string;
  monthIncomesAllowancesAmountYearlyEstimation: string;
  monthIncomesPensionsAmountMean: string;
  monthIncomesPensionsAmountSum: string;
  monthIncomesPensionsAmountYearlyEstimation: string;
  monthIncomesAmountMean: string;
  monthIncomesAmountSum: string;
  monthIncomesAmountYearlyEstimation: string;
  monthIncomesSavingsAmountMean: string;
  monthIncomesSavingsAmountSum: string;
  monthIncomeSavingsAmountYearlyEstimation: string;
  monthIncomeLastSalaryDate: string;
}

export type OpenBankingTaskType = {
  taskId: string;
  [key: string]: any;
}

export enum OpenBankingScopes {
  PERSONAL = 'PERSONAL',
  PROFESSIONAL = 'PROFESSIONAL',
}

export type OpenBankingRequestScope = {
  PERSONAL?: string | null;
  PROFESSIONAL?: string | null;
}

export type OpenBankingRequestLink = {
  journeyId?: string;
  phoneNumber?: string | null;
  mail?: string | null;
  country: string;
  isContinuousAccess?: boolean;
  scope: OpenBankingRequestScope;
  paymentProfile?: OpenBankingPaymentProfile;
  type?: 'PIS' | 'AIS' ;
}

export type OpenBankingPaymentProfile = {
  iban: string;
  name: string;
  label: string;
  amount: string;
  currencyCode: string;
}

export type OpenBankingCompanyIbanData = {
  iban: string;
  name: string;
}

export type OpenBankingInstantPayment = {
  data?: {
    status: string;
    statusReason: string;
  }
  paymentProfile: {
    amount: string;
    name: string;
    currencyCode?: string;
    currency: string;
    iban: string;
    label: string;
  };
  requestOptions: {
    country: string;
    email: string;
    phoneNumber?: string;
    phone?: string;
  }
  status?: string;
  taskId?: string;
  type?: string;
}

export interface SearchData {
  date?: string;
  category?: string;
  balance?: string;
  label?: string;
}

export type OpenBankingTask = {
  taskId: string;
}

export enum Routes {
  OPEN_BANKING = '/open-banking',
  ANALYSIS_B2B = '/analysis-b2b',
  ANALYSIS_B2C = '/analysis-b2c',
  SYNTHESIS = '/synthesis',
  RECOVERY = '/recovery',
  BACK_OFFICE = '/back-office',
  DOCUMENT_ANALYSIS = '/document-analysis',
  LOGIN = '/login',
}

export function amountFormatter(value: any) {
  if (isNil(value)) return '';
  return ('' + value)?.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export function formatBalance(data: Array<any>, type: string) {
  let formatted: any[] = [];
  if (type === 'date') {
    data.forEach((element: any) => {
      formatted.push(new Date(element[type]).toLocaleDateString());
    });
  } else {
    data.forEach((element: any) => {
      formatted.push(element[type]);
    });
  }
  return formatted.reverse();
}

export const formatIcon: (str: string) => string = (str) => {
  // to match the icon file name ex: income-utility.png
  return str.toLowerCase().replaceAll(':', '-');
};

export const formatCategory = (str: string) => {
  const strSplit = str.split(':', 2);
  const category = strSplit[0];
  const subCategory = str.substring(str?.indexOf(':') + 1);
  const translateFormat = 'OPEN_BANKING.ACCOUNT_STATEMENT.CATEGORY.';
  return translate(`${translateFormat}${category}.${subCategory}`);
};

export const formatDate = (str: string) => {
  return new Date(str).toLocaleDateString();
};

export const formatLabel = (str: string) => {
  // formatting label ex : "SALAIRE" => "Salaire"
  if (isNil(str)) return '';
  return str.toLowerCase().split(' ').map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }).join(' ');
};

export const formatNumber = (num: any) => {
  // formatting the amount ex: "200" => "200.00"
  return num.toFixed(2);
};

export const isBooked = (str: string) => {
  return str === 'BOOKED';
};

export const transColorCode = (str: string) => {
  // an outcome
  if (str.includes('OUTCOME')) {
    // for savings transactions
    if (str.includes('SAVING')) {
      return 'orangeFont';
    }
    return 'redFont';
  } else {
    return 'greenFont';
  }
};
