import api from "@/utils/api";
import envConfig from "@/utils/envConfig";

type CheckIbanRequest = {
  lastName: string;
  firstName: string;
  birthDate: string;
  country: string;
  iban: string;
};

interface CheckIbanCompanyRequest {
  iban: string;
  provider: string;
  country: string;
  registrationNumber: string;
}

export const checkIbanNaturalPerson = (
  journeyId: string,
  data: CheckIbanRequest
) =>
  api
    .put(
      `${envConfig.CHECK_IBAN_ROUTE}/v1/check/iban?journeyId=${journeyId}`,
      {
        checkIbanType: "PERSONAL_IBAN",
        customerProfile: {
          lastName: data.lastName,
          firstName: data.firstName,
          birthDate: data.birthDate,
          country: data.country,
        },
        iban: data.iban.replaceAll(" ", "")
      }
    )
    .then((response) => response);

export const checkIbanCompany = (
  journeyId: string,
  data: CheckIbanCompanyRequest
) =>
  api
    .put(
      `${envConfig.CHECK_IBAN_ROUTE}/v1/check/iban?journeyId=${journeyId}`,
      {
        checkIbanType: "COMPANY_IBAN",
        companyProfile: {
          address: {
            country: data.country,
          },
          registrationNumber: data.registrationNumber,
        },
        iban: data.iban.replaceAll(" ", "")
      }
    )
    .then((response) => response);

export const getBankName = async (iban: string, journeyId: string) =>
  api
    .put(
      `${
        envConfig.CHECK_IBAN_ROUTE
      }/v1/bank/search?journeyId=${journeyId}&iban=${iban.replaceAll(" ", "")}`
    )
    .then((response) => response);

export const runCheckIbanBatch = ({
  requestId,
  email,
  file,
  fileName,
}: {
  requestId: string;
  email: string;
  file: any;
  fileName?: string;
}) => {
  const formData = new FormData();
  formData.append("file", file);
  return api
    .post(
      `${envConfig.CHECK_IBAN_ROUTE}/v1/batch/check-iban/${requestId}/${email}${
        fileName !== "" ? `?fileName=${fileName}` : ""
      }`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    )
    .then((response) => response.data);
};

export const downloadCheckIbanBatchTemplate = (requestId: string) => {
  return api
    .get(`${envConfig.CHECK_IBAN_ROUTE}/v1/batch/template/${requestId}`, {
      responseType: "blob",
    })
    .then((response) => response.data);
};

export const downloadCheckIbanBatchResult = (documentId: string) => {
  return api
    .get(`${envConfig.CHECK_IBAN_ROUTE}/v1/batch/download/${documentId}`, {
      responseType: "blob",
    })
    .then((response) => response.data);
};

export const cancelCheckIbanBatch = (taskId: string) => {
  return api
    .delete(`${envConfig.CHECK_IBAN_ROUTE}/v1/batch/cancel/${taskId}`)
    .then((response) => response.data);
};
