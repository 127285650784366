<script setup lang="ts">
import { computed, ref } from "vue";
import moment from "moment";

import { translate } from "@/i18n";

const error = ref("");

const props = defineProps({
  modelValue: { default: "" },
  label: { default: "" },
  placeholder: { default: "" },
  readOnly: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  highlight: { type: Boolean, default: false },
});

const emits = defineEmits(["update:modelValue"]);

const max = computed(() => {
  const today = new Date();
  let dd: string | number = today.getDate();
  let mm: string | number = today.getMonth() + 1;
  const yyyy = today.getFullYear();

  if (dd < 10) {
    dd = '0' + dd;
  }

  if (mm < 10) {
    mm = '0' + mm;
  } 

  return `${yyyy}-${mm}-${dd}`
})

const isDateValid = (dateString: string) => {
  const [year, month, day] = dateString.split("-").map(Number);
  const date = new Date(year, month - 1, day);
  const today = new Date();

  // Vérifier si les éléments de la date correspondent à ceux fournis
  // et si la date résultante est valide
  return (
    date.getFullYear() <= today.getFullYear() &&
    date.getFullYear() === year &&
    date.getMonth() === month - 1 &&
    date.getDate() === day &&
    !isNaN(date.getTime())
  );
};

const formatDate = (date: string) => {
  return moment(date.trim().replaceAll("/", "-")).format("YYYY-MM-DD");
};

// const isItErrors = (event: FocusEvent) => {
//   const el = event.target as HTMLInputElement;
//   el.value = formatDate(el.value);
//   el.type = "date";
//   if (!isDateValid(props.modelValue)) {
//     error.value = translate("REGEX.INVALID_DATE");
//   } else {
//     error.value = "";
//   }
// };

const isDateInvalid = (date: string) => {
  if (!isDateValid(date)) {
    emits("update:modelValue", "");
    error.value = translate("REGEX.INVALID_DATE");
    return true;
  }
  error.value = "";
  return false;
};

const handleDblClick = (event: MouseEvent) => {
  const el = event.target as HTMLInputElement;
  el.type = "text";
  setTimeout(() => {
    el.select();
  });
};

const handleEnter = (event: KeyboardEvent) => {
  const el = event.target as HTMLInputElement;
  if (event.key === "Enter") {
    el.value = formatDate(el.value);
    if (isDateInvalid(el.value)) el.value = "";
    el.type = "date";
  }
};

const handleFocusOut = (event: FocusEvent) => {
  const el = event.target as HTMLInputElement;
  el.value = formatDate(el.value);
  if (isDateInvalid(el.value)) el.value = "";
  el.type = "date";
};
</script>

<template>
  <div class="calendar-input">
    <label>{{ label }}</label>
    <input
      v-on:focusout="handleFocusOut"
      v-on:dblclick="handleDblClick"
      v-on:keypress="handleEnter"
      class="calendar-input-date input"
      :class="highlight ? 'highlight' : ''"
      type="date"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target?.value)"
      :placeholder="placeholder"
      :readonly="readOnly"
      :disabled="disabled"
      :max="max"
    />
    <span v-if="error !== ''" class="calendar-input-error-tag">{{
      error
    }}</span>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/variables";

input {
  &:disabled {
    background-color: lightgray;
  }
}

.calendar-input {
  display: grid;

  label {
    font-weight: lighter;
    color: $loginColor1;
    padding: 0 0 0 0.2rem;
    font-size: $fontSize;
  }

  &-date {
    color: rgb(60, 66, 87);
  }

  &-error-tag {
    border: solid 0;
    border-radius: 5px;
    font-size: $fontSizeError;
    font-weight: bold;
    background-color: $redMeelo;
    color: white;
    margin: 0.4vh 0;
    padding: 0 0.3vw;
  }
}

.personal-info {
  .calendar-input {
    margin-bottom: 1rem;
  }
}

.highlight {
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(58 151 212 / 36%) 0px 0px 0px 4px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px;
}
</style>
