<script setup lang="ts">
import { computed, ref } from 'vue';

import i18n, { translate } from '@/i18n';
import { Locale, switchLocale } from '@/utils/switchLocale';

import FranceFlag from '../../public/images/flags/france.png';
import GermanyFlag from '../../public/images/flags/germany.png';
import ItalyFlag from '../../public/images/flags/italy.png';
import NetherlandsFlag from '../../public/images/flags/netherlands.png';
import PortugalFlag from '../../public/images/flags/portugal.png';
import SpainFlag from '../../public/images/flags/spain.png';
import UKFlag from '../../public/images/flags/uk.png';

const currentLocale = ref(i18n.global.locale.value);

const locales = {
  de: {
    label: 'Deutsch',
    imgUrl: GermanyFlag,
    value: 'de'
  },
  en: {
    label: 'English',
    imgUrl: UKFlag,
    value: 'en'
  },
  es: {
    label: 'Español',
    imgUrl: SpainFlag,
    value: 'es'
  },
  fr: {
    label: 'Français',
    imgUrl: FranceFlag,
    value: 'fr'
  },
  it: {
    label: 'Italiano',
    imgUrl: ItalyFlag,
    value: 'it'
  },
  nl: {
    label: 'Nederlands',
    imgUrl: NetherlandsFlag,
    value: 'nl'
  },
  pt: {
    label: 'Português',
    imgUrl: PortugalFlag,
    value: 'pt'
  }
};

const switchLocaleAndSetCurrent = (locale: Locale) => {
  currentLocale.value = locale;
  switchLocale(locale);
};

const selectedLocale = computed(() => locales[currentLocale.value as Locale]);
const otherLocales = computed(() => {
  const _locales = Object.assign({}, locales);
  delete _locales[currentLocale.value as Locale];
  return Object.values(_locales);
});
</script>

<template>
  <div class="switch-lang">
    <div class="current-lang">
      <img :src="selectedLocale.imgUrl" class="lang-flag" :alt="selectedLocale.imgUrl" />
      <p>{{ selectedLocale.label }}</p>
      <svg
        :fill="`${$route.path.startsWith('/back-office') ? '#ffffff' : '#293354'}`"
        height="15px"
        width="15px"
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 330 330"
        xml:space="preserve"
      >
        <path
          id="XMLID_225_"
          d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
	        c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
	        s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"
        />
      </svg>
    </div>
    <div class="lang-dropdown">
      <div
        v-for="locale in otherLocales.sort((a, b) => a.value > b.value ? 1 : -1)"
        class="selecting-lang"
        @click="switchLocaleAndSetCurrent(locale.value as Locale)"
      >
        <img :src="locale.imgUrl" class="lang-flag" :alt="locale.imgUrl" />
        <p>{{ translate(locale.label) }}</p>
      </div>
    </div>
  </div>
</template>
