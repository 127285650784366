<script setup lang="ts">
import { ref, defineProps, defineEmits, watch } from 'vue';

const props = defineProps<{
  isChecked: boolean;
  title: string;
  subtitle: string;
}>();

const emit = defineEmits(['update:isChecked']);

const localChecked = ref(props.isChecked);

const toggleCheck = () => {
  localChecked.value = !localChecked.value;
  emit('update:isChecked', localChecked.value);
};

watch(() => props.isChecked, (newVal) => {
  localChecked.value = newVal;
});
</script>

<template>
  <div class="checkbox-container" >
    <div class="checkbox-title">{{ title }}</div>
    <input
        type="checkbox"
        :checked="props.isChecked"
        @click="toggleCheck"
    />
    <div class="checkbox-subtitle">{{ subtitle }}</div>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";

.checkbox-container {
  display: grid;
  grid-template-columns: 7.5rem auto 1fr;
  align-items: center;
}

.checkbox {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.checkbox-title {
  font-weight: lighter;
  padding: 0 0 0 0.2rem;
  font-size: $fontSizeTwelveSize;
}

.checkbox-subtitle {
  text-align: left;
  font-weight: lighter;
  font-size: $fontSizeTwelveSize;
  color: $grey;
  padding: 0 0 0 1.3rem;
}
</style>