import api from '@/utils/api';
import envConfig from '@/utils/envConfig';
import { AxiosResponse } from 'axios';
import { KitResponse } from '@/store';
import { isValidInput, isValidPassword } from '@/utils/filterUserPayload';

export interface ApiUserResponseDto {
  userId: number;
  username: string;
  companyId: number;
  companyName: string;
  roles: string[];
  email: string;
  phone: string;
  function: string;
  enabled: boolean;
  group: string;
  place: string;
  isTwoFactorAuthenticationRequiredByCompany: boolean;
  twoFactorAuthenticationStatus: 'SMS' | 'EMAIL' | 'DISABLED';
  isTwoFactorAuthenticationChecked: boolean;
  supervisor?: { username: string, id: number };
  profiles: string[];
}

export interface ApiUserDtoV2 {
  userId: number;
  username: string;
  company: {
    id: number;
    name: string;
    technicalName: string;
    twoFactorAuthenticationRequired: boolean;
  };
  authorities: { authority: string }[];
  companyParameters: {
    [key: string]: any;
  };
  group: string,
  place: string,
  tokenData?: any;
  twoFactorAuthenticationStatus: 'SMS' | 'EMAIL' | 'DISABLED';
}

export interface AuthenticationResponse {
  user: ApiUserDtoV2,
  token: string,
  expirationInstant: string,
}

const authenticateBasic = (username: string, password: string): Promise<AxiosResponse<AuthenticationResponse>> => {
  if (isValidInput(username) && isValidPassword(password)) {
    const Authorization = 'Basic ' + window.btoa(`${username}:${password}`);
    return api.post(`${envConfig.BACKEND_ROUTE}/authenticate`,
      null, { headers: { Authorization } });
  }
  throw new Error('Invalid username or password!');
};

const authenticate2FA = (secret: string, token: string): Promise<AxiosResponse<AuthenticationResponse>> => {
  return api.post(`${envConfig.BACKEND_ROUTE}/authenticate/2fa?access_token=${token}`,
    { secret });
};

const setup2FA = (status: string, token?: string, phone?: string): Promise<AxiosResponse<never>> =>
  api.post(`${envConfig.BACKEND_ROUTE}/authenticate/2fa/setup${!!token ? '?access_token=' + token : ''}`,
    { status, phone });

const validate2FAsetup = (secret: string, token?: string): Promise<AxiosResponse<AuthenticationResponse>> => {
  return api.post(`${envConfig.BACKEND_ROUTE}/authenticate/2fa/setup/validate${!!token ? '?access_token=' + token : ''}`,
    { secret });
};

const getMe = (): Promise<AxiosResponse<ApiUserResponseDto>> =>
  api.get(`${envConfig.IAM_ROUTE}/v5/me`);

const getParameters = (): Promise<AxiosResponse<{ [key: string]: string }>> => {
  return api.get(`${envConfig.IAM_ROUTE}/v5/services/PORTAL/parameters`);
};

const getKits = (): Promise<AxiosResponse<KitResponse[]>> => {
  return api.get(`${envConfig.IAM_ROUTE}/v5/me/kits`);
};

const getCompanyParameters = (): Promise<AxiosResponse<never>> =>
  api.get(`${envConfig.IAM_ROUTE}/v5/services/PORTAL/parameters`);

export const getMyTeam = () =>
  api.get(`${envConfig.IAM_ROUTE}/v5/me/team`)
    .then(response => response.data);

export const getSSOMode = () =>
  api.get(`${envConfig.BACKEND_ROUTE}/toolbox/sso`)
    .then(response => response.data);

export default {
  authenticateBasic,
  authenticate2FA,
  setup2FA,
  validate2FAsetup,
  getMe,
  getParameters,
  getKits,
  getCompanyParameters,
  getMyTeam,
  getSSOMode
};
