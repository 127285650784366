import { defineComponent } from 'vue';
import Loader from '@/components/loader/Loader.vue';
import PieChart from '@/components/chart/PieChart.vue';
import ResultCard from '@/components/card/ResultCard.vue';
import Button from '@/components/button/Button.vue';
import { translate } from '@/i18n';
import {
  getAllTasks,
  myCompanyConsumption,
  myConsumption,
  myTeamConsumption, userConsumption
} from '@/apiCalls/task/consumption/consumption';

export const typeToDisplay = ['OCR', 'AUTHENTICITY', 'CHECK_VALUE', 'COMPANY_DETAILS_INTERNATIONAL', 'COMPANY_DETAILS', 'SEARCH', 'SEARCH_CUSTOMER',
  'GET_LINKEDIN_PROFILE', 'OPENBANKING', 'SCORING'];

export const subTypeToDisplay = ['ID_CHECK', 'DRIVING_LICENSE', 'ID_CARD', 'BANK_DETAILS', 'TAX_REPORT', 'UNKNOWN', 'KBIS', 'COMPANY_IBAN',
  'PERSONAL_IBAN', 'MEELO_OPENBANKING_PERSONAL_SCORING_MODEL', 'MEELO_OPENBANKING_PROFESSIONAL_SCORING_MODEL', 'ELLISPHERE_SCORE', 'CREDIT_SAFE_SCORE', 'WEB_SCORE_B2B_V1',
  'WEB_SCORE_B2C_V3', 'E_REPUTATION_SCORE_V1', 'ALTARES_SCORE', 'SMB_SCORE_V1', 'WEB_SCORE_B2B_V2', 'E_REPUTATION_SCORE_V2', null];

export const serviceToDisplay = ['OCR_ID_CHECK', 'OCR_DRIVING_LICENSE', 'OCR_ID_CARD', 'AUTHENTICITY_BANK_DETAILS', 'AUTHENTICITY_TAX_REPORT',
  'AUTHENTICITY_UNKNOWN', 'AUTHENTICITY_KBIS', 'CHECK_VALUE_COMPANY_IBAN', 'CHECK_VALUE_PERSONAL_IBAN', 'COMPANY_DETAILS_INTERNATIONAL',
  'COMPANY_DETAILS', 'SEARCH', 'SEARCH_CUSTOMER', 'GET_LINKEDIN_PROFILE', 'OPENBANKING_MEELO_OPENBANKING_PERSONAL_SCORING_MODEL',
  'OPENBANKING_MEELO_OPENBANKING_PROFESSIONAL_SCORING_MODEL', 'SCORING_ELLISPHERE_SCORE', 'SCORING_CREDIT_SAFE_SCORE',
  'SCORING_WEB_SCORE_B2B_V1', 'SCORING_WEB_SCORE_B2C_V3', 'SCORING_E_REPUTATION_SCORE_V1', 'SCORING_ALTARES_SCORE',
  'SCORING_SMB_SCORE_V1', 'COMPANY_DETAILS_INTERNATIONAL_null', 'COMPANY_DETAILS_null', 'GET_LINKEDIN_PROFILE_null',
  'SEARCH_CUSTOMER_null', 'SEARCH_null', 'SCORING_WEB_SCORE_B2B_V2', 'SCORING_E_REPUTATION_SCORE_V2'];

export default defineComponent({
  components: { Loader, PieChart, ResultCard, Button },
  methods: {
    translate,
    calculateTotal(period: string | number, usage: string | number) {
      const data = this.scoresUsages[usage][period];
      return Object.values(data).reduce((total: any, value: any) => total + value, 0);
    },
    totalUsages() {
      const keys = Object.keys(this.usageWithoutAll);
      const result = {
        labels: [] as string[],
        values: [] as number[]
      };

      for (let index = 0; index < keys.length; index++) {
        const usage = keys[index];
        this.totalUsage[translate(`BACKOFFICE.MONITORING.CONSUMPTION.${usage}`)] = this.calculateTotal('total', usage) as any;
      }
    },
    generateRandomColorsArray(size: number) {
      const colorsArray = [];

      for (let i = 0; i < size; i++) {
        const randomColor = this.getRandomHexColor();
        colorsArray.push(randomColor);
      }

      return colorsArray;
    },
    getRandomHexColor() {
      var randomValues = new Uint8Array(3);
      window.crypto.getRandomValues(randomValues);

      return (
        '#' +
        randomValues[0].toString(16).padStart(2, '0') +
        randomValues[1].toString(16).padStart(2, '0') +
        randomValues[2].toString(16).padStart(2, '0')
      );
    },
    async getTasks(onlyMe: boolean) {
      const allTasks = await getAllTasks(onlyMe);
      this.allTasks = allTasks.data;
    },
    async getMyTeamConsumption(filteredObj: any) {
      return await myTeamConsumption(filteredObj);
    },
    async getMyCompanyConsumption(filteredObj: any) {
      return await myCompanyConsumption(filteredObj);
    },
    async getUserConsumption(filteredObj: any, usersSelected: any) {
      return await userConsumption(filteredObj, usersSelected);
    },
    filterAllTasks(): any {
      return this.allTasks.filter(({ type }: { type: string }) => typeToDisplay.includes(type)).filter(({ subType }: {
        subType: string
      }) => subTypeToDisplay.includes(subType))
        .reduce((acc: any, cur: any) => {
          const tmp = { ...acc };
          tmp[`${cur.type}_${cur.subType}`] = cur;
          return tmp;
        }, {});
    },
    getKeys(data: any, label: string) {
      return Object.keys(data).filter(key => key.includes(label));
    },
    consumption(response: any) {
      const responseData = response.data;
      const authenticityKeys = this.getKeys(responseData, 'AUTHENTICITY');
      const ocrKeys = this.getKeys(responseData, 'OCR');
      const searchKeys = this.getKeys(responseData, 'SEARCH');
      const companyKeys = this.getKeys(responseData, 'COMPANY_DETAILS');
      const webScoreWebB2bKeys = this.getKeys(responseData, 'SCORING_WEB_SCORE_B2B');
      const eReputationKeys = this.getKeys(responseData, 'SCORING_E_REPUTATION_SCORE');

      this.scoresUsages = Object.keys(responseData).reduce((result: any, key: any) => {
        if (authenticityKeys.includes(key)) {
          const authenticityData = responseData[key];
          result['AUTHENTICITY'] = result['AUTHENTICITY'] || {};
          Object.keys(authenticityData).forEach(period => {
            result['AUTHENTICITY'][period] = result['AUTHENTICITY'][period] || {};
            Object.keys(authenticityData[period]).forEach(metric => {
              result['AUTHENTICITY'][period][metric] = (result['AUTHENTICITY'][period][metric] || 0) + authenticityData[period][metric];
            });
          });
        } else if (ocrKeys.includes(key)) {
          const ocrData = responseData[key];
          result['OCR'] = result['OCR'] || {};
          Object.keys(ocrData).forEach(period => {
            result['OCR'][period] = result['OCR'][period] || {};
            Object.keys(ocrData[period]).forEach(metric => {
              result['OCR'][period][metric] = (result['OCR'][period][metric] || 0) + ocrData[period][metric];
            });
          });
        } else if (searchKeys.includes(key)) {
          const ocrData = responseData[key];
          result['SEARCH'] = result['SEARCH'] || {};
          Object.keys(ocrData).forEach(period => {
            result['SEARCH'][period] = result['SEARCH'][period] || {};
            Object.keys(ocrData[period]).forEach(metric => {
              result['SEARCH'][period][metric] = (result['SEARCH'][period][metric] || 0) + ocrData[period][metric];
            });
          });
        } else if (companyKeys.includes(key)) {
          const ocrData = responseData[key];
          result['COMPANY'] = result['COMPANY'] || {};
          Object.keys(ocrData).forEach(period => {
            result['COMPANY'][period] = result['COMPANY'][period] || {};
            Object.keys(ocrData[period]).forEach(metric => {
              result['COMPANY'][period][metric] = (result['COMPANY'][period][metric] || 0) + ocrData[period][metric];
            });
          });
        } else if (webScoreWebB2bKeys.includes(key)) {
          const webScoreWebB2bData = responseData[key];
          result['SCORING_WEB_SCORE_B2B'] = result['SCORING_WEB_SCORE_B2B'] || {};
          Object.keys(webScoreWebB2bData).forEach(period => {
            result['SCORING_WEB_SCORE_B2B'][period] = result['SCORING_WEB_SCORE_B2B'][period] || {};
            Object.keys(webScoreWebB2bData[period]).forEach(metric => {
              result['SCORING_WEB_SCORE_B2B'][period][metric] = (result['SCORING_WEB_SCORE_B2B'][period][metric] || 0) + webScoreWebB2bData[period][metric];
            });
          });
        } else if (eReputationKeys.includes(key)) {
          const eReputationData = responseData[key];
          result['SCORING_E_REPUTATION_SCORE'] = result['SCORING_E_REPUTATION_SCORE'] || {};
          Object.keys(eReputationData).forEach(period => {
            result['SCORING_E_REPUTATION_SCORE'][period] = result['SCORING_E_REPUTATION_SCORE'][period] || {};
            Object.keys(eReputationData[period]).forEach(metric => {
              result['SCORING_E_REPUTATION_SCORE'][period][metric] = (result['SCORING_E_REPUTATION_SCORE'][period][metric] || 0) + eReputationData[period][metric];
            });
          });
        } else {
          result[key] = responseData[key];
        }
        return result;
      }, {});
    },
    filterObject(o: any) {
      return Object.keys(o)
        .filter(key =>
          serviceToDisplay.includes(key) &&
          key !== 'CHECK_VALUE_COMPANY_IBAN' &&
          key !== 'CHECK_VALUE_PERSONAL_IBAN'
        )
        .reduce((acc: any, key: any) => {
          acc[key] = o[key];
          return acc;
        }, {
          CHECK_VALUE_COMPANY_IBAN: { subType: 'COMPANY_IBAN' },
          CHECK_VALUE_PERSONAL_IBAN: { subType: 'PERSONAL_IBAN' }
        });
    }
  },
  data: () => ({
    scoresUsages: {} as any,
    allTasks: {} as any,
    totalUsage: {} as any,
    filteredObj: {} as any,
    totalValueUsage: [] as number[],
    totalLabelUsage: [] as string[],
    isMonitoringDataLoading: true
  }),
  async created() {
    this.isMonitoringDataLoading = true;
    let response = [] as any;
    if (this.$route.path === '/back-office/my-usage' || this.$route.path === '/consumption') {
      await this.getTasks(true);
      let o: any = this.filterAllTasks();
      this.filteredObj = this.filterObject(o);
      response = await myConsumption(this.filteredObj);
    }
    if (this.$route.path === '/back-office/usage-company') {
      await this.getTasks(false);
      let o: any = this.filterAllTasks();
      this.filteredObj = this.filterObject(o);
      response = await myCompanyConsumption(this.filteredObj);
    }
    this.isMonitoringDataLoading = false;
    this.consumption(response);
    this.totalUsages();
    const displayInChart = Object.fromEntries(Object.entries(this.totalUsage).filter(([key, value]) => value !== 0));
    this.totalLabelUsage = Object.keys(displayInChart);
    this.totalValueUsage = Object.values(displayInChart) as number[];
  },
  computed: {
    usageWithoutAll() {
      return Object.fromEntries(Object.entries(this.scoresUsages).filter(([key]) => key !== 'all'));
    }
  }
});