<template>
  <div class="text-input-div">
    <label :class="[color, customClass, 'label']">{{ label }}{{ required ? '*' : '' }}</label>
    <div class="input-error">
      <input class="form-text-input input"
             :value="modelValue"
             :change="isItErrors()"
             @input="$emit('update:modelValue', $event.target.value)"
             :placeholder="placeholder"
             :required="required"
             :readonly="readOnly"
             :disabled="disabled"
             :class="highlight ? 'highlight' : ''">
      <slot></slot>
      </input>
      <span v-if="error !== ''" class="error-tag">{{ error }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {getRegex, TextInputType} from '@/types/TextInputType';
import {translate} from '@/i18n';

export default defineComponent({
  name: 'TextInput',
  props: {
    color: {default: 'dark', type: String as PropType<'dark' | 'light'>},
    modelValue: {default: ''},
    label: {default: ''},
    placeholder: {default: ''},
    type: {type: Number as PropType<TextInputType>, default: TextInputType.TEXT},
    required: {type: Boolean, default: false},
    readOnly: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    highlight: {type: Boolean, default: false},
    customClass: {type: String, default: ''}
  },
  data: () => ({
    error: ''
  }),
  emits: ['update:modelValue'],
  methods: {
    translate,
    isItErrors() {
      const regex = getRegex(this.type);
      if (this.type != TextInputType.NOT_NULL && this.modelValue == '') {
        this.error = '';
        return;
      }

      if (!regex.test(this.modelValue)) {
        switch (this.type) {
          case TextInputType.NOT_NULL:
            this.error = translate('REGEX.NOT_NULL');
            break;
          case TextInputType.PHONE_NUMBER:
            this.error = translate('REGEX.PHONE_NUMBER');
            break;
          case TextInputType.EMAIL:
            this.error = translate('REGEX.EMAIL');
            break;
          case TextInputType.DATE:
            this.error = translate('REGEX.DATE');
            break;
          case TextInputType.SIREN:
            this.error = translate('REGEX.SIREN');
            break;
          case TextInputType.LETTERS:
            this.error = translate('REGEX.LETTERS');
            break;
          case TextInputType.IBAN:
            this.error = translate('REGEX.IBAN');
            break;
          case TextInputType.NAME:
            this.error = translate('REGEX.NAME');
            break;
          case TextInputType.NUMBER:
            this.error = translate('REGEX.NUMBER');
            break;
          case TextInputType.FILE_NAME:
            this.error = translate('REGEX.FILE_NAME');
            break;
        }
      } else {
        this.error = '';
      }
    }
  }
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.text-input-div {
  display: flex;
  align-items: center;
  gap: 1rem;

  .label {
    font-weight: lighter;
    padding: 0 0 0 0.2rem;
    font-size: $fontSize;

    &.light {
      color: white;
    }

    &.dark {
      color: $loginColor2;
    }
  }
}

.input-error {
  display: flex;
  flex-direction: column;
}

.form-text-input {
  display: flex;
  color: rgb(60, 66, 87);
  flex-grow: 1;
}

.error-tag {
  border: solid 0;
  border-radius: 5px;
  font-size: $fontSizeError;
  font-weight: bold;
  background-color: $redMeelo;
  color: white;
  margin: 0.4vh 0;
  padding: 0 0.3vw;
}

.search-input-bar {
  input {
    font-size: $fontSizeSixteenSize;
  }
}

.zipcode {
  input {
    width: 10rem;
  }
}

.form-b2c-bloc-input-result {
  margin-bottom: 0.5rem;
}

.container-openbanking-form-input {
  .text-input-div {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}

.phone-number {
  margin-bottom: 0;
}

.container-openbanking-informations-form-email {
  width: 15rem;
  margin-left: 2.2rem;
}

.personal-info {
  .text-input-div {
    margin-bottom: 1rem;
  }
}

.highlight {
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px,
  rgb(58 151 212 / 36%) 0px 0px 0px 4px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
  rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
  rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px;
}

.amount-currency-input {
  .input-error {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.instant-payment-form-text-input {
  .text-input-div {
    display: block;
    align-items: center;
    gap: 1rem;
  }
}
</style>
