import api from "@/utils/api";
import envConfig from "@/utils/envConfig";
import {getData} from "@/apiCalls/taskApi";
import {OPEN_BANKING_FIELDS} from "@/utils/dtoFields";
import {OpenBankingRequestLink, OpenBankingRequestScope} from "@/utils/openBankingUtils";

export const callOpenBanking = async (requestData: OpenBankingRequestLink) => {
  return await api.post(
    `${envConfig.OPENBANKING_ROUTE}/v1/generate-link?journeyId=${requestData.journeyId}`,
    {
      ...(requestData.type ? {
        type: requestData.type,
      } : {}),
      ...(requestData.isContinuousAccess ? {
        continuous: requestData.isContinuousAccess,
      }: {}),
      ...(requestData.paymentProfile ? {
        paymentProfile: requestData.paymentProfile,
      }:{}),
      requestOptions: {
        phoneNumber: requestData.phoneNumber,
        email: requestData.mail,
        country: requestData.country,
        ...(requestData.scope ? {
          scope: requestData.scope,
        } : {}),
      }
    }
  );
}

export const callOpenBankingTask = (journeyId: string, taskId: string, success: Function, failed: Function) => {
  return setInterval(function () {
    getData(journeyId, OPEN_BANKING_FIELDS)
      .then((response) => {
        if (response.data.data["open_banking_data_summary"].length > 0 && response.data.data["open_banking_data_summary"][0]) {
          success(response);
        }
      })
  }, 7500);
}

export const downloadOpenBankingInfos = async (taskId: string, type: "INPUT" | "OUTPUT") => {
  return api.get(`${envConfig.OPENBANKING_ROUTE}/v1/download-infos/${taskId}?type=${type}`)
    .then((response) => response).catch((error) => error.response);
}

export const refreshOpenBankingLink = async (taskId: string) => {
  return api.get(`${envConfig.OPENBANKING_ROUTE}/v1/refresh-link/${taskId}`);
}

export const disabledOpenBankingLink = async (taskId: string) => {
  return api.delete(`${envConfig.OPENBANKING_ROUTE}/v1/deactivate-link/${taskId}`);
}

export const getOpenBankingCompanyIbans = async () => {
  return await api.get(`${envConfig.BACKEND_ROUTE}/v1/iam/company/ibans`);
}

export const getInstantPaymentAuthorizedIbans = async () => {
    return await api.get(`${envConfig.OPENBANKING_ROUTE}/v1/payment/authorized-ibans`);
}
