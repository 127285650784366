<script setup lang="ts">
import LabelValuePrinter from '@/components/LabelValuePrinter.vue';
import ContentCard from '@/components/card/ContentCard.vue';
import OpenBankingSearch from '@/components/search/open-banking/OpenBankingSearch.vue';
import OpenBankingTable from '@/components/table/open-banking/OpenBankingTable.vue';
import { computed, reactive, ref, onMounted, nextTick } from 'vue';
import { useToast } from 'vue-toast-notification';
import { translate } from '@/i18n';
import { formatCategory, formatLabel, SearchData, ObData } from '@/utils/openBankingUtils';
import Skeleton from '@/components/skeleton/Skeleton.vue';
import { downloadOpenBankingInfos } from '@/apiCalls/openbankingApi';
import {isNil} from "lodash";

const props = withDefaults(defineProps<{
  obData: ObData
}>(), {});

// flags
let isLoading = ref(false);
let isPrivacyError = ref(false);

// reactive objects
let transactions = reactive({});
let filteredTransaction = reactive({});

// ref objects
const bankStatementSkeleton = ref(null);
let account = ref('');

// computed
const formattedTitle = computed(() => {
  return account.value ? (`${account.value.name} - ${account.value.iban.replace(/(.{4})/g, '$1 ')}`)
    : props.obData && props.obData.iban ? `${props.obData.accountName} - ${props.obData.iban.replace(/(.{4})/g, '$1 ')}` : '';
});

const accountDetails = computed(() => {
  return account.value ? ([
    { label: 'OPEN_BANKING.HOLDER', value: account.value.holder_name },
    { label: 'OPEN_BANKING.IBAN', value: account.value.iban.replace(/(.{4})/g, '$1 ') },
    { label: 'OPEN_BANKING.BANK_NAME', value: account.value.bank_name },
    { label: 'OPEN_BANKING.ACCOUNT_NAME', value: account.value.name },
    {
      label: 'OPEN_BANKING.BALANCE',
      value: `${account.value.balance.value} ${account.value.balance.currency_code}`
    }]) : [];
});

const handleSubmit = (searchData: SearchData) => {
  // when there is a search criteria
  if (Object.keys(searchData).length > 0) {
    const filtered = transactions.value.filter((transaction: any) => {
      const isDate = searchData.date ? transaction.date === searchData.date : true;
      // check if the category is in the transaction
      const isCategory = searchData.category ? formatCategory(transaction.meelo_category.label).includes(searchData.category) : true;
      // stringify the balance to handle the check for ex: 25 => 25.98
      const isBalance = searchData.balance ? String(transaction.amount.value).includes(searchData.balance) : true;
      // format correctly the label before search ex: STR => Str
      // check if the label is in the transaction
      const isLabel = searchData.label ? formatLabel(transaction.label).toLowerCase().includes(searchData.label.toLowerCase()) : true;

      return isDate && isCategory && isBalance && isLabel;
    });
    if (filtered.length !== 0) {
      filteredTransaction.value = filtered; // bind the filtered data to the filteredTransaction
    } else {
      const toast = useToast();
      toast.warning(translate('OPEN_BANKING.ACCOUNT_STATEMENT.NO_DATA_FOUND'));
      filteredTransaction.value = []; // show an empty array
    }
  } else {
    handleReset(); // if there is no search data, show the unfiltered data
  }

};
const handleReset = () => {
  filteredTransaction.value = [...transactions.value];
};

const scrollToSkeleton = () => {
  nextTick(() => {
    const element = bankStatementSkeleton.value.$el as HTMLElement;
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'end' });
    }
  });
};

const fetchAccountInfo = async () => {
  isLoading.value = true;
  try {
    if(isNil(props.obData.obTaskId)) {
      isPrivacyError = true;
    } else {
      const response = await downloadOpenBankingInfos(props.obData.obTaskId, 'INPUT');
      if (response?.data?.status === 422) isPrivacyError = true;
      else {
        transactions.value = response.data.accounts[0].transactions;
        account.value = response.data.accounts[0];
      }
    }
  } catch (error) {
    console.error('Failed to fetch account info:', error);
  } finally {
    filteredTransaction.value = transactions.value;
    isLoading.value = false;
  }
};

onMounted(() => {
  scrollToSkeleton();
  fetchAccountInfo();
});
</script>

<template class="container-openbanking">
  <Skeleton v-if="isLoading" ref="bankStatementSkeleton" />
  <template v-else>
    <h6 class="container-openbanking-bank-statement-title">
      {{ translate('OPEN_BANKING.ACCOUNT_STATEMENT.TITLE') }}
    </h6>
    <ContentCard
      :title="formattedTitle"
      class="container-openbanking-bank-statement"
    >
      <LabelValuePrinter
        v-for="(detail, index) in accountDetails"
        :key="index"
        :label="translate(detail.label)"
        :value="detail.value"
      />
      <template v-if="!isPrivacyError">
        <OpenBankingSearch @submit-search="handleSubmit" @handle-reset="handleReset" :transactions="transactions" />
        <OpenBankingTable :transactions="filteredTransaction" :is-privacy-error="isPrivacyError" />
      </template>
      <span v-else class="container-openbanking-bank-statement-privacy-error">
        {{ translate('OPEN_BANKING.ACCOUNT_STATEMENT.PRIVACY_ERROR') }}
      </span>
    </ContentCard>
  </template>
</template>