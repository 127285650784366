<script setup lang="ts">
import { computed, ref } from "vue";
import ContentCard from "@/components/card/ContentCard.vue";
import { formatLocaleDate, translate } from "@/i18n";
import ScoreCircleChart from "@/components/ScoreCircleChart.vue";
import ResultCard from "@/components/card/ResultCard.vue";
import LabelValuePrinter from "@/components/LabelValuePrinter.vue";
import VerificationResult from "@/components/VerificationResult.vue";
import Button from "@/components/button/Button.vue";
import Loader from "@/components/loader/Loader.vue";
import Carousel from "@/components/carousel/Carousel.vue";
import CustomModal from "@/components/modal/CustomModal.vue";
import { isArray, isNull, isNumber } from "lodash";
import { Applicant, CausesSMB } from "@/types/ScoreSMB";
import BoxOutputMultiRequests from "@/components/box-output/BoxOutputMultiRequests.vue";
import BoxOutputDQEControl from "./BoxOutputDQEControl.vue";
import { IdMobileCauses } from "@/types/ScoreFraud";
import { useToast } from "vue-toast-notification";

const props = withDefaults(
  defineProps<{
    dqeControlResult: IdMobileCauses;
    dqeLoader: boolean;
    dqeError: boolean;
    ruleEngineDecision: boolean;
    scoreSMB: number | number[];
    searchImage: string[];
    causesSMB: CausesSMB;
    applicant: Applicant;
    company: string;
    color: string;
    permissions: string[];
    canScoreSMB: boolean;
    phone: string;
    email: string;
  }>(),
  {
    dqeLoader: false,
    dqeError: false,
    company: "",
    color: "#D9D9D9",
    canScoreSMB: false,
    phone: "",
    email: "",
  }
);
const toast = useToast();
const searchImageModalFlag = ref(false);

const emit = defineEmits(["dqe-control"]);

const openSearchImage = () => {
  searchImageModalFlag.value = !searchImageModalFlag.value;
};

const isSMB = (scoreSMB: any) => {
  return isNumber(scoreSMB) || isArray(scoreSMB);
};

const address = computed(() => {
  return `${
    props.causesSMB?.contact?.emailDomainRegistrantStreet
      ? props.causesSMB?.contact?.emailDomainRegistrantStreet
      : ""
  } ${
    props.causesSMB?.contact?.emailDomainRegistrantPostalCode
      ? props.causesSMB?.contact?.emailDomainRegistrantPostalCode
      : ""
  } ${
    props.causesSMB?.contact?.emailDomainRegistrantCity
      ? props.causesSMB?.contact?.emailDomainRegistrantCity
      : ""
  } ${
    props.causesSMB?.contact?.emailDomainRegistrantCountry
      ? props.causesSMB?.contact?.emailDomainRegistrantCountry
      : ""
  }`;
});

const hasNoImage = computed(() => {
  return props.searchImage?.length === 0;
});

const displayMultiRequest = computed(() => {
  return (
    props.causesSMB.multiRequests?.email === 0 &&
    props.causesSMB.multiRequests?.phone === 0
  );
});
const displayContentIdMobile = computed(() => {
  return props.dqeControlResult ? props.dqeControlResult : null;
});

const messageForDqeControl = computed(() => {
  if (
    !props.ruleEngineDecision ||
    isNull(props?.dqeControlResult?.globalDecision)
  ) {
    return translate("DQE_CONTROL.NO_SEARCH_RULE");
  } else if (props.dqeError) {
    toast.error(translate("ERRORS.ERROR_OCCURRED"));
    return translate("DQE_CONTROL.ERROR");
  }
});

const dqeControl = () => {
  emit("dqe-control");
};
</script>

<template>
  <ContentCard
    v-if="!canScoreSMB"
    :checked-display-content="false"
    :is-padlock="true"
    :title="translate('CONFIDENCE_SCORE')"
    class="score-smb"
  />
  <template v-else>
    <ContentCard :title="translate('CONFIDENCE_SCORE')" class="score-smb">
      <Loader v-if="!(scoreSMB || causesSMB)" />
      <template v-else>
        <div class="score-smb-causes">
          <div
            v-if="isSMB(scoreSMB) && permissions?.includes('KIT_B2B_SMB_SCORE')"
            class="score-smb-content-score"
          >
            <ScoreCircleChart
              class="circle-chart score-fraud"
              :circle-value="Math.round(<number> scoreSMB)"
              :circle-width="'50%'"
              type-score="score_smb"
              :circleColor="color"
              :helper-text-display="true"
            />
          </div>
          <div
            v-if="
              causesSMB && permissions?.includes('KIT_B2B_SMB_DETAILS_PHONE')
            "
          >
            <ResultCard
              :title="translate('PHONE')"
              class="score-smb-content-phone breakinside"
            >
              <div class="score-smb-verification-result">
                <VerificationResult
                  :value="causesSMB?.contact?.phoneExists"
                  :label="translate('VERIFIED')"
                  :tooltip="translate('TOOLTIP.MOBILE_PHONE_VERIFICATION')"
                  class="verification"
                />
              </div>
              <LabelValuePrinter
                :label="translate('FIRST_SEEN_DATE')"
                :value="
                  causesSMB?.contact?.phoneFirstSeenDate
                    ? formatLocaleDate(causesSMB?.contact?.phoneFirstSeenDate)
                    : '-'
                "
              />
              <LabelValuePrinter
                :label="translate('TELEPHONE.NUMBER')"
                :value="
                  applicant?.mobilePhone
                    ? applicant?.mobilePhone
                    : phone !== ''
                    ? phone
                    : '-'
                "
                :copyToClipboard="true"
              />
              <LabelValuePrinter
                :label="translate('TELEPHONE.CARRIER')"
                :value="
                  causesSMB?.contact?.phoneCarrier
                    ? causesSMB?.contact.phoneCarrier
                    : '-'
                "
              >
                <img
                  v-if="causesSMB?.contact?.phoneCarrierRisky"
                  src="../../../public/images/warning-icon.png"
                  class="warning-icon"
                  alt="warning-icon.png"
                  :title="translate('OPERATOR_AT_RISK')"
                />
              </LabelValuePrinter>
              <LabelValuePrinter
                :label="translate('TELEPHONE.ORIGINAL_CARRIER')"
                :value="
                  causesSMB?.contact?.phoneOriginalCarrier
                    ? causesSMB?.contact?.phoneOriginalCarrier
                    : '-'
                "
              >
                <img
                  v-if="causesSMB?.contact?.phoneOriginalCarrierRisky"
                  src="../../../public/images/warning-icon.png"
                  class="warning-icon"
                  alt="warning-icon.png"
                  :title="translate('OPERATOR_AT_RISK')"
                />
              </LabelValuePrinter>
            </ResultCard>
          </div>
          <div v-if="permissions?.includes('KIT_B2B_SMB_DETAILS_MAIL')">
            <ResultCard
              :title="translate('EMAIL')"
              class="score-smb-content-email breakinside"
            >
              <div class="score-smb-verification-result">
                <VerificationResult
                  :value="causesSMB?.contact?.emailExists"
                  :label="translate('VERIFIED')"
                  :tooltip="translate('TOOLTIP.EMAIL_VERIFICATION')"
                  class="verification"
                />
              </div>
              <LabelValuePrinter
                :label="translate('FIRST_SEEN_DATE')"
                :value="
                  causesSMB?.contact?.emailFirstSeenDate
                    ? formatLocaleDate(causesSMB?.contact?.emailFirstSeenDate)
                    : '-'
                "
              />
              <LabelValuePrinter
                :label="translate('EMAIL')"
                :value="
                  applicant?.email
                    ? applicant?.email
                    : email !== ''
                    ? email
                    : '-'
                "
                :copyToClipboard="true"
              >
                <img
                  v-if="causesSMB?.contact?.emailDisposable"
                  src="../../../public/images/warning-icon.png"
                  class="warning-icon"
                  alt="warning-icon.png"
                  :title="translate('TOOLTIP.EMAIL_DISPOSABLE')"
                />
                <img
                  v-if="causesSMB?.contact?.emailDomainRisky"
                  src="../../../public/images/warning-icon.png"
                  class="warning-icon"
                  alt="warning-icon.png"
                  :title="translate('TOOLTIP.EMAIL_RISKY')"
                />
              </LabelValuePrinter>
              <br />
              <div v-if="causesSMB">
                <span class="title domain-information">{{
                  `${translate("DOMAIN_INFORMATION")} :`
                }}</span>
                <LabelValuePrinter
                  :label="translate('DOMAIN')"
                  :value="`${
                    causesSMB?.contact?.emailDomain
                      ? causesSMB?.contact.emailDomain
                      : '-'
                  }`"
                  is-website
                />
                <LabelValuePrinter
                  :label="translate('COMPANY.CREATED_DATE')"
                  :value="`${
                    causesSMB?.contact?.emailDomainCreationDate
                      ? formatLocaleDate(
                          causesSMB?.contact.emailDomainCreationDate
                        )
                      : '-'
                  }`"
                />
                <LabelValuePrinter
                  :label="translate('REGISTRANT')"
                  :value="`${
                    causesSMB?.contact?.emailDomainRegistrant
                      ? causesSMB?.contact?.emailDomainRegistrant
                      : '-'
                  }`"
                />
                <LabelValuePrinter
                  :label="translate('REGISTRAR')"
                  :value="`${
                    causesSMB?.contact?.emailDomainRegistrar
                      ? causesSMB?.contact?.emailDomainRegistrar
                      : '-'
                  }`"
                />
                <LabelValuePrinter
                  :label="translate('PHONE')"
                  :value="`${
                    causesSMB?.contact?.emailDomainRegistrantPhone
                      ? causesSMB?.contact?.emailDomainRegistrantPhone
                      : '-'
                  }`"
                  :copyToClipboard="true"
                />
                <LabelValuePrinter
                  :label="translate('ADDRESS.TITLE')"
                  :value="address"
                  is-address
                />
              </div>
            </ResultCard>
          </div>
          <div class="score-smb-content-social-networks">
            <ResultCard
              v-if="
                permissions?.includes('KIT_B2B_SMB_DETAILS_SOCIAL_NETWORKS')
              "
              class="breakinside"
              :title="translate('SOCIAL_NETWORKS.TITLE')"
            >
              <div class="score-smb-content-social-networks-detailed-score">
                <div>
                  <span
                    class="score-smb-content-social-networks-detailed-score-label"
                    >{{ `${translate("SOCIAL_NETWORKS.PHONE")} :` }}</span
                  >
                  <span>{{ ` ${causesSMB?.socialNetworks?.phone}` }}</span>
                </div>
                <ul v-if="causesSMB?.socialNetworks?.phoneList?.length > 0">
                  <li
                    v-for="(phone_network, key) in causesSMB?.socialNetworks
                      ?.phoneList"
                    :key="key"
                    class="score-smb-content-social-networks-detailed-score-result"
                  >
                    {{ phone_network }}
                  </li>
                </ul>
                <div>
                  <span
                    class="score-smb-content-social-networks-detailed-score-label"
                    >{{ `${translate("SOCIAL_NETWORKS.MAIL")} :` }}</span
                  >
                  <span>{{ ` ${causesSMB?.socialNetworks?.email}` }}</span>
                </div>
                <ul v-if="causesSMB?.socialNetworks?.emailList?.length > 0">
                  <li
                    v-for="(mail_network, index) in causesSMB?.socialNetworks
                      ?.emailList"
                    :key="index"
                    class="score-smb-content-social-networks-detailed-score-result"
                  >
                    {{ mail_network }}
                  </li>
                </ul>
              </div>
            </ResultCard>
          </div>
        </div>
        <div class="score-smb-causes-2ndLine">
          <BoxOutputDQEControl
          v-if="causesSMB != null"
          :data="displayContentIdMobile"
          @dqe-control="dqeControl"
          :content="messageForDqeControl"
          :loader="dqeLoader"
          :permissions="permissions"
          />
          <BoxOutputMultiRequests
          v-if="causesSMB && !displayMultiRequest"
          :result="causesSMB?.multiRequests"
          :company="company"
          :title="translate('MULTI_REQUESTS.TITLE')"
          market-type="b2c"
          />
          <ResultCard
            v-if="
              searchImage &&
              permissions?.includes('KIT_B2B_SMB_DETAILS_SEARCH_IMAGE')
            "
            :title="translate('SOCIAL_FACE_MATCHING.TITLE')"
            class="no-print score-smb-content-search-image"
          >
            <div>
              <Button
                :label="translate('BUTTONS.CONSULT')"
                :disabled="hasNoImage"
                @click="openSearchImage"
              />
              <CustomModal
                v-model:open="searchImageModalFlag"
                :title-modal="translate('SOCIAL_FACE_MATCHING.TITLE')"
                :display-close-button="true"
              >
                <LabelValuePrinter
                  class="facematching-images border"
                  :label="translate('SOCIAL_FACE_MATCHING.CATCHED_IMAGES')"
                  :value="searchImage.length"
                  :tooltip-title="translate('TOOLTIP.SOCIAL_FACE_MATCHING')"
                />
                <Carousel :images="searchImage" />
              </CustomModal>
              <span v-if="hasNoImage">{{
                translate("SOCIAL_FACE_MATCHING.NO_IMAGE")
              }}</span>
            </div>
          </ResultCard>
        </div>
      </template>
    </ContentCard>
  </template>
</template>
