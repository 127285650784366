import { translate } from "@/i18n";
import { TextInputType, getRegex } from "@/types/TextInputType";

export function getFormattedRegistrationNumber(siren: string) {
  if (siren == null) {
    return;
  }
  // Regex pour mettre un espace tous les 3 caractères
  siren = siren?.replaceAll(" ", "")?.replaceAll(/(.{3})/g, "$1 ");
  // Si le dernier caractère est un espace je le supprime
  if (siren.charAt(siren?.length - 1) === " ") {
    siren = siren.slice(0, -1);
  }
  return siren;
}

export const formatSiren = (selectedOptionValue: string, siren: string) => {
  let _siren = siren?.length > 3 ? siren?.replaceAll(" ", "") : siren;
  if (_siren?.length === 10)
    return _siren?.replaceAll(/(.{4})(.{3})/g, "$1 $2 ");
  return _siren?.replaceAll(/(.{3})/g, "$1 ");
};

export const isES = (registrationNumber: string) => {
  const ESRegex = getRegex(TextInputType.TAX_IDENTIFICATION_CODE);
  return ESRegex.test(registrationNumber);
};

export const getSirenRegex = (country: string) => {
  if (country === "BE") return TextInputType.NATIONAL_REGISTRATION_NUMBER;
  if (country === "ES") return TextInputType.TAX_IDENTIFICATION_CODE;
  return TextInputType.SIREN;
};

export const getCountryOptions = (permissionsSorted: string[]) => {
  return [
    {
      name: translate("COMPANY.ORIGIN.FR"),
      imageSrc: "/images/custom-select/france.png",
      value: "FR",
      disabled: !permissionsSorted.includes("KIT_B2B_INTERNATIONALIZATION_FR"),
    },
    {
      name: translate("COMPANY.ORIGIN.BE"),
      imageSrc: "/images/custom-select/belgium.png",
      value: "BE",
      disabled: !permissionsSorted.includes("KIT_B2B_INTERNATIONALIZATION_BE"),
    },
    {
      name: translate("COMPANY.ORIGIN.ES"),
      imageSrc: "/images/custom-select/spain.png",
      value: "ES",
      disabled: false,
    },
  ].filter((el) => !el.disabled);
};

export const applySirenFormat = (
  event: KeyboardEvent,
  siren: string,
  sirenCountry = "FR"
) => {
  let newSiren = siren;
  const keyCode = event.which || event.keyCode;

  if (keyCode === 8 && newSiren?.charAt(newSiren?.length - 1) === " ") {
    event.preventDefault();
  }

  newSiren = formatSiren(sirenCountry, newSiren);

  if (newSiren?.charAt(newSiren?.length - 1) === " ") {
    newSiren = newSiren?.slice(0, -1);
  }

  return newSiren;
};

export default { getFormattedRegistrationNumber };
