<script setup lang="ts">

import { computed, reactive } from 'vue';
import ContentCard from '@/components/card/ContentCard.vue';
import LabelValuePrinter from '@/components/LabelValuePrinter.vue';
import { translate } from '@/i18n';
import ResultCard from '@/components/card/ResultCard.vue';
import BarChart from '@/components/chart/BarChart.vue';
import LineChart from '@/components/chart/LineChart.vue';
import { formatBalance, ObData, OpenBankingScopes, Routes } from '@/utils/openBankingUtils';
import Button from '@/components/button/Button.vue';
import { useRoute } from 'vue-router';
import { isArray, isEqual, isNull } from 'lodash';
import AnalysisOpenBankingTable from '@/components/table/open-banking/AnalysisOpenBankingTable.vue';

const emit = defineEmits(['to-account-summary-event']);
const route = useRoute();

const props = withDefaults(defineProps<{
  obData: ObData,
  contextData: any,
  isRecovery: boolean,
  permissions: string[],
  canOpenBanking: boolean,
}>(), {
  contextData: {},
  isRecovery: false,
  canOpenBanking: false
});

const obData = reactive(props.obData);

const formatCardNumber = (number: string) => {
  let res = 'X'.repeat(16 - number.length) + number;
  return res.replace(/(.{4})/g, '$1 ');
};

const handleSummaryButton = () => {
  emit('to-account-summary-event');
};

const columnsIncomes = [translate('OPEN_BANKING.MONTHLY_AVERAGE'), translate('OPEN_BANKING.SUM_OVER_THE_PERIOD'), translate('OPEN_BANKING.ANNUAL_ESTIMATE')];

const isPerAccount = computed(() => isNull(props.obData.scope) ? !props.obData.topProAccount : isEqual(props.obData.scope, OpenBankingScopes.PERSONAL));
const isProAccount = computed(() => isNull(props.obData.scope) ? props.obData.topProAccount : isEqual(props.obData.scope, OpenBankingScopes.PROFESSIONAL));

const MonthlyIncomeCond = computed(() => {
  return [Routes.OPEN_BANKING, Routes.ANALYSIS_B2B, Routes.ANALYSIS_B2C, Routes.RECOVERY, Routes.SYNTHESIS].includes(route.path) && isPerAccount.value;
});

const MonthlyIncomeValues = computed(() => [
  {
    label: translate('OPEN_BANKING.CATEGORIES.MONTH_INCOMES_AMOUNT_MEAN'),
    value: obData.monthIncomesAmountMean
  },
  {
    label: translate('OPEN_BANKING.CATEGORIES.MONTH_INCOMES_SALARIES_AMOUNT_MEAN'),
    value: obData.monthIncomesSalariesAmountMean
  },
  {
    label: translate('OPEN_BANKING.CATEGORIES.MONTH_INCOMES_ALLOWANCES_AMOUNT_MEAN'),
    value: obData.monthIncomesAllowancesAmountMean
  },
  {
    label: translate('OPEN_BANKING.CATEGORIES.MONTH_INCOMES_PENSIONS_AMOUNT_MEAN'),
    value: obData.monthIncomesPensionsAmountMean
  }
]);

// Pro Account
const MonthlyIncomeCondProAccount = computed(() => {
  return [Routes.OPEN_BANKING, Routes.ANALYSIS_B2B, Routes.ANALYSIS_B2C, Routes.RECOVERY, Routes.SYNTHESIS].includes(route.path) && isProAccount.value;
});

const MonthlyIncomeValuesProAccount = computed(() => [
  {
    label: translate('OPEN_BANKING.CATEGORIES.AVERAGE_MONTHLY_ENTRIES'),
    value: obData.monthIncomesAmountMean
  },
  {
    label: translate('OPEN_BANKING.CATEGORIES.TOTAL_FOR_THE_PERIOD'),
    value: obData.monthIncomesAmountSum
  }
]);

// aggregation bank card
const aggregationBankCardsValues = computed(() => [
  {
    cond: true,
    label: translate('OPEN_BANKING.CATEGORIES.BANK_CARD_PRESENCE'),
    value: obData.bankCardPresence
  },
  {
    cond: true,
    label: translate('OPEN_BANKING.CATEGORIES.BANK_CARD_QUANTITY'),
    value: isEqual(obData.bankCardQuantity, 0) ? '-' : obData.bankCardQuantity
  },
  {
    cond: true,
    label: translate('OPEN_BANKING.CATEGORIES.DEBITS'),
    value: obData.bankDirectDebitPresence
  },
  {
    cond: obData.bankCardPresence !== `${translate('NO')}`,
    label: translate('OPEN_BANKING.CATEGORIES.BANK_CARD_NUMBERS')
  }
]);

// aggregation other accounts
const aggregationOtherAccountsValues = computed(() => [
  {
    label: translate('OPEN_BANKING.CATEGORIES.OTHER_ACCOUNTS.MONTH_INCOMES_SAVINGS_AMOUNT_MEAN'),
    value: obData.monthIncomesSavingsAmountMean
  },
  {
    label: translate('OPEN_BANKING.CATEGORIES.OTHER_ACCOUNTS.MONTH_OUTCOMES_SAVINGS_AMOUNT_MEAN'),
    value: obData.monthOutcomesSavingsAmountMean
  },
  {
    label: translate('OPEN_BANKING.CATEGORIES.OTHER_ACCOUNTS.MONTH_INCOMES_TRANSFERS_AMOUNT_MEAN'),
    value: obData.monthIncomesTransfersAmountMean
  },
  {
    label: translate('OPEN_BANKING.CATEGORIES.OTHER_ACCOUNTS.MONTH_OUTCOMES_TRANSFERS_AMOUNT_MEAN'),
    value: obData.monthOutcomesTransfersAmountMean
  }
]);


const checkPermission = (permission: string) => {
  if (route.path === Routes.OPEN_BANKING) {
    return props.permissions?.includes(`KIT_OB_${permission}`);
  } else if (route.path === Routes.ANALYSIS_B2B) {
    return props.permissions?.includes(`KIT_B2B_${permission}`);
  } else if (route.path === Routes.ANALYSIS_B2C) {
    return props.permissions?.includes(`KIT_B2C_${permission}`);
  } else if (route.path === Routes.SYNTHESIS) {
    return props.permissions?.some((elt: any) => elt.includes(`KIT_OB_${permission}`) || elt.includes(`KIT_B2B_${permission}`) || elt.includes(`KIT_B2C_${permission}`));
  } else if (route.path === Routes.RECOVERY) {
    return true;
  }
};
const movementsIncomes = computed(() => checkPermission('OB_DETAILS_MOVEMENTS_INCOMES'));
const accountSummary = computed(() => checkPermission('OB_SUMMARY_GLOBAL'));
const movementsOutcomes = computed(() => checkPermission('OB_DETAILS_MOVEMENTS_OUTCOMES'));
const movementsDailyBalance = computed(() => checkPermission('OB_DETAILS_MOVEMENTS_DAILY_BALANCE'));
const movementsMonthlyBalance = computed(() => checkPermission('OB_DETAILS_MOVEMENTS_MONTHLY_BALANCE'));
const movementsRecommendations = computed(() => checkPermission('OB_DETAILS_MOVEMENTS_RECOMMENDATIONS'));
const aggregationsRemainsToLive = computed(() => checkPermission('OB_DETAILS_AGGREGATIONS_REMAINS_TO_LIVE'));
const aggregationsIndebtedness = computed(() => checkPermission('OB_DETAILS_AGGREGATIONS_INDEBTEDNESS'));
const aggregationsExistingCreditAnalysis = computed(() => checkPermission('OB_DETAILS_AGGREGATIONS_EXISTING_CREDIT_ANALYSIS'));
const aggregationsHabitat = computed(() => checkPermission('OB_DETAILS_AGGREGATIONS_HABITAT'));
const aggregationsBankCard = computed(() => checkPermission('OB_DETAILS_AGGREGATIONS_BANK_CARD'));
const aggregationsOtherAccounts = computed(() => checkPermission('OB_DETAILS_AGGREGATIONS_OTHER_ACCOUNTS'));

const habitatString = computed(() => `*${translate('OPEN_BANKING.SERVICES')} = ${translate('OPEN_BANKING.WATER')}, ${translate('OPEN_BANKING.GAS')}, ${translate('OPEN_BANKING.ELECTRICITY')}...`);

const displayIncomesValues = computed(() => {
  return [
    {
      label: translate('OPEN_BANKING.SALARIES'),
      monthlyAverage: obData.monthIncomesSalariesAmountMean,
      periodSum: obData.monthIncomesSalariesAmountSum,
      annualEstimate: obData.monthIncomesSalariesAmountYearlyEstimation
    },
    {
      label: translate('OPEN_BANKING.ALLOWANCES'),
      monthlyAverage: obData.monthIncomesAllowancesAmountMean,
      periodSum: obData.monthIncomesAllowancesAmountSum,
      annualEstimate: obData.monthIncomesAllowancesAmountYearlyEstimation
    },
    {
      label: translate('OPEN_BANKING.PENSIONS'),
      monthlyAverage: obData.monthIncomesPensionsAmountMean,
      periodSum: obData.monthIncomesPensionsAmountSum,
      annualEstimate: obData.monthIncomesPensionsAmountYearlyEstimation
    },
    {
      label: translate('OPEN_BANKING.TOTAL_INCOME'),
      monthlyAverage: obData.monthIncomesAmountMean,
      periodSum: obData.monthIncomesAmountSum,
      annualEstimate: obData.monthIncomesAmountYearlyEstimation
    }
  ];
});

const displayOutcomesValues = computed(() => {
  return [
    {
      label: translate('OPEN_BANKING.RENTS'),
      monthlyAverage: obData.monthOutcomesRentsAmountMean,
      periodSum: obData.monthOutcomesRentsAmountSum,
      annualEstimate: obData.monthOutcomesRentsAmountYearlyEstimation
    },
    {
      label: translate('OPEN_BANKING.LOANS'),
      monthlyAverage: obData.monthOutcomesCreditsAmountMean,
      periodSum: obData.monthOutcomesCreditsAmountSum,
      annualEstimate: obData.monthOutcomesCreditsAmountYearlyEstimation
    },
    {
      label: translate('OPEN_BANKING.TOTAL_OUTCOMES'),
      monthlyAverage: obData.monthOutcomesAmountMean,
      periodSum: obData.monthOutcomesAmountSum,
      annualEstimate: obData.monthOutcomesAmountYearlyEstimation
    }
  ];
});

const displayCreditsValues = computed(() => {
  return [
    {
      label: translate('OPEN_BANKING.LOANS'),
      monthlyAverage: obData.monthOutcomesCreditsAmountMean,
      periodSum: obData.monthOutcomesCreditsAmountSum,
      annualEstimate: obData.monthOutcomesCreditsAmountYearlyEstimation
    }
  ];
});

const displayHabitatValues = computed(() => {
  return [
    {
      label: translate('OPEN_BANKING.RENTS'),
      monthlyAverage: obData.monthOutcomesRentsAmountMean,
      periodSum: obData.monthOutcomesRentsAmountSum,
      annualEstimate: obData.monthOutcomesRentsAmountYearlyEstimation
    },
    {
      label: translate('OPEN_BANKING.SERVICES'),
      monthlyAverage: obData.monthOutcomesUtilitiesAmountMean,
      periodSum: obData.monthOutcomesUtilitiesAmountSum,
      annualEstimate: obData.monthOutcomesUtilitiesAmountYearlyEstimation
    }
  ];
});

const displayOtherAccountValues = computed(() => {
  return [
    {
      label: translate('OPEN_BANKING.CATEGORIES.OTHER_ACCOUNTS.MONTH_INCOMES_SAVINGS_AMOUNT_MEAN'),
      monthlyAverage: obData.monthIncomesSavingsAmountMean,
      periodSum: obData.monthIncomesSavingsAmountSum,
      annualEstimate: obData.monthIncomeSavingsAmountYearlyEstimation
    },
    {
      label: translate('OPEN_BANKING.CATEGORIES.OTHER_ACCOUNTS.MONTH_OUTCOMES_SAVINGS_AMOUNT_MEAN'),
      monthlyAverage: obData.monthOutcomesSavingsAmountMean,
      periodSum: obData.monthOutcomesSavingsAmountSum,
      annualEstimate: obData.monthOutcomesSavingsAmountYearlyEstimation
    },
    {
      label: translate('OPEN_BANKING.CATEGORIES.OTHER_ACCOUNTS.MONTH_INCOMES_TRANSFERS_AMOUNT_MEAN'),
      monthlyAverage: obData.monthIncomesTransfersAmountMean,
      periodSum: obData.monthIncomesTransfersAmountSum,
      annualEstimate: obData.monthIncomesTransfersAmountYearlyEstimation
    },
    {
      label: translate('OPEN_BANKING.CATEGORIES.OTHER_ACCOUNTS.MONTH_OUTCOMES_TRANSFERS_AMOUNT_MEAN'),
      monthlyAverage: obData.monthOutcomesTransfersAmountMean,
      periodSum: obData.monthOutcomesTransfersAmountSum,
      annualEstimate: obData.monthOutcomesTransfersAmountYearlyEstimation
    }
  ];
});

const displayIncomesProValues = computed(() => {
  return [
    {
      label: translate('OPEN_BANKING.REVENUE_INCL_VAT'),
      monthlyAverage: obData.monthIncomesTransfersAmountMean,
      periodSum: obData.monthIncomesTransfersAmountSum,
      annualEstimate: obData.monthIncomesTransfersAmountYearlyEstimation
    },
    {
      label: translate('OPEN_BANKING.TOTAL_INCOME'),
      monthlyAverage: obData.monthIncomesAmountMean,
      periodSum: obData.monthIncomesAmountSum,
      annualEstimate: obData.monthIncomesAmountYearlyEstimation
    }
  ];
});

const displayOutcomesProValues = computed(() => {
  return [
    {
      label: translate('OPEN_BANKING.RENTS'),
      monthlyAverage: obData.monthOutcomesRentsAmountMean,
      periodSum: obData.monthOutcomesRentsAmountSum,
      annualEstimate: obData.monthOutcomesRentsAmountYearlyEstimation
    },
    {
      label: translate('OPEN_BANKING.OUTGOING_SALARIES'),
      monthlyAverage: obData.monthOutcomesSalariesAmountMean,
      periodSum: obData.monthOutcomesSalariesAmountSum,
      annualEstimate: obData.monthOutcomesSalariesAmountYearlyEstimation
    },
    {
      label: translate('OPEN_BANKING.OUTGOING_SOCIAL_CHARGES'),
      monthlyAverage: obData.monthOutcomesSocialFeesMean,
      periodSum: obData.monthOutcomesSocialFeesSum,
      annualEstimate: obData.monthOutcomesSocialFeesYearlyEstimation
    },
    {
      label: translate('OPEN_BANKING.OTHER_EXPENSES_INCL_VAT'),
      monthlyAverage: obData.monthOutcomesOtherExpensesInclVatAmountMean,
      periodSum: obData.monthOutcomesOtherExpensesInclVatAmountSum,
      annualEstimate: obData.monthOutcomesOtherExpensesInclVatAmountYearlyEstimation
    },
    {
      label: translate('OPEN_BANKING.CREDITS_INCL_VAT'),
      monthlyAverage: obData.monthOutcomesCreditsAmountMean,
      periodSum: obData.monthOutcomesCreditsAmountSum,
      annualEstimate: obData.monthOutcomesCreditsAmountYearlyEstimation
    },
    {
      label: translate('OPEN_BANKING.TAXES_INCL_VAT'),
      monthlyAverage: obData.monthOutcomesTaxesAmountMean,
      periodSum: obData.monthOutcomesTaxesAmountSum,
      annualEstimate: obData.monthOutcomesTaxesAmountYearlyEstimation
    },
    {
      label: translate('OPEN_BANKING.INTRA_COMPANY_TRANSFERS'),
      monthlyAverage: obData.monthOutcomesAutoTransfersAmountMean,
      periodSum: obData.monthOutcomesAutoTransfersAmountSum,
      annualEstimate: obData.monthOutcomesAutoTransfersAmountSum
    },
    {
      label: translate('OPEN_BANKING.TOTAL_OUTCOMES'),
      monthlyAverage: obData.monthOutcomesAmountMean,
      periodSum: obData.monthOutcomesAmountSum,
      annualEstimate: obData.monthOutcomesAmountYearlyEstimation
    }
  ];
});
</script>

<template>
  <ContentCard v-if="!canOpenBanking" :title="translate('OPEN_BANKING.DETAILS')" :checked-display-content="false"
               :is-padlock="true"
               :class="isRecovery ? 'container-openbanking-recovery container-openbanking-detailed-recovery  container-openbanking-analysis-detailed' : 'container-openbanking-analysis-detailed'" />
  <template v-else>
    <ContentCard :title="translate('OPEN_BANKING.DETAILS')"
                 :class="isRecovery ? 'container-openbanking-recovery container-openbanking-detailed-recovery  container-openbanking-analysis-detailed' : 'container-openbanking-analysis-detailed'"
    >
      <div class="financial-period">
            <span>{{
                `${translate('OPEN_BANKING.FINANCIAL_ANALYSIS_PERIOD')}: ${obData.startStudyPeriod} ${translate('OPEN_BANKING.TO')} ${obData.endStudyPeriod} `
              }}</span>
      </div>
      <div>
        <div class="container-openbanking-analysis-detailed-container">
          <span class="container-openbanking-analysis-detailed-title">
            {{ translate('OPEN_BANKING.MOVEMENT_SUMMARY') }}
          </span>
          <Button
            v-if="$route.path !== Routes.SYNTHESIS"
            class="container-openbanking-analysis-detailed-container-button no-print"
            :label="translate('OPEN_BANKING.ACCOUNT_STATEMENT.BUTTON')"
            @click="handleSummaryButton"
            :action-icon="!accountSummary ? 'padlock-blue.png' : ''"
            :is-disabled="!accountSummary"
          />
        </div>
        <div v-if="!isRecovery"
             class="container-openbanking-analysis-detailed-divided container-openbanking-analysis-balance">
          <ResultCard :title="translate('OPEN_BANKING.DAILY_BALANCE')"
                      v-if="!!obData?.balancePerDay && movementsDailyBalance">
            <LineChart background-color-chart="#4FA0FF" :abscissa-chart="formatBalance(obData.balancePerDay, 'date')"
                       :data-chart="formatBalance(obData.balancePerDay, 'amount')"
                       :label-chart="translate('OPEN_BANKING.DAILY_BALANCE')" border-color-chart="#4FA0FF" />
          </ResultCard>
          <ResultCard :title="translate('OPEN_BANKING.MONTHLY_BALANCE')"
                      v-if="!!obData?.balancePerMonth && movementsMonthlyBalance">
            <BarChart background-color-chart="#4FA0FF" :abscissa-chart="formatBalance(obData.balancePerMonth, 'date')"
                      :data-chart="formatBalance(obData.balancePerMonth, 'amount')"
                      :label-chart="translate('OPEN_BANKING.MONTHLY_BALANCE')" />
          </ResultCard>
        </div>
        <div v-if="movementsIncomes" class="container-openbanking__movements">
          <ResultCard v-if="isPerAccount" :title="translate('OPEN_BANKING.INCOMES')">
            <AnalysisOpenBankingTable :column-headers="columnsIncomes" :table-data="displayIncomesValues" />
          </ResultCard>
          <ResultCard v-else :title="translate('OPEN_BANKING.INCOMES')">
            <AnalysisOpenBankingTable :column-headers="columnsIncomes" :table-data="displayIncomesProValues" />
          </ResultCard>
        </div>
        <div>
          <ResultCard v-if="movementsOutcomes" :title="translate('OPEN_BANKING.OUTCOMES')">
            <AnalysisOpenBankingTable v-if="isPerAccount" :column-headers="columnsIncomes"
                                      :table-data="displayOutcomesValues" />
            <AnalysisOpenBankingTable v-else :column-headers="columnsIncomes" :table-data="displayOutcomesProValues" />
          </ResultCard>
        </div>
        <div
          :class="`${isRecovery ? 'recovery-divided container-openbanking-analysis-detailed-unique' : 'container-openbanking-analysis-detailed-unique'}`">
          <ResultCard v-if="movementsRecommendations"
                      :title="translate('OPEN_BANKING.RECOMMENDATIONS')">
            <div class="recommendations-value">
              <LabelValuePrinter :label="translate('OPEN_BANKING.CATEGORIES.RECOMMENDED_DEBIT_DATE')"
                                 :value="obData.recommendedDebitDate" />
              <LabelValuePrinter :label="translate('OPEN_BANKING.MONTH_NEGATIVE')" />
              <LabelValuePrinter :label="translate('OPEN_BANKING.CATEGORIES.RECOMMENDED_DEBIT_AMOUNT')"
                                 :value="obData.recommendedDebitAmount" />
              <LabelValuePrinter :label="translate('OPEN_BANKING.CATEGORIES.MONTH_NEGATIVE_BALANCE_MIN')"
                                 :value="obData.monthNegativeBalanceMin"
                                 class="recommendations-value-month-negative"
              />
              <LabelValuePrinter :label="translate('OPEN_BANKING.CATEGORIES.MAX_BALANCE_MIN')"
                                 :value="obData.maxBalance"
                                 class="recommendations-value-max-balance"
              />
              <LabelValuePrinter :label="translate('OPEN_BANKING.CATEGORIES.MONTH_NEGATIVE_BALANCE_MAX')"
                                 :value="obData.monthNegativeBalanceMax"
                                 class="recommendations-value-month-negative"
              />
              <LabelValuePrinter
                v-if="($route.path === Routes.OPEN_BANKING || $route.path === Routes.ANALYSIS_B2C || $route.path === Routes.SYNTHESIS) && isPerAccount"
                :label="translate('OPEN_BANKING.CATEGORIES.LAST_SALARY_DATE')"
                :value="obData.monthIncomeLastSalaryDate"
                class="recommendations-value-month-negative"
              />
            </div>
          </ResultCard>
        </div>
      </div>
      <div :class="`openbanking-approvals ${$route.path === Routes.SYNTHESIS ? '' : 'breakinside'}`">
        <span class="container-openbanking-analysis-detailed-title">{{ translate('OPEN_BANKING.APPROVALS') }}</span>
        <div v-if="isPerAccount"
             :class="`${isRecovery ? 'recovery-divided container-openbanking-analysis-detailed-divided' : 'container-openbanking-analysis-detailed-divided'}`">
          <ResultCard v-if="aggregationsRemainsToLive"
                      :title="translate('OPEN_BANKING.REMAINS_TO_LIVE')">
            <LabelValuePrinter :label="translate('OPEN_BANKING.CATEGORIES.MONTHLY_AMOUNT')"
                               :value="obData.remainsToLive" />
            <LabelValuePrinter :label="translate('OPEN_BANKING.CATEGORIES.MONTH_NEGATIVE_BALANCE_MEAN')"
                               :value="obData.monthNegativeBalanceMean" />
          </ResultCard>
          <ResultCard v-if="aggregationsIndebtedness"
                      :title="translate('OPEN_BANKING.INDEBTEDNESS')">
            <LabelValuePrinter :label="translate('OPEN_BANKING.DEBT_RATIO')"
                               :value="obData.debtRatio" />
            <LabelValuePrinter :label="translate('OPEN_BANKING.MONTH_OUTCOMES_CREDITS_COUNT_MEAN')"
                               :value="obData.monthOutcomesCreditsCountMean" />
          </ResultCard>
        </div>
        <ResultCard v-if="aggregationsExistingCreditAnalysis"
                    :title="translate('OPEN_BANKING.EXISTING_CREDIT_ANALYSIS')"
                    class="container-openbanking__movements">
          <AnalysisOpenBankingTable :column-headers="columnsIncomes" :table-data="displayCreditsValues" />
          <div class="container-openbanking__direct-debits">
            <div>
              <LabelValuePrinter :label="translate('OPEN_BANKING.CATEGORIES.MONTH_OUTCOMES_CREDITS_COUNT_MIN')"
                                 :value="obData.monthOutcomesCreditsCountMin" />
              <LabelValuePrinter :label="translate('OPEN_BANKING.CATEGORIES.MONTH_OUTCOMES_CREDITS_COUNT_MAX')"
                                 :value="obData.monthOutcomesCreditsCountMax" />
              <LabelValuePrinter :label="translate('OPEN_BANKING.INSTITUTION_ASSOCIATED_WITH_LOAN')"
                                 :value="!isArray(obData.monthOutcomesCreditsKeywords) ? obData.monthOutcomesCreditsKeywords : ''" />
              <template v-if="isArray(obData.monthOutcomesCreditsKeywords)">
                <ul>
                  <li v-for="(keyword, index) in obData.monthOutcomesCreditsKeywords" :key="index">{{ keyword }}</li>
                </ul>
              </template>
            </div>
            <div>
              <LabelValuePrinter :label="translate('OPEN_BANKING.CATEGORIES.MONTH_OUTCOMES_CREDITS_MIN')"
                                 :value="obData.monthOutComesCreditsAmountMin" />
              <LabelValuePrinter :label="translate('OPEN_BANKING.CATEGORIES.MONTH_OUTCOMES_CREDITS_MAX')"
                                 :value="obData.monthOutComesCreditsAmountMax" />
            </div>
          </div>
        </ResultCard>
        <ResultCard v-if="aggregationsHabitat && isPerAccount" :title="translate('OPEN_BANKING.HABITAT')"
                    class="container-openbanking__movements">
          <AnalysisOpenBankingTable :column-headers="columnsIncomes" :table-data="displayHabitatValues" />
          <span class="habitat-text">{{ habitatString }}</span>
        </ResultCard>
        <ResultCard v-if="aggregationsBankCard"
                    :title="translate('OPEN_BANKING.CATEGORIES.BANK_CARD_AND_DEBITS')"
                    class="container-openbanking__movements">
          <div class="container-openbanking__bank-card">
            <template v-for="aggregationBankCard in aggregationBankCardsValues">
              <LabelValuePrinter
                v-if="aggregationBankCard.cond"
                :label="aggregationBankCard.label"
                :value="aggregationBankCard.value"
              />
            </template>
            <template v-if="obData.bankCardNumbers !== '-'">
              <div></div>
              <div class="container-openbanking-analysis-detailed-unique-block-scroll">
                <ul v-for="(number, index) in obData.bankCardNumbers" :key="index">
                  <li>{{ formatCardNumber(number) }}</li>
                </ul>
              </div>
            </template>
            <LabelValuePrinter v-if="obData.bankCardNumbers === '-'"
                               :label="translate('OPEN_BANKING.CATEGORIES.BANK_CARD_NUMBERS')"
                               :value="obData.bankCardNumbers"
            />
          </div>
        </ResultCard>
        <ResultCard v-if="aggregationsOtherAccounts && isPerAccount"
                    :title="translate('OPEN_BANKING.CATEGORIES.OTHER_ACCOUNTS.TITLE')"
                    class="container-openbanking__movements">
          <AnalysisOpenBankingTable :column-headers="columnsIncomes" :table-data="displayOtherAccountValues" />
        </ResultCard>
      </div>
    </ContentCard>
  </template>
</template>

<style scoped lang="scss">
.habitat-text {
  margin-left: 1rem;
  font-size: .8rem;
}
</style>