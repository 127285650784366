<script setup lang="ts">
import { onMounted, ref } from 'vue';
import Button from '@/components/button/Button.vue';
import { copyMessage } from '@/utils/copyClipboard';
import { useToast } from 'vue-toast-notification';
import { translate } from '@/i18n';
import { getApiKey } from '@/apiCalls/apiKeyReset';

const toast = useToast();

const apiKey = ref<string>('');
const displayContent = ref(true);

const copyToClipboard = () => {
  copyMessage(apiKey.value);
  toast.success(translate('BACKOFFICE.USERS.RESET_API_KEY.NEW_API_KEY'));
  displayContent.value = false;
};

onMounted(async () => {
  try {
    const response = await getApiKey();
    apiKey.value = response.data.apiKey;
  } catch (error) {
    displayContent.value = false;
  }
});
</script>

<template>
  <div class="end-of-journey api-key">
    <template v-if="displayContent">
      <span class="api-key__title">{{ translate('RESET_API_KEY.TITLE') }}</span>
      <span class="api-key__warning">️️{{ translate('RESET_API_KEY.WARNING') }}</span>
      <div class="api-key__container">
        <span class="api-key__container__new">{{ apiKey }}</span>
        <Button :label="translate('RESET_API_KEY.COPY_YOUR_KEY')" @click="copyToClipboard" />
      </div>
    </template>
    <template v-else>
      <p>{{ translate('RESET_API_KEY.CANNOT') }} - <a href="mailto:support@getmeelo.com">support@getmeelo.com</a></p>
    </template>
  </div>
</template>