<script setup lang="ts">
import { translate } from "@/i18n";
import ContentCard from "@/components/card/ContentCard.vue";
import TreeNode from "@/components/tree/TreeNode.vue";
import { TreeNodeType } from '@/types/Tree';
import { useStore } from "vuex";
import { computed, onMounted, ref } from "vue";
import {isEmpty, isNil, toUpper} from "lodash";
import { fetchClientContext } from "@/apiCalls/contextApi";
import {createTreeData, GenericContextResponse} from "@/utils/clientContextUtils";

const props = withDefaults(defineProps<{
  journeyId: string;
  toggleExpanded: boolean;
}>(), {
  journeyId: '',
  toggleExpanded: false
});

const store = useStore();

const user = computed(() => store.getters.user);
const companyName = computed(() => user.value.company.name);
const companyParams = computed(() => store.getters.companyParameters);

const displayClientContext = computed(() => {
  const params = companyParams?.value?.displayClientContext;
  return params ? JSON.parse(params) : {};
});

const contextType = computed(() => displayClientContext?.value?.context.type);
const contextName = computed(() => displayClientContext?.value?.context.name);

const shouldDisplay = computed(() => displayClientContext?.value?.display) || false;

const need2Display = ref(false);
const treeData = ref<TreeNodeType[]>();

const isToggled = ref<boolean>(props.toggleExpanded);

onMounted(async () => {
  if (!shouldDisplay || isEmpty(props.journeyId) || isEmpty(displayClientContext.value)) return;
  try {
    await fetchClientContext(props.journeyId, contextType.value, contextName.value, (response: GenericContextResponse) => {
      need2Display.value = !isEmpty(response);
      treeData.value = createTreeData(response, contextName.value, toUpper(companyName.value));
    });
  } catch (e) {
    console.error(e);
  }
});
</script>

<template>
  <ContentCard v-if="shouldDisplay && !isEmpty(treeData) && need2Display" class="client-context-card" :title="`${translate('CLIENT_CONTEXT.TITLE')}${companyName}`">
    <TreeNode v-for="rootNode in treeData" :key="rootNode.id" :node="rootNode" :toggle-expanded="isToggled" />
  </ContentCard>
</template>