<script setup lang="ts">

import Progress from '@/components/bar/Progress.vue';
import ContentCard from '@/components/card/ContentCard.vue';
import ResultCard from '@/components/card/ResultCard.vue';
import { translate } from '@/i18n';
import { ResultRulesEngine, Rules } from '@/types/RulesEngine';
import { isNull, toLower } from 'lodash';
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    data: ResultRulesEngine,
  }>(),
  {}
);

const displayDecision = (decision: boolean | null, warning: string) => {
  if (isNull(decision)) return 'underline';
  if (decision) {
    if (!isNull(warning)) return 'warning';
    return 'success';
  }
  if (!decision) return 'failed';
};

const calculatePercentage = (possibleChecks: number, currentChecks: number): number => {
  return (possibleChecks / currentChecks) * 100;
};

const countNoNullDecision = computed(() => {
  return props.data.rules?.filter((rule: any) => rule.decision !== null)?.length;
});

const displayRecommended = computed(() => {
  if (!props.data.globalDecision) {
    return toLower('RISKY');
  } else if (props.data.globalDecision) {
    if (props.data.nbOfWarnings > 0) {
      return toLower('AVERAGE');
    } else {
      return toLower('GOOD');
    }
  }
});
</script>

<template>
  <ContentCard :title="translate('CHECK_LIST.TITLE')" class="box-output-check-list">
    <div class="box-output-check-list__progress">
      <span>{{ translate('CHECK_LIST.NUMBER_OF_VERIFICATION') }}</span>
      <Progress class="box-output-check-list__progress__bar"
                :progress="calculatePercentage(countNoNullDecision, data.rules?.length)" color="#293354"
                height="10px" />
      <div class="box-output-check-list__progress__count">
        <span class="box-output-check-list__progress__count__bold">{{ countNoNullDecision }}</span>
        <span> / {{ data.rules?.length }}</span>
      </div>
    </div>
    <ResultCard class="box-output-check-list__verifications" :title="translate('CHECK_IBAN.RESULT.VERIFICATION')">
      <div v-for="rule in data.rules">
        <img :src="require(`/public/images/${displayDecision(rule.decision, rule.warning)}-icon.png`)"
             :alt="`${displayDecision(rule.decision, rule.warning)}`"
             class="box-output-check-list__verifications__icon" />
        <span :class="`box-output-check-list__verifications__rule-${displayDecision(rule.decision, rule.warning)}`">{{
            translate(`CHECK_LIST.RULES.${rule.name}`)
          }}</span>
      </div>
    </ResultCard>
    <ResultCard class="box-output-check-list__verifications" :title="translate('CHECK_LIST.MEELO_RECOMMENDATION')">
      <div class="box-output-check-list__verifications__global">
        <span
          :class="`box-output-check-list__verifications__global__decision box-output-check-list__verifications__global__decision__${displayRecommended}`">{{
            translate(`CHECK_LIST.RECOMMENDATION.DECISION.${displayRecommended}`)
          }}</span>
        <span :class="`box-output-check-list__verifications__global__message__${displayRecommended}`">{{
            translate(`CHECK_LIST.RECOMMENDATION.MESSAGE.${displayRecommended}`)
          }}</span>
      </div>
    </ResultCard>
  </ContentCard>
</template>
