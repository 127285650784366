import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "container-openbanking" }
const _hoisted_2 = { class: "ob-financial-analysis-period" }
const _hoisted_3 = { class: "print-ob-analysis-detail-container" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "container-openbanking-content-no-data" }
const _hoisted_6 = { class: "container-openbanking-flex-container" }
const _hoisted_7 = {
  key: 0,
  class: "openbanking-bloc-link"
}
const _hoisted_8 = ["href"]
const _hoisted_9 = {
  key: 1,
  class: "container-openbanking-flex-container-instant-payment"
}
const _hoisted_10 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrintFolderInfo = _resolveComponent("PrintFolderInfo")!
  const _component_PrintApplicantOB = _resolveComponent("PrintApplicantOB")!
  const _component_BoxOutputOpenBankingDetail = _resolveComponent("BoxOutputOpenBankingDetail")!
  const _component_PrintCard = _resolveComponent("PrintCard")!
  const _component_BoxInputStateFolder = _resolveComponent("BoxInputStateFolder")!
  const _component_EditFolderModal = _resolveComponent("EditFolderModal")!
  const _component_DeleteFolderModal = _resolveComponent("DeleteFolderModal")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Loader = _resolveComponent("Loader")!
  const _component_BoxInputOpenBanking = _resolveComponent("BoxInputOpenBanking")!
  const _component_BoxOutputInstantPayment = _resolveComponent("BoxOutputInstantPayment")!
  const _component_BoxInputInstantPayment = _resolveComponent("BoxInputInstantPayment")!
  const _component_BoxOutputOpenBanking = _resolveComponent("BoxOutputOpenBanking")!
  const _component_BoxOutputOpenBankingAlert = _resolveComponent("BoxOutputOpenBankingAlert")!
  const _component_BoxOutputOpenBankingBankStatement = _resolveComponent("BoxOutputOpenBankingBankStatement")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_PrintFolderInfo, {
        entries: {
        uniqId: _ctx.journeyId,
      }
      }, null, 8, ["entries"]),
      (_ctx.checkDataAvailability)
        ? (_openBlock(), _createBlock(_component_PrintApplicantOB, {
            key: 0,
            entries: {
        bankName: _ctx.obFormattedFields.bankName,
        holder: _ctx.obFormattedFields.holderName,
        account: _ctx.obFormattedFields.accountName,
        iban: _ctx.obFormattedFields.iban,
        mainAccount: _ctx.obFormattedFields.topSecondaryAccount,
        monthSalaries: _ctx.obFormattedFields.monthSalaries,
        monthRents: _ctx.obFormattedFields.monthRents,
        monthEndRatio: _ctx.obFormattedFields.monthEndRatio,
        balance: _ctx.obFormattedFields.balance,
        topBankingSupervision: _ctx.obFormattedFields.topBankingSupervision,
        topDeferredDebit: _ctx.obFormattedFields.topDeferredDebit,
        monthEndBalance: _ctx.obFormattedFields.monthEndBalance,
        globalTransactionsCount: _ctx.obFormattedFields.globalTransactionsCount,
      },
            title: _ctx.translate('PRINT.APPLICANT_OB.OB_TITLE'),
            score: Math.round(_ctx.obFormattedFields.score || 0),
            color: _ctx.colorScoreOB
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_2, _toDisplayString(`${_ctx.translate('OPEN_BANKING.FINANCIAL_ANALYSIS_PERIOD')}: ${
              _ctx.obFormattedFields.startStudyPeriod
          } ${_ctx.translate('OPEN_BANKING.TO')} ${
              _ctx.obFormattedFields.endStudyPeriod
          } `), 1)
            ]),
            _: 1
          }, 8, ["entries", "title", "score", "color"]))
        : _createCommentVNode("", true),
      _createVNode(_component_PrintCard, {
        title: "PRINT.OB_ANALYSIS_DETAIL",
        class: "pagebreak"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            (Object.keys(_ctx.obFormattedFields).length > 0 && _ctx.checkDataAvailability)
              ? (_openBlock(), _createBlock(_component_BoxOutputOpenBankingDetail, {
                  key: 0,
                  "ob-data": _ctx.obFormattedFields,
                  "can-open-banking": _ctx.permissionsSorted.includes('KIT_OB_OB_DETAILS_GLOBAL'),
                  permissions: _ctx.permissionsSorted
                }, null, 8, ["ob-data", "can-open-banking", "permissions"]))
              : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.translate('OPEN_BANKING.PROVIDER_ERROR')), 1)
                  ])
                ]))
          ])
        ]),
        _: 1
      }),
      (_ctx.displayStateFolder)
        ? (_openBlock(), _createBlock(_component_BoxInputStateFolder, {
            key: 1,
            "case-number": _ctx.businessId,
            "case-state": _ctx.journeyIdStatus,
            onDeleteFolder: _ctx.openModalDeleteFolder,
            states: _ctx.states(),
            motive: _ctx.motive,
            onSaveFolder: _ctx.openModalEditFolder
          }, null, 8, ["case-number", "case-state", "onDeleteFolder", "states", "motive", "onSaveFolder"]))
        : _createCommentVNode("", true),
      _createVNode(_component_EditFolderModal, {
        "open-modal": _ctx.modalEditFolder,
        "onUpdate:openModal": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modalEditFolder) = $event)),
        "case-number": _ctx.businessId,
        "case-state": _ctx.journeyIdStatus,
        onConfirmSelection: _ctx.editFolder
      }, null, 8, ["open-modal", "case-number", "case-state", "onConfirmSelection"]),
      _createVNode(_component_DeleteFolderModal, {
        "open-modal": _ctx.modalDeleteFolder,
        "onUpdate:openModal": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modalDeleteFolder) = $event)),
        onConfirmSelection: _ctx.deleteFolder,
        "case-number": _ctx.businessId
      }, null, 8, ["open-modal", "onConfirmSelection", "case-number"]),
      _createElementVNode("div", _hoisted_6, [
        (!(_ctx.openBankingFields) && _ctx.permissionsSorted?.some(elt => elt.includes('KIT_OB_OB_INPUT_MAIL') || elt.includes('KIT_OB_OB_INPUT_TEXT_MESSAGE')))
          ? (_openBlock(), _createBlock(_component_BoxInputOpenBanking, {
              key: 0,
              onGenerateLink: _ctx.generateLink,
              "prop-prefill-client-form": _ctx.prefillClientForm,
              class: "container-openbanking-flex-container-input no-print",
              permissions: _ctx.permissionsSorted,
              "can-open-baking": _ctx.openBankingInputPermissions
            }, {
              default: _withCtx(() => [
                (_ctx.openBankingLink != '')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      (_ctx.obStarted && !_ctx.obEnded)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                            _createElementVNode("a", {
                              href: _ctx.openBankingLink,
                              target: "_blank"
                            }, [
                              _createVNode(_component_Button, {
                                class: "openbanking-bloc-link-button",
                                onClick: _ctx.goOpenBanking,
                                label: _ctx.translate('BUTTONS.JOURNEY_OPEN_BANKING')
                              }, null, 8, ["onClick", "label"])
                            ], 8, _hoisted_8)
                          ]))
                        : _createCommentVNode("", true)
                    ], 64))
                  : _createCommentVNode("", true),
                (_ctx.beginOpenBanking && !_ctx.obEnded)
                  ? (_openBlock(), _createBlock(_component_Loader, { key: 1 }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["onGenerateLink", "prop-prefill-client-form", "permissions", "can-open-baking"]))
          : _createCommentVNode("", true),
        (!(_ctx.openBankingFields) && _ctx.permissionsSorted?.some(elt => elt.includes('KIT_OB_OB_INPUT_MAIL') || elt.includes('KIT_OB_OB_INPUT_TEXT_MESSAGE')))
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              (!_ctx.newInstantPayment)
                ? (_openBlock(), _createBlock(_component_BoxOutputInstantPayment, {
                    key: 0,
                    class: "openbanking-instant-payment",
                    "instant-payments": _ctx.openBankingInstantPaymentData,
                    loading: _ctx.newInstantPaymentLoader,
                    "refreshing-data-loader": _ctx.refreshedInstantPaymentData,
                    onRefreshInstantPayment: _ctx.handleInstantPaymentTaskRefresh,
                    onNewInstantPayment: _ctx.handleNewInstantPayment
                  }, null, 8, ["instant-payments", "loading", "refreshing-data-loader", "onRefreshInstantPayment", "onNewInstantPayment"]))
                : _createCommentVNode("", true),
              (_ctx.newInstantPayment)
                ? (_openBlock(), _createBlock(_component_BoxInputInstantPayment, {
                    key: 1,
                    class: "openbanking-instant-payment-form",
                    "new-instant-payment-loader": _ctx.newInstantPaymentLoader,
                    onGeneratePisLink: _ctx.handleNewGeneratePisLink,
                    onBackButtonClicked: _ctx.handleNewInstantPayment
                  }, null, 8, ["new-instant-payment-loader", "onGeneratePisLink", "onBackButtonClicked"]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      (Object.keys(_ctx.obFormattedFields).length>0)
        ? (_openBlock(), _createBlock(_component_BoxOutputOpenBanking, {
            key: 2,
            "ob-data": _ctx.obFormattedFields,
            "color-score": _ctx.colorScoreOB,
            class: "no-print",
            "refresh-loader": this.obRefreshedDataLoading,
            "disable-loader": this.obDisabledDataLoading,
            onRefreshTask: _cache[2] || (_cache[2] = ($event: any) => (this.handleTaskRefresh())),
            onDisableContinuousAccess: _cache[3] || (_cache[3] = ($event: any) => (this.handleDisableContinuousAccess())),
            "can-open-banking": _ctx.permissionsSorted.includes('KIT_OB_OB_DETAILS_GLOBAL'),
            company: _ctx.user?.company?.name
          }, null, 8, ["ob-data", "color-score", "refresh-loader", "disable-loader", "can-open-banking", "company"]))
        : _createCommentVNode("", true),
      (Object.keys(_ctx.obFormattedFields).length>0 && _ctx.checkDataAvailability)
        ? (_openBlock(), _createBlock(_component_BoxOutputOpenBankingDetail, {
            key: 3,
            class: "no-print",
            "ob-data": _ctx.obFormattedFields,
            onToAccountSummaryEvent: _ctx.handleAccountSummary,
            "can-open-banking": _ctx.permissionsSorted.includes('KIT_OB_OB_DETAILS_GLOBAL'),
            permissions: _ctx.permissionsSorted
          }, null, 8, ["ob-data", "onToAccountSummaryEvent", "can-open-banking", "permissions"]))
        : _createCommentVNode("", true),
      ( Object.keys(_ctx.obFormattedFields).length>0 && _ctx.checkDataAvailability)
        ? (_openBlock(), _createBlock(_component_BoxOutputOpenBankingAlert, {
            key: 4,
            "ob-data": _ctx.obFormattedFields,
            class: "no-print",
            "can-open-banking": _ctx.permissionsSorted.includes('KIT_OB_OB_ALERTS_GLOBAL')
          }, null, 8, ["ob-data", "can-open-banking"]))
        : _createCommentVNode("", true),
      (_ctx.isAccountSummaryButtonClicked)
        ? (_openBlock(), _createBlock(_component_BoxOutputOpenBankingBankStatement, {
            key: 5,
            class: "no-print",
            "ob-data": _ctx.obFormattedFields
          }, null, 8, ["ob-data"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.openBankingFields)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          (!_ctx.newInstantPayment)
            ? (_openBlock(), _createBlock(_component_BoxOutputInstantPayment, {
                key: 0,
                class: "openbanking-instant-payment",
                "instant-payments": _ctx.openBankingInstantPaymentData,
                loading: _ctx.newInstantPaymentLoader,
                "refreshing-data-loader": _ctx.refreshedInstantPaymentData,
                onRefreshInstantPayment: _ctx.handleInstantPaymentTaskRefresh,
                onNewInstantPayment: _ctx.handleNewInstantPayment
              }, null, 8, ["instant-payments", "loading", "refreshing-data-loader", "onRefreshInstantPayment", "onNewInstantPayment"]))
            : _createCommentVNode("", true),
          (_ctx.newInstantPayment)
            ? (_openBlock(), _createBlock(_component_BoxInputInstantPayment, {
                key: 1,
                class: "openbanking-instant-payment-form",
                onGeneratePisLink: _ctx.handleNewGeneratePisLink,
                onBackButtonClicked: _ctx.handleNewInstantPayment
              }, null, 8, ["onGeneratePisLink", "onBackButtonClicked"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}