<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';

import { translate } from '@/i18n';
import type { AccountantVerificationData, warningListAccountantVerification } from '@/types/WarningListType';

//@ts-ignore
import ContentCard from '@/components/card/ContentCard.vue';
//@ts-ignore
import Loader from '@/components/loader/Loader.vue';
import { isBoolean } from 'lodash';

const props = defineProps<{
  accountantVerificationData: AccountantVerificationData | undefined;
  warningList: warningListAccountantVerification | undefined;
  isLoading: boolean;
}>();

const warningListToDisplay = ref({
  phoneMatches: false,
  registrationAndCountryMatches: false,
  emailMatches: false
});

const warningListCard = computed(() => ({
  COMPANY: {
    details: [
      {
        key: 'SIREN',
        value: props.accountantVerificationData?.REGISTRATION_NUMBER
      }
    ],
    warningKey: 'REGISTRATION_NUMBER',
    isInWarningList: warningListToDisplay.value?.registrationAndCountryMatches
  },
  PHONE_NUMBER: {
    details: [
      {
        key: 'PHONE_NUMBER',
        value: props.accountantVerificationData?.PHONE
      }
    ],
    warningKey: 'PHONE',
    isInWarningList: warningListToDisplay.value?.phoneMatches
  },
  EMAIL_ADDRESS: {
    details: [
      {
        key: 'EMAIL_ADDRESS',
        value: props.accountantVerificationData?.EMAIL
      }
    ],
    warningKey: 'EMAIL',
    isInWarningList: warningListToDisplay.value?.emailMatches
  }
}));

const isVerified = (value: { details: { key: string; value?: string }[] }) =>
  value.details.some((el) => !!el.value);

const getWarningMessage = (warningKey: string, isInWarningList?: boolean) => {
  return translate(`WARNING_LIST.${isBoolean(isInWarningList) && isInWarningList ? 'MESSAGE_ALERT_' + warningKey : 'MESSAGE_NO_ALERT_ACCOUNTANT_VERIFICATION'}`);
};

const verifyWarningList = (value: { warningKey: string, isInWarningList: boolean }) => {
  return !(['EMAIL', 'PHONE', 'REGISTRATION_NUMBER'].includes(value.warningKey) && value.isInWarningList);
};

watch(() => props.warningList, (newValue) => {
  warningListToDisplay.value = newValue;
});

onMounted(() => {
  props.warningList.value?.forEach((item: any) => {
    if (item.phoneMatches) warningListToDisplay.value.phoneMatches = true;
    if (item.registrationAndCountryMatches) warningListToDisplay.value.registrationAndCountryMatches = true;
    if (item.emailMatches) warningListToDisplay.value.emailMatches = true;
  });
});
</script>

<template>
  <ContentCard
    :title="translate('ACCOUNTANT_VERIFICATION')"
    class="accountant-verification"
  >
    <Loader v-if="isLoading" />
    <div v-else class="box-output-accountant-verification-container">
      <div
        v-for="(value, key) in warningListCard"
        :key="key"
        class="box-output-accountant-verification-card"
      >
        <span class="box-output-accountant-verification-card-title">
          {{ translate(`WARNING_LIST.${key}`) }}
        </span>
        <span v-if="!isVerified(value)" class="unverified-info">{{
            translate('WARNING_LIST.NOT_VERIFIED')
          }}</span>
        <template v-else>
          <div
            v-for="detail in value.details"
            :key="detail.value"
            class="box-output-accountant-verification-card-details"
          >
            <span>{{ translate(`WARNING_LIST.${detail.key}`) }} : </span>
            <span>{{ detail.value }}</span>
          </div>
          <ContentCard
            :title="translate('WARNING_LIST.TITLE')"
            :class="`warning-list ${
             verifyWarningList(value) && 'warning-list-no-data'
            }`"
          >
            <span class="warning-list-message">{{ getWarningMessage(value.warningKey, value.isInWarningList) }}</span>
          </ContentCard>
        </template>
      </div>
    </div>
  </ContentCard>
</template>
