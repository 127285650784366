<template class="recovery">
  <BoxInputCustomerInfo v-if="isNotCompleted"
                        :propPrefillClientForm="prefillClientForm"
                        @customer-information="customerInformation"
  />
  <div v-if="!isNotCompleted || applicantData" class="recovery-result">
    <div>
      <ResultCard :title="translate('RECOVERY.REQUEST')" class="recovery-request">
        <XMasBonhomme :style="{top: '14vh', left: '34vw'}" :value="6" />
        <div v-if="applicantData" class="recovery-request-information">
          <div class="recovery-request-information-first-block">
            <div class="recovery-request-information-first-block-name">
            <span>{{
                `${translate('IDENTITY.NAME')} ${translate('IDENTITY.FIRSTNAME')} : ${applicantData.lastname} ${applicantData.firstname}`
              }}</span>
              <span>{{ `${translate('RECOVERY.CLIENT_ID')} : ${externalId}` }}</span>
            </div>
            <div class="recovery-request-synthesis">
              <template v-if="Object.keys(obFormattedFields).length>0">
                <ResultsPdfExport ref="exportPdf" />
                <Button :label="translate('BUTTONS.EXPORT_PDF')" v-on:click="generateReport" />
              </template>
            </div>
          </div>
          <div v-if="(causesFraudData != null && applicantData)" class="recovery-request-information-second-block">
            <span>{{ `${translate('IDENTITY.EMAIL')} : ${applicantData.email}` }}</span>
            <div class="recovery-request-information-second-block-verification">
              <VerificationResult :value="causesFraudData.contact.email_exists" :label="translate('VERIFIED')"
                                  :tooltip="translate('TOOLTIP.EMAIL_VERIFICATION')" />
              <VerificationResult :value="causesFraudData.contact.email_name" :label="translate('LINKED')"
                                  :tooltip="translate('TOOLTIP.EMAIL_LINK')"
                                  :is-warning="true" />
            </div>
            <span>{{ `${translate('IDENTITY.PHONE_NUMBER')} : ${applicantData.mobilePhone}` }}</span>
            <div class="recovery-request-information-second-block-verification">
              <VerificationResult :value="causesFraudData.contact.phone_exists" :label="translate('VERIFIED')"
                                  :tooltip="translate('TOOLTIP.MOBILE_PHONE_VERIFICATION')" />
              <VerificationResult :value="causesFraudData.contact.phone_name" :label="translate('LINKED')"
                                  :tooltip="translate('TOOLTIP.MOBILE_PHONE_LINK')"
                                  :is-warning="true" />
            </div>
          </div>
        </div>
      </ResultCard>
      <ResultCard v-if="Object.keys(obFormattedFields).length>0"
                  :title="translate('OPEN_BANKING.RECOMMENDATIONS')"
                  class="recovery-recommendations">
        <span>{{
            `${translate('OPEN_BANKING.CATEGORIES.RECOMMENDED_DEBIT_DATE')} : ${obFormattedFields.recommendedDebitDate}`
          }}</span>
        <span>{{
            `${translate('OPEN_BANKING.CATEGORIES.RECOMMENDED_DEBIT_AMOUNT')} : ${obFormattedFields.recommendedDebitAmount}`
          }}</span>
      </ResultCard>
      <ResultCard v-if="Object.keys(obFormattedFields).length>0"
                  :title="translate('RECOVERY.FINANCIAL_SITUATION')" class="recovery-financial-situation">
        <span>{{ `${translate('OPEN_BANKING.BALANCE')} : ${obFormattedFields.balance}` }}</span>
        <span>{{ `${translate('RECOVERY.AVERAGE_DISPOSABLE_INCOME')} : ${obFormattedFields.remainsToLive}` }}</span>
        <span>{{
            `${translate('RECOVERY.AVERAGE_MONTHLY_INCOME')} : ${obFormattedFields.monthIncomesAmountMean}`
          }}</span>
        <span>{{ `${translate('RECOVERY.LOWEST_INCOME')} : ${obFormattedFields.monthIncomesAmountMin}` }}</span>
        <span>{{
            `${translate('RECOVERY.LACK_OF_INCOME')} : ${obFormattedFields?.monthIncomesAmountMean?.length > 0 ? translate('RECOVERY.LACK_OF_INCOME_SOURCES_DETECTED') : translate('RECOVERY.LACK_OF_INCOME_SOURCES_NOT_DETECTED')}`
          }}</span>
      </ResultCard>
    </div>
    <div>
      <ResultCard :title="translate('RECOVERY.FINANCIAL_ANALYSIS')" class="recovery-financial-analysis">
        <div v-if="Object.keys(obFormattedFields).length === 0" class="recovery-request-links">
          <Button :label="translate('BUTTONS.LINK_TEXT_MESSAGE')"
                  @click="generateLink(prefillClientForm.phone, '')"
                  :disabled="Object.keys(obFormattedFields).length>0" />
          <Button :label="translate('BUTTONS.LINK_EMAIL')"
                  @click="generateLink({disabled: false, value: '+33'}, prefillClientForm.email)"
                  :disabled="Object.keys(obFormattedFields).length>0" />
          <Button v-if="shouldShowButton" :label="translate('BUTTONS.LINK_BROWSER')"
                  @click="generateLink(null, null)"
                  :disabled="Object.keys(obFormattedFields).length>0" />
        </div>
        <template v-if="shouldShowButton && openBankingLink != ''">
          <div class="openbanking-bloc-link"
               v-if="obStarted && !obEnded">
            <a :href="openBankingLink" target="_blank">
              <Button class="openbanking-bloc-link-button" @click="goOpenBanking"
                      :label="translate('BUTTONS.JOURNEY_OPEN_BANKING')" />
            </a>
          </div>
        </template>
        <div v-if="Object.keys(obFormattedFields).length>0" class="recovery-request-date">
          <span>{{ `${translate('RECOVERY.ANALYSIS_DATE')} : ${new Date().toLocaleDateString()}` }}</span>
          <span v-if="Object.keys(obFormattedFields).length>0">{{
              `${translate('OPEN_BANKING.ANALYSIS_PERIOD')} ${translate('FROM')} ${obFormattedFields.startStudyPeriod} ${translate('OPEN_BANKING.TO')} ${obFormattedFields.endStudyPeriod}`
            }}</span>
          <span>{{
              `${translate('OPEN_BANKING.BANK_NAME')} : ${obFormattedFields.bankName} (${translate('RECOVERY.RISK_LEVEL')} `
            }}
          <img
            :src="require(`/public/images/${displayTopBankRiskLevel(obFormattedFields.topBankRiskLevel)}`)"
            :alt="obFormattedFields.topBankRiskLevel"
            class="icon"><span>{{ ` ${displayTopBankRiskDescription(obFormattedFields.topBankRiskLevel)})` }}
          </span>
        </span>
          <span>{{ `${translate('OPEN_BANKING.HOLDER')} : ${obFormattedFields.holderName}` }}</span>
          <span>{{ `${translate('OPEN_BANKING.MAIN_ACCOUNT')} : ${obFormattedFields.topSecondaryAccount}` }}</span>
        </div>
      </ResultCard>
      <ResultCard v-if="Object.keys(obFormattedFields).length>0" :title="translate('RECOVERY.FINANCIAL_PRESSURE')"
                  class="recovery-financial-pressure">
        <div class="recovery-financial-pressure-content">
          <span class="recovery-financial-pressure-content-subtitle">{{ `${translate('RECOVERY.CREDITS')} :` }}</span>
          <span>{{ `${translate('OPEN_BANKING.DEBT_RATIO')} : ${obFormattedFields.debtRatio}` }}</span>
          <span>{{
              `${translate('OPEN_BANKING.MONTH_OUTCOMES_CREDITS_COUNT_MEAN')} : ${obFormattedFields.monthOutcomesCreditsCountMean}`
            }}</span>
        </div>
        <div class="recovery-financial-pressure-content">
          <span class="recovery-financial-pressure-content-subtitle">{{
              `${translate('RECOVERY.DEBT_COLLECTION_AGENCIES')} :`
            }}</span>
          <span>{{
              `${translate('OPEN_BANKING.ALERTS.AMOUNT')} : ${obFormattedFields.monthOutcomesCashCollectionsAmountMean}`
            }}</span>
        </div>
      </ResultCard>
      <ResultCard v-if="Object.keys(obFormattedFields).length>0"
                  :title="translate('RECOVERY.CUSTOMER_RELIABILITY')" class="recovery-customer-reliability">
        <div class="recovery-customer-reliability-content">
          <ScoreCircleChart
            class="circle-chart score-open-banking-recovery"
            :circle-value="Math.round(obFormattedFields.score)" :circle-width="'40%'"
            :tooltip="translate('TOOLTIP.SCORE_OPEN_BANKING')"
            :circle-color="colorScore"
          />
          <div class="recovery-customer-reliability-content-right">
        <span>{{
            `${translate('OPEN_BANKING.TOP_BANKING_SUPERVISION')} : ${obFormattedFields.topBankingSupervision}`
          }}</span>
            <span>{{ `${translate('RECOVERY.REJECTION')} : ${obFormattedFields.monthOutcomesRejects}` }}</span>
            <span>{{
                `${translate('RECOVERY.INTERVENTION_FEES')} : ${obFormattedFields.monthOutcomesInterventionFees}`
              }}</span>
            <span>{{
                `${translate('RECOVERY.THIRD_PARTY_NOTICE')} : ${obFormattedFields.monthOutcomesSeizureFees}`
              }}</span>
            <span>{{ `${translate('RECOVERY.GAMBLING')} : ${obFormattedFields.monthOutcomesGamblings}` }}</span>
          </div>
        </div>
      </ResultCard>
    </div>
    <ResultCard :title="translate('OPEN_BANKING.DAILY_BALANCE')"
                v-if="!!obFormattedFields?.balancePerDay"
    >
      <LineChart background-color-chart="#4FA0FF"
                 :abscissa-chart="formatBalance(obFormattedFields?.balancePerDay, 'date')"
                 :data-chart="formatBalance(obFormattedFields?.balancePerDay, 'amount')"
                 :label-chart="translate('OPEN_BANKING.DAILY_BALANCE')" border-color-chart="#4FA0FF" />
    </ResultCard>
    <ResultCard :title="translate('OPEN_BANKING.MONTHLY_BALANCE')"
                v-if="!!obFormattedFields?.balancePerMonth"
    >
      <BarChart background-color-chart="#4FA0FF"
                :abscissa-chart="formatBalance(obFormattedFields?.balancePerMonth, 'date')"
                :data-chart="formatBalance(obFormattedFields?.balancePerMonth, 'amount')"
                :label-chart="translate('OPEN_BANKING.MONTHLY_BALANCE')" />
    </ResultCard>
    <XMasBonhomme :style="{top: '148vh', left: '75vw'}" :value="9" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Button from '@/components/button/Button.vue';
import BoxOutputOpenBankingAlert from '@/components/box-output/BoxOutputOpenBankingAlert.vue';
import BoxInputOpenBanking from '@/components/box-input/BoxInputOpenBanking.vue';
import BoxOutputOpenBanking from '@/components/box-output/BoxOutputOpenBanking.vue';
import BoxOutputOpenBankingDetail from '@/components/box-output/BoxOutputOpenBankingDetail.vue';
import { translate } from '@/i18n';
import OpenBankingMixin from '@/mixins/openBankingMixin';
import TemplateMixin from '@/mixins/templateMixin';
import BoxInputRecovery from '@/components/box-input/BoxInputRecovery.vue';
import BoxInputCustomerInfo from '@/components/box-input/recovery/BoxInputCustomerInfo.vue';
import ResultCard from '@/components/card/ResultCard.vue';
import BarChart from '@/components/chart/BarChart.vue';
import LineChart from '@/components/chart/LineChart.vue';
import { getJourneyId, sendApplicantInfoToContext } from '@/apiCalls/contextApi';
import { CustomerProfileV3 } from '@/types/CustomerProfile';
import ResultsPdfExport from '@/components/pdf-export/ResultsPdfExport.vue';
import { getCustomerFraudDataV3 } from '@/apiCalls/scoringApi';
import VerificationResult from '@/components/VerificationResult.vue';
import Loader from '@/components/loader/Loader.vue';
import ScoreCircleChart from '@/components/ScoreCircleChart.vue';
import XMasBonhomme from '@/components/x-mas/XMasBonhomme.vue';
import { formatBalance } from '@/utils/openBankingUtils';
import { sendApplicantInformation } from '@/apiCalls/taskApi';
import { getData } from '@/apiCalls/taskApi';
import { RECOVERY_FIELDS } from '@/utils/dtoFields';


export default defineComponent({
  methods: {
    translate,
    formatBalance,
    async displayInformations() {
      getData(this.journeyId, RECOVERY_FIELDS).then(async ({ data: { data } }: any) => {
        this.contextData = data ? data : {};
      });
    },
    async customerInformation(customerProfile: CustomerProfileV3, idCustomer: string) {
      this.isNotCompleted = false;
      this.isScoreLoading = true;
      this.externalId = idCustomer;
      if (this.journeyId === undefined) await this.createJourneyId('recovery', idCustomer);
      const { data: applicantInfo } = await sendApplicantInformation(customerProfile, this.journeyId);
      this.contextData.applicant = applicantInfo != null ? [applicantInfo] : [];
      getCustomerFraudDataV3(this.customerProfileV3, this.journeyId).then(async (response: any) => {
        this.contextData.web_score_b2c = [response.result.score];
        this.colorB2C = await this.defineColor('score_b2c', this.scoreFraudData, this.journeyId);
        this.contextData.causes_web_score_b2c = response?.causes != null ? [response.causes] : [];
        this.isScoreLoading = false;
      });
      await this.displayInformations();
    },
    cleanAnalysis() {
      this.isNotCompleted = true;
      this.cleanOpenBanking();
      this.contextData = {};
      this.prefillClientForm = {
        lastName: { disabled: false, value: '' },
        firstNames: { disabled: false, value: '' },
        phone: { disabled: false, value: '+33' },
        email: { disabled: false, value: '' },
        idCustomer: { disabled: false, value: '' }
      };
      this.externalId = '';
      this.isScoreLoading = false;
    },
    restartAnalysis() {
      this.isNotCompleted = true;
      this.cleanOpenBanking();
      this.isScoreLoading = false;
      this.$router.push(`/recovery`);
    },
    generateReport() {
      (this.$refs.exportPdf as (typeof ResultsPdfExport)).generateReport(this.applicantData, this.externalId, this.obFormattedFields, this.causesFraudData, this.colorScore, this.abscissaLineChart, this.detailsLineChart, this.abscissaBarChart, this.detailsBarChart);
    },
    displayTopBankRiskLevel(riskLevel: number) {
      if (riskLevel === 0 || riskLevel === 1) {
        return 'success-icon.png';
      } else if (riskLevel === 2) {
        return 'warning-icon.png';
      } else if (riskLevel === 3) {
        return 'failed-icon.png';
      } else {
        return 'null-icon.png';
      }
    },
    displayTopBankRiskDescription(riskLevel: number) {
      if (riskLevel === 0 || riskLevel === 1) {
        return translate('RECOVERY.LOW');
      } else if (riskLevel === 2) {
        return translate('RECOVERY.MODERATE');
      } else if (riskLevel === 3) {
        return translate('RECOVERY.RISKY');
      } else {
        return '';
      }
    },
    checkShouldShowButton() {
      const host = window.location.host;
      return host.includes('dev') || host.includes('stg') || host.includes('localhost');
    }
  },
  components: {
    ScoreCircleChart,
    Loader,
    VerificationResult,
    ResultsPdfExport,
    ResultCard,
    BoxInputCustomerInfo,
    BoxInputRecovery,
    BoxOutputOpenBankingDetail,
    BoxOutputOpenBanking,
    BoxInputOpenBanking,
    BoxOutputOpenBankingAlert,
    Button,
    XMasBonhomme,
    BarChart,
    LineChart
  },
  mixins: [OpenBankingMixin, TemplateMixin],
  data: () => ({
    prefillClientForm: {
      lastName: { disabled: false, value: '' },
      firstNames: { disabled: false, value: '' },
      phone: { disabled: false, value: '+33' },
      email: { disabled: false, value: '' },
      idCustomer: { disabled: false, value: '' }
    } as any,
    isNotCompleted: false,
    externalId: '',
    isScoreLoading: false,
    shouldShowButton: false,
    colorScore: '#D9D9D9',
    abscissaLineChart: [] as string[],
    detailsLineChart: [] as number[],
    abscissaBarChart: [] as string[],
    detailsBarChart: [] as number[]
  }),
  watch: {
    applicantData(data: any) {
      this.prefillClientForm.email.value = data?.email;
      this.prefillClientForm.email.disabled = !!data?.email;
      this.prefillClientForm.phone.value = data?.mobilePhone && data.mobilePhone.length >= 5 ? data.mobilePhone : null;
      this.prefillClientForm.phone.disabled = !!data?.mobilePhone;
      this.prefillClientForm.firstNames.value = data?.firstname;
      this.prefillClientForm.firstNames.disabled = !!data?.firstname;
      this.prefillClientForm.lastName.value = data?.lastname;
      this.prefillClientForm.lastName.disabled = !!data?.lastname;
      this.prefillClientForm.idCustomer.value = data?.idCustomer;
      this.prefillClientForm.idCustomer.disabled = !!data?.idCustomer;
    },
    async obFormattedFields() {
      if (typeof this.obFormattedFields.score !== 'undefined' && this.colorScore === '#D9D9D9') {
        this.colorScore = await this.defineColor('score_ob', this.obFormattedFields.score, this.journeyId);
        this.abscissaLineChart = this.formatBalance(this.obFormattedFields?.balancePerDay, 'date');
        this.detailsLineChart = this.formatBalance(this.obFormattedFields?.balancePerDay, 'amount');
        this.abscissaBarChart = this.formatBalance(this.obFormattedFields?.balancePerMonth, 'date');
        this.detailsBarChart = this.formatBalance(this.obFormattedFields?.balancePerMonth, 'amount');
      }
    }
  },
  props: {
    obData: { type: Object },
    contextData: { type: Object }
  },
  async created() {
    window.addEventListener('resetRecoveryAnalysis', this.cleanAnalysis);

    if (this.journeyId === undefined) this.isNotCompleted = true;

    this.shouldShowButton = this.checkShouldShowButton();
  },
  beforeUnmount() {
    window.removeEventListener('resetRecoveryAnalysis', this.cleanAnalysis);
  },
  async mounted() {
    if (this.journeyId) {
      const response = await getJourneyId(this.journeyId);
      this.externalId = response.externalId;
    }
  },
  computed: {
    customerProfileV3() {
      return {
        identity: {
          lastName: this.prefillClientForm.lastName.value,
          firstName: this.prefillClientForm.firstNames.value,
          birthDate: '',
          email: this.prefillClientForm.email.value,
          mobileNumber: this.prefillClientForm.phone.value && this.prefillClientForm.phone.value.length >= 5 ? this.prefillClientForm.phone.value : null
        },
        address: {
          street: '',
          city: '',
          zipCode: '',
          country: ''
        }
      };
    }

  }
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";

.recovery {
  &-request {
    &-information {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      &-first-block {
        display: grid;
        grid-template-columns: 320px 1fr;

        &-name {
          display: flex;
          flex-direction: column;
        }
      }

      &-second-block {
        display: grid;
        grid-template-columns: 320px 1fr;
        gap: 10px;
        align-items: center;
        max-height: 5rem;

        &-verification {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }

    &-subtitle {
      text-decoration: underline;
    }

    &-links, &-synthesis {
      text-align: center;
    }

    &-date {
      display: flex;
      flex-direction: column;
    }

    &-right-block {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }

  &-request, &-recommendations, &-financial-analysis, &-financial-pressure {
    margin-bottom: 0.5rem;
  }

  &-result {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin: 0 1rem;
  }

  &-financial-pressure {
    &-content {
      display: flex;
      flex-direction: column;

      &-subtitle {
        font-weight: bold;
      }
    }
  }

  &-customer-reliability {
    &-content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      &-right {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.icon {
  height: 1.2rem;
}

.openbanking-bloc-link {
  text-align: center;
}
</style>