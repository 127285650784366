<script setup lang="ts">
import ContentCard from '@/components/card/ContentCard.vue';
import { translate } from '@/i18n';
import Loader from '@/components/loader/Loader.vue';
import { computed } from 'vue';

const props = defineProps<{
  sirenWarningList: boolean,
  isLoading: boolean
  country: string
}>();

const registrationNumberMessage = computed(() => {
  return props.country === 'FR' ? 'MESSAGE_ALERT_SIREN' : 'MESSAGE_ALERT_REGISTRATION_NUMBER_COUNTRY';
});

</script>

<template>
  <ContentCard v-if="isLoading" :title="translate('WARNING_LIST.TITLE')" class="warning-list-loading">
    <Loader />
  </ContentCard>
  <ContentCard v-else :title="translate('WARNING_LIST.TITLE')"
               :class="`${sirenWarningList ? 'warning-list warning-list-siren' : 'warning-list-no-data warning-list-siren'}`">
    <span
      class="warning-list-message">{{ `${sirenWarningList ? translate(`WARNING_LIST.${registrationNumberMessage}`) : translate('WARNING_LIST.MESSAGE_NO_ALERT_SIREN')}`
      }}</span>
  </ContentCard>
</template>