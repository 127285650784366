<template>
  <div class="account-page">
    <XMasBonhomme :style="{top: '34vh', left: '75vw'}" :value="15" />
    <span class="account-page-title">{{ translate('ACCOUNT_PAGE.TITLE') }}</span>
    <ContentCard class="account-page-content" :title="translate('ACCOUNT_PAGE.INFORMATION')">
      <div class="account-page-content-block">
        <ResultCard :title="translate('ACCOUNT_PAGE.GENERAL_INFORMATION')">
          <LabelValuePrinter :label="translate('ACCOUNT_PAGE.COMPANY')" :value="user?.companyName" />
          <LabelValuePrinter :label="translate('ACCOUNT_PAGE.USERNAME')" :value="user?.username" />
          <LabelValuePrinter :label="translate('ACCOUNT_PAGE.EMAIL')" :value="email" />
          <LabelValuePrinter :label="translate('ACCOUNT_PAGE.PHONE_NUMBER')"
                             :value="phone ? phone : translate('ACCOUNT_PAGE.PHONE_NUMBER_NOT_SPECIFIED')" />
        </ResultCard>
        <ResultCard :title="translate('ACCOUNT_PAGE.TWO_FACTOR_AUTHENTICATION.TITLE')">
          <LabelValuePrinter :label="translate('ACCOUNT_PAGE.TWO_FACTOR_AUTHENTICATION.REQUIRED')"
                             :value="user?.company?.twoFactorRequired ? translate('YES') : translate('NO')" />
          <LabelValuePrinter :label="translate('ACCOUNT_PAGE.TWO_FACTOR_AUTHENTICATION.MODE.TITLE')"
                             :value="translate( `ACCOUNT_PAGE.TWO_FACTOR_AUTHENTICATION.MODE.${user?.twoFactorStatus !== 'DISABLED' ? user?.twoFactorStatus : 'DISABLED'}`)" />
          <LabelValuePrinter :label="translate('ACCOUNT_PAGE.TWO_FACTOR_AUTHENTICATION.CHECKED')"
                             :value="user?.isTwoFactorAuthenticationChecked ? translate('YES') : translate('NO')" />
        </ResultCard>
      </div>
      <div class="account-page-content-action">
        <Button :label="translate('ACCOUNT_PAGE.TWO_FACTOR_AUTHENTICATION.EDIT')"
                v-on:click="pushEditQuery(edit === 'mode' ? undefined : 'mode')" />
      </div>
    </ContentCard>
    <TwoFactorAuthenticationSetupForm v-if="edit === 'mode'"
                                      v-on:success="setup2FA"
                                      :with-disabled="!user?.isTwoFactorAuthenticationRequiredByCompany"
                                      :is-required-by-company="user?.isTwoFactorAuthenticationRequiredByCompany"
                                      :email="email"
                                      :is-checked="isAlready2FA"
                                      :authentication-status="user?.twoFactorStatus"
    />
    <TwoFactorAuthenticationForm v-else-if="edit === 'validate'" :authentication-method="authMethod" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import login from '@/apiCalls/login';
import Button from '@/components/button/Button.vue';
import TwoFactorAuthenticationSetupForm from '@/views/login/TwoFactorAuthenticationSetupForm.vue';
import TwoFactorAuthenticationForm from '@/views/login/TwoFactorAuthenticationForm.vue';
import { translate } from '@/i18n';
import ContentCard from '@/components/card/ContentCard.vue';
import ResultCard from '@/components/card/ResultCard.vue';
import LabelValuePrinter from '@/components/LabelValuePrinter.vue';
import XMasBonhomme from '@/components/x-mas/XMasBonhomme.vue';
import { mapActions, mapGetters } from 'vuex';

export default defineComponent({
  components: {
    LabelValuePrinter,
    ResultCard, ContentCard, TwoFactorAuthenticationForm, TwoFactorAuthenticationSetupForm, Button, XMasBonhomme
  },
  data: () => ({
    editing2FA: false,
    token: '',
    status: 'EMAIL' as 'SMS' | 'EMAIL' | 'DISABLED'
  }),
  methods: {
    ...mapActions(['fetchUser']),
    translate,
    async setup2FA(e: { token: string, status: 'EMAIL' | 'SMS' | 'DISABLED', contact: string }) {
      if (e.status === 'DISABLED') {
        this.pushEditQuery(undefined);
        await this.fetchUser();
        return;
      }
      this.pushEditQuery('validate', { query: e });
    },
    pushEditQuery(name?: 'mode' | 'validate', options?: {
      query: { token: string, status: 'EMAIL' | 'SMS' | 'DISABLED' }
    }) {
      this.$router.push({
        query: { ...this.$route.query, edit: name, ...options?.query },
        replace: true
      });
    }
  },
  computed: {
    ...mapGetters(['user']),
    email(): string {
      if (!this.user) return translate('NO_AVAILABLE');
      const [name, domain] = this.user?.email.split('@');
      return `${name[0]}${'*'.repeat(name.length - 1)}@${domain}`;
    },
    phone() {
      if (!this.user) return translate('NO_AVAILABLE');
      let { phone } = this.user;
      if (!phone) return;
      phone = phone.replace(/\s/g, '');
      if (phone.charAt(0) === '+') {
        const start = phone.slice(0, 3);
        const end = phone.slice(-2);
        return `${start}${'*'.repeat(phone.length - 5)}${end}`;
      }
      return this.user.phone;
    },
    edit() {
      return this.$route.query.edit as string | undefined;
    },
    authMethod() {
      return login.validate2FAsetup;
    },
    created() {
      this.$router.push({ name: this.$route.name as string });
    },
    isAlready2FA() {
      return this.user.twoFactorStatus !== 'DISABLED';
    }
  }
});

</script>
