<script lang="ts" setup>
import ScoreCircleChart from '@/components/ScoreCircleChart.vue';
import ContentCard from '@/components/card/ContentCard.vue';
import Loader from '@/components/loader/Loader.vue';
import { getStatusIcon, sortChecksByKeyStringList } from '@/composables/formatKeyValuePairWithFunc';
import { translate } from '@/i18n';
import { isArray, isEmpty, isNil, isNull, isObject, merge } from 'lodash';
import { computed, onUpdated, ref } from 'vue';
import CopyToClipboard from '@/components/copy-to-clipboard/CopyToClipboard.vue';
import Button from '@/components/button/Button.vue';
import { AlertDetails, AlertDetailsButton, AlertDetailsEnumList, formatAlertObjectName } from '@/utils/scoreCompany';
import { useRoute } from 'vue-router';
import { Routes } from '@/utils/openBankingUtils';
import BoxOutputMultiRequests from '@/components/box-output/BoxOutputMultiRequests.vue';
import { ScoreFraudCompanyType } from '@/types/ScoreFraudCompanyType';
import ResultCard from '@/components/card/ResultCard.vue';

const route = useRoute();

const props = withDefaults(defineProps<{
  scoringB2B: number;
  causesB2B: ScoreFraudCompanyType;
  company: string;
  color: string;
  permissions: Array<String>;
  permissionsCompanyAlert: Array<String>;
  canCompanyScore: boolean;
}>(), {
  scoringB2B: 0,
  company: '',
  color: '#D9D9D9',
  canCompanyScore: false
});

const listA = [
  'MORE_THAN_ONE_TAX_REPORT_WITH_SAME_SUBMISSION_DATE',
  'LOCATION_RISKY',
  'DOMICILIATION',
  'WEBSITE_DOMAIN_REGISTRATION_RISKY',
  'EMAIL_DOMAIN_REGISTRATION_RISKY',
  'EMAIL_DOMAIN_EQUALS_WEBSITE_DOMAIN',
  'RADIATION',
  'COLLECTIVE_PROCEEDINGS',
  'OTHER_COMPANIES_IN_COLLECTIVE_PROCEEDINGS',
  'OTHER_COMPANIES_IN_RADIATION',
  'ACTIVITIES_CODE_RISKY',
  'HEADQUARTER_DISTANCE_TOO_HIGH',
  'LEGAL_SITUATION_FOUND',
  'MORE_THAN_ONE_TAX_REPORT_WITH_SUBMISSION_DATE_CLOSED',
  'MORE_THAN_TEN_TAX_REPORTS_WITH_DIFFERENT_SUBMISSION_DATE',
  'OTHER_COMPANIES_IN_SAME_ACTIVITY_SECTOR_IN_COLLECTIVE_PROCEEDINGS'
];

const listB = [
  'MORE_THAN_THREE_YEARS_WITHOUT_ACTIVITY',
  'LESS_THAN_X_YEARS',
  'SHARE_CAPITAL_CHANGEMENT',
  'MANAGEMENT_CHANGEMENT',
  'MULTI_MANAGEMENT_CHANGEMENT',
  'HEADQUARTER_CHANGEMENT',
  'MULTI_HEADQUARTER_CHANGEMENT',
  'ACTIVITY_CHANGEMENT',
  'MULTI_ACTIVITY_CHANGEMENT',
  'CESSATION_OF_ACTIVITY',
  'ALL_ESTABLISHMENTS_ARE_CLOSED',
  'CONTACTABILITY',
  'HEADQUARTER_ADDRESS_IN_WARNING_LIST',
  'MULTI_REGISTRATION',
  'MORE_THAN_ONE_TAX_REPORT_WITH_SAME_SUBMISSION_DATE_AND_YEAR_DIFFERENT',
  'RECENT_COLLECTIVE_PROCEEDINGS',
  'ALREADY_SOLD',
  'ALREADY_DEREGISTERED',
  'COMPANY_HISTORY',
  'MATCHING_RATIOS'
];

const listBenefits = [
  'CUSTOMER_IS_IN_REPRESENTATIVES_OR_BUSINESS_OWNERS_OR_SHARE_HOLDERS',
  'CUSTOMER_IS_IN_LINKEDIN_COMPANY',
  'TAX_REPORTS_SUBMISSION_DATES',
  'STATE_WORKFORCE_MINIMUM',
  'EMAIL_OR_WEBSITE_DOMAIN_REGISTRATION_NOT_RISKY'
];

const alertsDetailsButton = ref<AlertDetailsButton>({});
const alertDetails = ref<AlertDetails>({});

const handleDetailsAction = (label: string) => {
  if (alertsDetailsButton.value.hasOwnProperty(label)) alertsDetailsButton.value[label] = !alertsDetailsButton.value[label];
};

const isAlert = (label: string): boolean => {
  const list: string[] = [...AlertDetailsEnumList];
  return list.includes(label);
};

const isSynthesis = computed(() => route.path.includes(Routes.SYNTHESIS));

const alertDetailsMap = computed<AlertDetails>(() => {
  const map: AlertDetails = {};
  if (alertDetails.value) {
    for (const alert in alertDetails.value) {
      if (isAlert(alert)) map[alert] = alertDetails.value[alert];
    }
  }
  return map;
});

const checkListLeft = computed(() =>
  sortChecksByKeyStringList({
    checks: props.causesB2B?.checks,
    sortKeys: listA
  })
);

const checkListRight = computed(() =>
  sortChecksByKeyStringList({
    checks: props.causesB2B?.checks,
    sortKeys: listB
  })
);

const checkListBenefits = computed(() =>
  sortChecksByKeyStringList({
    checks: props.causesB2B?.bonuses,
    sortKeys: listBenefits
  })
);

const displayMultiRequest = computed(() => {
  const { email, registrationNumber, phone } = props.causesB2B.multiRequests || {};

  const allAreZero = email === 0 && phone === 0 && registrationNumber === 0;
  const missingRegNum = email === 0 && phone === 0 && !('registrationNumber' in props.causesB2B.multiRequests);

  return allAreZero || missingRegNum;
});

const getIcon = (status: string) => {
  return status === 'OK' ? 'empty-circle.png' : getStatusIcon(status);
};

const initAlertDetailsData = () => {
  AlertDetailsEnumList.map((alert) => {
    alertsDetailsButton.value[alert] = isSynthesis.value;
    const _1 = props.causesB2B?.customer?.[formatAlertObjectName(alert)];
    const _2 = props.causesB2B?.checks?.find((check: any) => check.label === alert)?.details;
    alertDetails.value[alert] = merge(_1, _2);
  });
};

onUpdated(() => {
  if (!isNil(props.causesB2B) && isObject(props.causesB2B) && !isEmpty(props.causesB2B)) {
    initAlertDetailsData();
  }
});
</script>

<template>
  <ContentCard
    v-if="!canCompanyScore"
    :title="translate('SCORING_B2B')"
    class="score-company"
    :checked-display-content="false"
    :is-padlock="true"
  />
  <template v-else>
    <ContentCard :title="translate('SCORING_B2B')" class="score-company">
      <Loader v-if="isEmpty(causesB2B)" />
      <template v-else>
        <div class="score-company-content">
          <div
            v-if="permissions?.includes('KIT_B2B_COMPANY_SCORE_SCORE')"
            class="score-company-content-score"
          >
            <ScoreCircleChart
              v-if="!isNull(scoringB2B) && isArray(scoringB2B)"
              class="circle-chart score-b2b"
              type-score="score_b2b"
              :circle-value="Math.round(<number>scoringB2B[0])"
              :circle-width="'50%'"
              :circleColor="color"
              :helper-text-display="true"
            />
          </div>
          <div v-if="causesB2B && permissionsCompanyAlert[0].length > 0" class="score-company-content-checks">
            <div class="score-company-content-checks-first">
              <div class="score-company-content-checks-display" v-for="check in checkListLeft">
                <div class="score-company-content-checks-display-block">
                  <img
                    :src="require(`/public/images/${getIcon(check.value)}`)"
                    :alt="translate(`COMPANY.CHECKS.${check.value}`)"
                    class="score-company-content-checks__icon"
                  />
                  <span>{{ translate(`COMPANY.CHECKS.${check.label}`) }}</span>
                </div>
                <Button
                  class="score-company-content-checks-alerts-button no-print"
                  v-if="isAlert(check.label) && !isEmpty(alertDetailsMap[check.label])"
                  @click="handleDetailsAction(check.label)"
                  :action-icon="alertsDetailsButton[check.label] ? 'up-arrow.png' : 'down-arrow.png'"
                  :label="translate('BUTTONS.DETAILS')" />
                <ul class="score-company-content-checks-display-details"
                    v-if="alertsDetailsButton[check.label] && alertDetailsMap[check.label]">
                  <li v-for="detail in alertDetailsMap[check.label]">
                    {{ detail }}
                    <CopyToClipboard :value="detail" />
                  </li>
                </ul>
              </div>
            </div>
            <div class="score-company-content-checks-second">
              <div
                class="score-company-content-checks-display"
                v-for="check in checkListRight"
              >
                <div class="score-company-content-checks-display-block">
                  <img
                    :src="require(`/public/images/${getIcon(check.value)}`)"
                    :alt="translate(`COMPANY.CHECKS.${check.value}`)"
                    class="score-company-content-checks__icon"
                  />
                  <span>{{ translate(`COMPANY.CHECKS.${check.label}`) }}</span>
                </div>
                <Button
                  class="score-company-content-checks-alerts-button no-print"
                  v-if="isAlert(check.label) && !isEmpty(alertDetailsMap[check.label])"
                  @click="handleDetailsAction(check.label)"
                  :action-icon="alertsDetailsButton[check.label] ? 'up-arrow.png' : 'down-arrow.png'"
                  :label="translate('BUTTONS.DETAILS')" />
                <ul
                  :class="`score-company-content-checks-display-details ${check.label === 'MATCHING_RATIOS' ? 'score-company-content-checks-display-details__matching-ratios' : ''}`"
                  v-if="alertsDetailsButton[check.label] && alertDetailsMap[check.label]">
                  <li v-for="detail in alertDetailsMap[check.label]">
                    <ul v-if="check.label === 'MATCHING_RATIOS'" v-for="year in detail.matchingYears">
                      <li class="score-company-content-checks-display-details__matching-ratios__list">
                        {{ `${translate('COMPANY.CHECKS.MATCHING_YEARS.TAX_REPORT_OF')} ${year.sourceYear} ${translate('COMPANY.CHECKS.MATCHING_YEARS.SAME_AS')} ${year.targetYear} ${translate('COMPANY.CHECKS.MATCHING_YEARS.OF_THE_COMPANY')} ${detail.registrationNumber}`
                        }}
                        <CopyToClipboard :value="detail.registrationNumber" />
                      </li>
                    </ul>
                    <template v-else>
                      {{ detail }}
                      <CopyToClipboard :value="detail" />
                    </template>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <ResultCard v-if="causesB2B && causesB2B.hasOwnProperty('bonuses') && causesB2B.bonuses.length > 0"
                    :title="translate('COMPANY.BENEFITS')">
          <div v-for="check in checkListBenefits" class="score-company-content-checks-display">
            <div class="score-company-content-checks-display-block">
              <img
                :src="require(`/public/images/${getStatusIcon(check.value)}`)"
                :alt="translate(`COMPANY.BONUSES.${check.value}`)"
                class="score-company-content-checks__icon"
              />
              <span>{{ translate(`COMPANY.BONUSES.${check.label}`) }}</span>
            </div>
          </div>
        </ResultCard>
        <BoxOutputMultiRequests v-if="!displayMultiRequest" :result="causesB2B.multiRequests" :company="company"
                                :title="translate('MULTI_REQUESTS.TITLE')" market-type="b2b" />
      </template>
    </ContentCard>
  </template>
</template>
