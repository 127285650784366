<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { translate } from '@/i18n';
import ContentCard from '@/components/card/ContentCard.vue';
import BoxOutputWarningListRegistrationNumber from '@/components/box-output/BoxOutputWarningListRegistrationNumber.vue';
import BoxInputRegistrationNumber from '@/components/box-input/BoxInputRegistrationNumber.vue';
import { isArray, isString } from 'lodash';
import { isDataAvalaible } from '@/utils/utils';

const props = withDefaults(
  defineProps<{
    showButton?: boolean;
    sirenWarningList?: Array<any>;
    showSiren?: string;
    permissions?: string[];
    default?: string;
    isDuplicate?: boolean;
    companyCountry?: string;
    isLoading?: boolean
  }>(),
  {
    showButton: true,
    showSiren: '',
    default: '',
    isDuplicate: false,
    isLoading: false
  }
);

const emit = defineEmits(['company-search']);

const store = useStore();

const responseStatus = computed(() => store.getters.responseStatus);

const hasSirenInWarningList = (): boolean => {
  return !!props.sirenWarningList?.find((warning) => {
    return isArray(warning) && (warning.includes('SIREN') || warning.includes('REGISTRATION_NUMBER'));
  });
};

const canShowWarningList = computed(
  () =>
    props.showSiren && isDataAvalaible(props.showSiren) &&
    props.permissions?.includes('KIT_B2B_WARNING_LIST_GLOBAL') &&
    !props.isDuplicate && isArray(props.sirenWarningList)
);

const companySearch = (siren: string, companyCountry: string) => {
  emit('company-search', siren, companyCountry);
};

</script>

<template>
  <ContentCard class="box-input-siren" :title="translate('NUMBER_COMPANY')">
    <BoxInputRegistrationNumber
      :showSiren="showSiren"
      :selectedCountry="companyCountry"
      @registration-number-search="companySearch"
      :is-duplicate="isDuplicate"
      :show-button="showButton"
    />
    <span class="box-input-siren__error-message"
          v-if="responseStatus.companyCheckErrorMessage">{{ responseStatus.companyCheckErrorMessage }}</span>
    <BoxOutputWarningListRegistrationNumber
      v-if="isLoading || (canShowWarningList && !isString(responseStatus.companyCheckErrorMessage))"
      :siren-warning-list="hasSirenInWarningList()" :is-loading="isLoading" :country="companyCountry"
    />
  </ContentCard>
</template>
