<script setup lang="ts">
import {nextTick, ref, watch} from "vue";

const props = withDefaults(
    defineProps<{
      modelValue: string;
      label: string;
      placeholder: string;
      required: boolean;
      readOnly: boolean;
      disabled: boolean;
      customClass: string;
      decimals?: number;
      max?: number;
    }>(), {
      modelValue: '0.00',
      label: '',
      placeholder: '0.00',
      required: false,
      readOnly: false,
      disabled: false,
      customClass: '',
      decimals: 2,
      max: Number.MAX_SAFE_INTEGER,
    });

const emits = defineEmits(['update:modelValue']);

const formattedValue = ref(formatAmount(props.modelValue || '0.00'));
const inputRef = ref<HTMLInputElement | null>(null);
const cursorPosition = ref<number | null>(null);

function formatAmount(value: string): string {
  let cleaned = value.replace(/[^\d.]/g, '');
  const parts = cleaned.split('.');
  cleaned = parts[0] + (parts.length > 1 ? '.' + parts.slice(1).join('') : '');

  let num = parseFloat(cleaned);
  if (isNaN(num) || num < 0) num = 0;

  if (num > props.max) num = props.max;

  return num.toFixed(props.decimals);
}

function parseDisplayValue(value: string): string {
  return value.replace(/[^\d.-]/g, '');
}

const onInput = (event: Event) => {
  const input = event.target as HTMLInputElement;
  cursorPosition.value = input.selectionStart;
  const rawValue = parseDisplayValue(input.value);

  if (rawValue.endsWith('.')) {
    formattedValue.value = rawValue;
    emits('update:modelValue', rawValue);
    return;
  }

  const formatted = formatAmount(rawValue);
  formattedValue.value = formatted;
  emits('update:modelValue', formatted);

  nextTick(() => {
    if (inputRef.value && cursorPosition.value !== null) {
      inputRef.value.setSelectionRange(cursorPosition.value, cursorPosition.value);
    }
  });
};

const onBlur = () => {
  formattedValue.value = formatAmount(formattedValue.value);
  emits('update:modelValue', formattedValue.value);
};

const onKeyDown = (event: KeyboardEvent) => {
  const allowed = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    '.',
    ',',
    'ArrowLeft',
    'ArrowRight',
    'ArrowUp',
    'ArrowDown',
    'Home',
    'End'
  ];

  if (/^\d$/.test(event.key)) return;

  if (event.key.startsWith('Arrow') || event.key === 'Home' || event.key === 'End') {
    return;
  }

  if (!allowed.includes(event.key)) {
    event.preventDefault();
  }

  if (event.key === ',') {
    event.preventDefault();
    const input = event.target as HTMLInputElement;
    const pos = input.selectionStart || 0;
    formattedValue.value =
        formattedValue.value.slice(0, pos) + '.' + formattedValue.value.slice(pos);

    nextTick(() => {
      if (inputRef.value) {
        inputRef.value.setSelectionRange(pos + 1, pos + 1);
      }
    });
  }
};

watch(() => props.modelValue, (newValue) => {
  if (newValue !== formattedValue.value) {
    formattedValue.value = formatAmount(newValue);
  }
});
</script>

<template>
  <div class="amount-input">
    <label :class="['label', customClass]">{{ label }}{{ required ? '*' : '' }}</label>
    <input
        ref="inputRef"
        type="text"
        inputmode="decimal"
        :value="formattedValue"
        @input="onInput"
        @blur="onBlur"
        @keydown="onKeyDown"
        :placeholder="placeholder"
        :required="required"
        :readonly="readOnly"
        :disabled="disabled"
        :class="['input']"
    />
  </div>
</template>

<style scoped>
.amount-input {
  display: flex;
  flex-direction: column;
}

.label {
  margin-bottom: 0.5rem;
}

.input {
  padding: 0.5rem;
  border-radius: 4px;
  height: 1rem;
  text-align: left;
}
</style>