<script setup lang="ts">
import { computed, nextTick, onMounted, ref } from 'vue';

const props = withDefaults(defineProps<{
  label: string;
  actionIcon: string;
  colorBackground: string;
  type?: 'reset' | 'submit' | 'button';
  disabled: boolean;
  loading: boolean;
  picture: string;
  title: string;
  outlined: boolean;
  white: boolean;
  asHighLight: boolean;
}>(), {
  label: '',
  actionIcon: '',
  colorBackground: '#293354',
  disabled: false,
  loading: false,
  picture: '',
  title: '',
  outlined: false,
  white: false,
  asHighLight: false
});

const btn = ref(null);

const isDisabled = computed(() => {
  if (props.actionIcon === 'padlock-blue.png') {
    return true;
  } else if (props.asHighLight) {
    return false;
  } else if (props.disabled || props.loading) {
    return true;
  }
});

onMounted(() => {
  nextTick(() => {
    const btnEl = btn?.value as unknown as HTMLButtonElement;
    btnEl.style.minWidth = btnEl?.offsetWidth + 'px';
  });
});
</script>

<template>
  <button
    ref="btn"
    :class="{
      button: true,
      'button-disabled': disabled || loading,
      outlined: outlined,
      white: white,
    }"
    :type="type"
    :disabled="isDisabled"
    :title="title !== '' ? title : ''"
  >
    <img v-if="picture !== ''" class="button__image" :src="`/images/${picture}`" :alt="picture" />
    <span v-if="loading" class="button__loader" />
    <template v-else>{{ label }}</template>
    <img v-if="actionIcon !== ''" :src="`/images/${actionIcon}`" class="button__icon" :alt="actionIcon" />
  </button>
</template>

<style scoped>
.button-disabled {
  background-color: #bfc0c4;
  cursor: not-allowed
}

.button-remove {
  display: none;
}
</style>