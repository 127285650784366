import api from '@/utils/api';
import envConfig from '@/utils/envConfig';

export const searchWarningList = (journeyId: string, listName: string, operator: string, data: {
  COMPANY?: string,
  REGISTRATION_NUMBER?: string,
  EMAIL?: string,
  PHONE?: string,
  COUNTRY?: string
}, label: string) =>
  api.post(`${envConfig.CHECK_VALUE_ROUTE}/v1/search/${listName}?journeyId=${journeyId}&label=${label}`, {
    'operator': operator,
    'rowValues': data
  })
    .then(response => response);

export const getAllLists = () =>
  api.get(`${envConfig.CHECK_VALUE_ROUTE}/v1/lists`)
    .then(response => response.data);

export const searchCombinedWarningList = (journeyId: string, listName: string, data: {
  REGISTRATION_NUMBER?: string,
  EMAIL?: string,
  PHONE?: string,
  COUNTRY?: string
}, label: string) => {
  return api.post(`${envConfig.CHECK_VALUE_ROUTE}/v1/search/${listName}/combined?journeyId=${journeyId}&label=${label}`, {
    'operator': 'OR',
    'operations': [
      {
        'operator': 'EQUALS',
        'key': 'EMAIL',
        'value': data.hasOwnProperty('EMAIL') ? data.EMAIL : '_',
        'operationName': 'emailMatches'
      },
      {
        'operator': 'EQUALS',
        'key': 'PHONE',
        'value': data.hasOwnProperty('PHONE') ? data.PHONE : '_',
        'operationName': 'phoneMatches'

      },
      {
        'operator': 'AND',
        'operationName': 'registrationAndCountryMatches',
        'operations': [
          {
            'operator': 'EQUALS',
            'key': 'REGISTRATION_NUMBER',
            'value': data.hasOwnProperty('REGISTRATION_NUMBER') ? data.REGISTRATION_NUMBER : '_'
          },
          {
            'operator': 'EQUALS',
            'key': 'COUNTRY',
            'value': data.COUNTRY
          }
        ]
      }
    ]
  })
    .then(response => response);
};
