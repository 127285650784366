<script setup lang="ts">
import ResultCard from "@/components/card/ResultCard.vue";
import {translate} from "@/i18n";
import {computed, onMounted, onUpdated, ref, watch, watchEffect} from "vue";
import Button from "@/components/button/Button.vue";
import CustomModal from "@/components/modal/CustomModal.vue";
import {disabledOpenBankingLink, refreshOpenBankingLink} from "@/apiCalls/openbankingApi";
import {isEmpty} from "lodash";
import {ObData, OpenBankingTask} from "@/utils/openBankingUtils";
import {useToast} from "vue-toast-notification";
import {useRoute} from "vue-router";
import Loader from "@/components/loader/Loader.vue";

const props = withDefaults(
    defineProps<{
      obData: ObData;
      refreshLoader: boolean;
      disableLoader: boolean;
    }>(),
    {
      refreshLoader: false,
      disableLoader: false,
    }
);

const openModal = ref(false);
const route = useRoute();

const isRefreshable = computed(()=> props.obData.isRefreshable);

const emit = defineEmits(['refresh-task', 'disable-continuous-access']);

const values = computed(() => [
  {
    title: translate("OPEN_BANKING.CONTINUOUS_ACCESS.BOX.DATA.FIRST_AGGREGATION_DATE"),
    value: props.obData.dateTimeFirstAggregation,
  },
  {
    title: translate("OPEN_BANKING.CONTINUOUS_ACCESS.BOX.DATA.LAST_AGGREGATION_DATE"),
    value: props.obData.dateTimeLastAggregation,
  },
  {
    title: translate("OPEN_BANKING.CONTINUOUS_ACCESS.BOX.DATA.EXPIRATION_DATE"),
    value: props.obData.dateTimeConsentExpiration,
  },
  {
    title: translate("OPEN_BANKING.CONTINUOUS_ACCESS.BOX.DATA.REFRESH_COUNT"),
    value: props.obData.refreshCount,
  },
  {
    title: translate("OPEN_BANKING.CONTINUOUS_ACCESS.BOX.DATA.FIRST_REFRESH_DATE"),
    value: props.obData.dateTimeFirstRefresh,
  },
  {
    title: translate("OPEN_BANKING.CONTINUOUS_ACCESS.BOX.DATA.LAST_REFRESH_DATE"),
    value: props.obData.dateTimeLastRefresh,
  },
  {
    title: translate("OPEN_BANKING.CONTINUOUS_ACCESS.BOX.DATA.DISABLED_REFRESH_DATE"),
    value: props.obData.dateTimeDeactivation,
  },
]);

const showButtons = computed(() => {
  return isRefreshable.value && route.path !== '/synthesis';
});

const refreshDataAction = async () => {
  emit('refresh-task');
};
const confirmDisableAction = async () => {
  emit('disable-continuous-access');
  openModal.value = false;
};

</script>

<template>
  <ResultCard :title="translate('OPEN_BANKING.CONTINUOUS_ACCESS.BOX.TITLE')" class="data-refresh">
    <div class="data-refresh-content">
      <div class="data-refresh-content-column">
        <template v-for="(value, index) in values">
          <span :key="index" v-if="index < 3">
            {{ `${value.title}: ${value.value}` }}
          </span>
        </template>
      </div>
      <div class="data-refresh-content-column">
        <template v-for="(value, index) in values">
          <span :key="index" v-if="index >= 3 && index < 7">
            {{ `${value.title}: ${value.value}` }}
          </span>
        </template>
      </div>
      <div v-if="showButtons" class="data-refresh-content-column">
        <Button
            class="data-refresh-content-actions-button"
            type="button"
            :loading="props.refreshLoader"
            :disabled="props.disableLoader"
            :label="translate('OPEN_BANKING.CONTINUOUS_ACCESS.BOX.ACTIONS.REFRESH')"
            @click="refreshDataAction"
        />
        <Button
            class="button data-refresh-modal-actions__disable"
            type="button"
            :loading="props.disableLoader"
            :disabled="props.refreshLoader"
            :label="translate('OPEN_BANKING.CONTINUOUS_ACCESS.BOX.ACTIONS.DISABLE_REFRESH')"
            @click="() => openModal = true"
        />
      </div>
    </div>
    <div class="data-refresh-modal">
      <CustomModal v-model:open="openModal" @close-modal="() => openModal = false">
        <ResultCard :title="translate('OPEN_BANKING.CONTINUOUS_ACCESS.BOX.MODAL.TITLE')" :display-arrows="false">
          <div class="data-refresh-modal-description">
            {{ translate('OPEN_BANKING.CONTINUOUS_ACCESS.BOX.MODAL.1ST_DESCRIPTION') }}
          </div>
          <div class="data-refresh-modal-description">
            {{ translate('OPEN_BANKING.CONTINUOUS_ACCESS.BOX.MODAL.2ND_DESCRIPTION') }}
          </div>
          <div class="data-refresh-modal-actions">
            <Button
                class="cancel"
                type="button"
                :label="translate('BUTTONS.CANCEL')"
                @click="() => openModal = false"
            />
            <Button
                class="button data-refresh-modal-actions__disable"
                type="button"
                :label="translate('BUTTONS.CONFIRM')"
                @click="confirmDisableAction"
            />
          </div>
        </ResultCard>
      </CustomModal>
    </div>
  </ResultCard>
</template>
