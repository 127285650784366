import { uniq } from 'lodash';

import api from '@/utils/api';
import envConfig from '@/utils/envConfig';
import { FaceMatchingResponseDto } from '@/types/FaceMatching';

export type UploadMode = 'IFRAME' | 'PHONE' | 'EMAIL';

export type DocumentTypeDto =
  | 'BANK_DETAILS'
  | 'BANK_STATEMENT'
  | 'KBIS'
  | 'OTHER'
  | 'PAYSLIP'
  | 'TAX_NOTICE'
  | 'TAX_REPORT'
  | 'TAX_REPORT_ANALYSIS'
  | 'TAX_NOTICE_ANALYSIS';

type UploadLinkDocumentTypeRequestDto = {
  documentType: DocumentTypeDto;
  withAuthenticity: boolean;
  withAnalysis: boolean;
};

type UploadLinkRequestDto = {
  documents: UploadLinkDocumentTypeRequestDto[];
  uploadMode: UploadMode;
  callbackUrl?: string;
  blocking?: boolean;
  contacts?: string[];
};

export const makeUploadFile = (file: any, documentType: string) => {
  const formData = new FormData();
  formData.append('documentType', documentType);
  formData.append('file', file);
  return formData;
};

export const uploadIdentityDocument = async (journeyId: string, file: any) => {
  const formData = makeUploadFile(file, 'ID_CHECK') as any;
  const doc = formData.get('document') as string;
  return await api.post(
    `${envConfig.FRISK_ROUTE}/v2/journeys/${journeyId}/documents/validate?details=true&store=true`,
    formData,
    {
      timeout: 30000,
      headers: {
        Accept: '*/*',
        'Content-Type': 'multipart/form-data',
        'Content-Length': doc?.length
      }
    }
  );
};

export const createIframeUploadDocument = (
  journeyId: string,
  documentType: string,
  uploadMode: 'IFRAME' | 'PHONE' | 'EMAIL',
  data?: string
) => {
  return api.post(
    `${envConfig.UPLOAD_ROUTE}/v2/upload/multiple/link?journeyId=${journeyId}&documentTypes=${documentType}&uploadMode=${uploadMode}`,
    !!data ? [data] : undefined,
    {
      timeout: 30000,
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json'
      }
    }
  );
};

export const createIframeUploadDocumentWithAuthenticity = (
  journeyId: string,
  data: UploadLinkRequestDto
) => {
  return api.post(
    `${envConfig.UPLOAD_ROUTE}/v2/upload/multiple/link/authenticity?journeyId=${journeyId}`,
    data,
    {
      timeout: 30000,
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json'
      }
    }
  );
};

export const resendLinkWebUpload = (
  journeyId: string,
  uploadMode: 'IFRAME' | 'PHONE' | 'EMAIL',
  data?: string
) => {
  return api.post(
    `${envConfig.UPLOAD_ROUTE}/v2/upload/multiple/resend?journeyId=${journeyId}&mode=${uploadMode}`,
    !!data ? [data] : undefined,
    {
      timeout: 30000,
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json'
      }
    }
  );
};

export const uploadDocument = async (
  journeyId: string,
  type: string,
  file: any
) => {
  const formData = makeUploadFile(file, type) as any;
  const doc = formData.get('document') as string;
  return await api.post(
    `${envConfig.DOCUMENT_ROUTE}/upload-document?journeyId=${journeyId}&documentType=${type}`,
    formData,
    {
      timeout: 30000,
      headers: {
        Accept: '*/*',
        'Content-Type': 'multipart/form-data',
        'Content-Length': doc?.length
      }
    }
  );
};

export const authenticityDocument = async (
  journeyId: string,
  documentId: string,
  needPdfReport: boolean = false
) => {
  return await api.put(
    `${envConfig.DOCUMENT_ROUTE}/v2/${documentId}/authenticity?journeyId=${journeyId}&needPdfReport=${needPdfReport}`
  );
};

export const analyseTaxNoticeDocument = async (
  journeyId: string,
  documentId: string
) => {
  return await api.put(
    `${envConfig.TAX_NOTICE_ANALYSIS_ROUTE}/v1/check_tax_notice/${documentId}?journeyId=${journeyId}`,
    {
      timeout: 30000
    }
  );
};

export const getOcrFilesIdsFromContextData = (res: any) => {
  let ocrFilesIds: any[] = [];
  const contextsToCheck = [
    'id_check_data',
    'tax_report_data',
    'kbis_data',
    'authenticity_result',
    'tax_notice_data',
    'payslip_data',
    'tax_reports_result'
  ];
  contextsToCheck
    .filter((elt) => Object.keys(res).includes(elt))
    .forEach((e: string) => {
      res[e].forEach((doc: any) => {
        doc?.documentIds && ocrFilesIds.push(doc.documentIds);
        doc?.certificateDocumentId && ocrFilesIds.push(doc.certificateDocumentId);
      });
    });
  return uniq(ocrFilesIds.flat(1));
};

export const isAuthenticityDone = (contextData: any) => {
  for (let i = 0; i < contextData.authenticity_result?.length; i++) {
    if (contextData?.authenticity_result[i]?.status === 'IN_PROGRESS') {
      return false;
    }
  }
  return true;
};

const getInProgressFileIds = (contextData: any) => {
  const inProgressFiles = contextData.web_upload_link_result?.filter(
    (el: any) => el.status === 'IN_PROGRESS'
  );
  const inProgressFileIds = inProgressFiles?.map((el: any) =>
    Object.values(el?.value?.uploads)
      .flat(1)
      .map((val: any) => val.documentId)
  );
  return inProgressFileIds ? inProgressFileIds?.flat(1) : [];
};

export const allUploadedFilesOcrDone = (contextData: any) => {
  if (!isAuthenticityDone(contextData)) {
    return false;
  }

  const allUploadedFiles = contextData.file ? contextData.file?.map((el: any) => el?.document?.id)?.filter((el: any) => el) : [];

  const inProgressFileIds = getInProgressFileIds(contextData);

  const uploadedFiles = allUploadedFiles.filter((el: string) => !inProgressFileIds.includes(el))

  const ocrFilesIds = getOcrFilesIdsFromContextData(contextData);

  for (const a of uploadedFiles) {
    if (!ocrFilesIds.includes(a)) {
      return false;
    }
  }
  return true;
};

export const DOCUMENTS_TIMEOUT: any = {
  BANK_DETAILS: 45,
  KBIS: 45,
  ID_CARD: 45,
  ID_CHECK: 45,
  RESIDENCE_PERMIT: 45,
  DRIVING_LICENSE: 45,
  TAX_REPORT: 45,
  TAX_NOTICE: 45,
  PASSPORT: 45,
  OTHER: 300
};

export const runFaceMatching = async (
  mobileNumber: string,
  journeyId: string
) => {
  return await api.put<FaceMatchingResponseDto>(
    `${envConfig.DOCUMENT_ROUTE}/v2/face-matching/ID_CHECK?journeyId=${journeyId}&language=fr&provider=OPTION_3`,
    {
      mobileNumber
    }
  );
};

export default {
  uploadDocument,
  uploadIdentityDocument,
  createIframeUploadDocument,
  authenticityDocument,
  DOCUMENTS_TIMEOUT,
  isAuthenticityDone
};
