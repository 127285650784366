<script setup lang="ts">
import { translate } from '@/i18n';
import Tooltip from '@/components/Tooltip.vue';
import Loader from '@/components/loader/Loader.vue';
import { isNull } from 'lodash';
import { formatLocaleDateTimeUTC } from '@/utils/dateTime';

withDefaults(
  defineProps<{
    contentColumns: Object;
    titles?: Object,
    isLoading: boolean
  }>(), {
    isLoading: false
  }
);

const emit = defineEmits(['delete-folder']);

const deleteFolder = (item: Object) => {
  emit('delete-folder', item);
};

const displayFolderType = (businessId: string) => {
  return ['portal-b2c', 'portal-b2b', 'document-analysis', 'open-banking'].includes(businessId) ? translate('FOLDER.TYPE.WEB_FOLDER') : businessId === 'meelo-journey' ? translate('FOLDER.TYPE.JOURNEY_FOLDER') : translate('FOLDER.TYPE.API_FOLDER');
};

const displayIconFolderType = (businessId: string) => {
  return ['portal-b2c', 'portal-b2b', 'document-analysis', 'open-banking', 'meelo-journey'].includes(businessId) ? 'monitor' : 'cloud';
};
</script>
<template>
  <div>
    <div class="whole-table">
      <table>
        <thead>
        <tr>
          <th v-for="colsTitle in titles" scope="col" v-bind:key="colsTitle">{{ colsTitle }}</th>
        </tr>
        </thead>
        <tbody>
        <template v-if="!isLoading">
          <tr v-for="(item, index) in contentColumns" :key="index">
            <td
              v-for="(value, key) in Object.keys(item).filter(elt => !['journeyId', 'path', 'synthesis', 'clientId'].includes(elt))"
              :data-label="key"
              :key="key + index">
              <template v-if="value === 'iban' && item[value] !== '-'">
                <span>
                  <img
                    :src="
                      require(`/public/images/${
                        item[value] === 'OK'
                          ? 'success-icon.png'
                          : item[value] === 'WARNING'
                          ? 'warning-icon.png'
                          : 'failed-icon.png'
                      }`)
                    "
                    :alt="`${item[value].toLowerCase()}-icon`"
                    class="check-iban-result__icon"
                  />
                </span>
              </template>
              <template v-else-if="value === 'date'">
                {{ formatLocaleDateTimeUTC(item[value]) }}
              </template>
              <template v-else-if="value === 'warning_list' && item[value] === translate('YES')">
                <img :src="require('/public/images/flag.png')" class="flag-historical" alt="flag" />
              </template>
              <template v-else-if="value === 'ocrAnalysisStatus' || value === 'authenticityStatus'">
                <img :src="require(`/public/images/${item[value]}`)" class="flag-historical" :alt="item[value]" />
              </template>
              <template v-else-if="value === 'status'">
                {{ !isNull(item[value]) ? translate(`FOLDER.STATE.${item[value]}`) : '-' }}
              </template>
              <template v-else-if="value === 'businessId'">
                <div class="historical-business-id">
                  <span>{{ item['businessId'] }}</span>
                  <div class="historical-folder-type">
                    <img :src="require(`/public/images/${displayIconFolderType(item['clientId'])}.png`)"
                         :alt="displayIconFolderType(item['clientId'])"
                         class="historical-folder-type__icon" />
                    <span>{{ displayFolderType(item['clientId']) }}</span>
                  </div>
                </div>
              </template>
              <template v-else>{{ item[value] }}</template>
            </td>
            <td>
              <div class="action-column">
                <router-link
                  v-if="!['ACCEPTED', 'REFUSED', 'CANCELED'].includes(item['status']) && ['portal-b2c', 'portal-b2b', 'document-analysis', 'open-banking'].includes(item['clientId'])"
                  :to="`/${item['path']}?journeyId=${item['journeyId']}`">
                  <Tooltip :text="translate('TOOLTIP.EDIT')" position="top">
                    <img :src="require('/public/images/edit.png')" alt="continue-analysis" class="edit-historical" />
                  </Tooltip>
                </router-link>
                <Tooltip v-else-if="['ACCEPTED', 'REFUSED', 'CANCELED'].includes(item['status'])"
                         :text="translate('TOOLTIP.BLOCK_FOLDER')" position="top">
                  <img :src="require('/public/images/padlock-blue.png')" alt="padlock-blue" class="edit-historical" />
                </Tooltip>
                <router-link v-if="['analysis-b2c', 'analysis-b2b'].includes(item['path'])"
                             :to="`/${item['path']}?journeyId=${item['journeyId']}&duplicate=${true}`">
                  <Tooltip :text="translate('TOOLTIP.DUPLICATE')" position="top">
                    <img :src="require('/public/images/duplicate.png')" alt="duplicate" class="duplicate-historical" />
                  </Tooltip>
                </router-link>
                <router-link :to="`/${item['synthesis']}?journeyId=${item['journeyId']}`">
                  <Tooltip :text="translate('TOOLTIP.VIEW')" position="top">
                    <img :src="require('/public/images/eye.png')" alt="read-only" class="eye-historical" />
                  </Tooltip>
                </router-link>
                <Tooltip v-if="!['document-analysis'].includes(item['path'])" :text="translate('TOOLTIP.DELETE_FOLDER')"
                         position="top"
                         @click="deleteFolder(item)">
                  <img :src="require('/public/images/blue-bin.png')" alt="delete-folder"
                       class="delete-folder-historical">
                </Tooltip>
              </div>
            </td>
          </tr>
        </template>
        </tbody>
      </table>
    </div>
    <Loader v-if="isLoading" />
  </div>
</template>
