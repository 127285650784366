<script setup lang="ts">
import ContentCard from "@/components/card/ContentCard.vue";
import {translate} from "@/i18n";
import Button from "@/components/button/Button.vue";
import {computed, onMounted, onUpdated, reactive, ref, watch} from "vue";
import Loader from "@/components/loader/Loader.vue";
import {OpenBankingInstantPayment, Routes} from "@/utils/openBankingUtils";
import {useRoute} from "vue-router";
import {isEmpty, size} from "lodash";

const props = withDefaults(
    defineProps<{
      instantPayments: OpenBankingInstantPayment[];
      loading: boolean;
      refreshingDataLoader: boolean;
    }>(), {
      loading: false,
      refreshingDataLoader: false
    }
);

const emit = defineEmits(["new-instant-payment", "refresh-instant-payment"]);
const route = useRoute();
const loading = ref(false);

const startNewInstantPayment = () => {
  emit('new-instant-payment');
}

const refreshInstantPayment = () => {
  emit('refresh-instant-payment');
}

const values = computed(() =>
    [
      {
        header: `${translate('OPEN_BANKING.INSTANT_PAYMENT.FORM.LABEL')} : `,
        value: (v: OpenBankingInstantPayment) => v?.paymentProfile?.label,
        pane: 'left-pane',
        bold: true
      },
      {
        header: `${translate('OPEN_BANKING.INSTANT_PAYMENT.FORM.AMOUNT')} : `,
        value: (v: OpenBankingInstantPayment) => v?.paymentProfile?.amount + " " + v?.paymentProfile?.currencyCode,
        pane: 'left-pane',
        bold: true
      },
      {
        header: `${translate('OPEN_BANKING.INSTANT_PAYMENT.FORM.RECIPIENT')} : `,
        value: (v: OpenBankingInstantPayment) => v?.paymentProfile?.name,
        pane: 'left-pane'
      },
      {
        header: `${translate('OPEN_BANKING.INSTANT_PAYMENT.FORM.STATUS')} : `,
        icon: (v: OpenBankingInstantPayment) => v?.status === 'SUCCESS' ? 'success-icon.png' : v?.status === 'FAILED' ? 'failed-icon.png' : 'open-banking/hourglass.png',
        value: (v: OpenBankingInstantPayment) =>  translate(`OPEN_BANKING.INSTANT_PAYMENT.STATUS.${v.status}`),
        pane: 'left-pane'
      },
      {
        header: `${translate('OPEN_BANKING.INSTANT_PAYMENT.FORM.INFORMATION')} : `,
        isHeader: true,
        pane: 'left-pane'
      },
      {
        value: (v: OpenBankingInstantPayment) => v?.requestOptions?.email,
        pane: 'left-pane'
      },
      {
        value: (v: OpenBankingInstantPayment) => v?.requestOptions?.phone || v?.requestOptions?.phoneNumber,
        pane: 'left-pane'
      }
    ]);

const isSynthesis = computed(() => {
  return route.path === Routes.SYNTHESIS;
});

const canRefresh = computed(() => {
  return props.instantPayments.some((payment) => payment.status === 'CREATED') && !isEmpty(props.instantPayments);
});

watch(() => props.loading, (newValue) => {
  loading.value = newValue;
});
</script>

<template>
  <ContentCard class="instant-payment" :title="translate('OPEN_BANKING.INSTANT_PAYMENT.TITLE')">
    <div class="instant-payment-container">
      <div class="instant-payment-action">
        <template v-if="!isSynthesis">
          <Button
              @click="startNewInstantPayment"
              :label="translate('OPEN_BANKING.INSTANT_PAYMENT.BUTTON.START')"
              picture="plus.png"
              class="instant-payment-start-btn"
          />
          <Button
              @click="refreshInstantPayment"
              :label="translate('OPEN_BANKING.INSTANT_PAYMENT.BUTTON.REFRESH')"
              picture="refresh.png"
              :disabled="!canRefresh"
              :loading="refreshingDataLoader"
              class="instant-payment-refresh-btn"
          />
          <div class="horizontal-divider"/>
        </template>
      </div>
      <div class="instant-payment-payments">
        <Loader v-if="loading" />
        <span v-if="size(instantPayments) === 0 && !loading" >{{ translate("OPEN_BANKING.INSTANT_PAYMENT.PAYMENTS.NO_DATA") }}</span>
        <template v-else v-for="payment in instantPayments" :key="payment.id">
          <div class="payment-grid">
            <div class="left-pane">
              <template v-for="item in values.filter(v => v.pane === 'left-pane')" :key="item.header">
                <div class="payment-item">
                  <span class="grid-header">{{ item.header }}</span>
                  <span :class="item.bold ? 'grid-value-bold' : 'grid-value' ">
                      <img v-if="item.icon" class="grid-value-icon" :src="require(`/public/images/${item.icon(payment)}`)" alt="icon"/>
                      {{ payment && item.value ? item.value(payment) : ''  }}
                  </span>
                </div>
              </template>
            </div>
            <div class="right-pane">
              <template v-for="item in values.filter(v => v.pane === 'right-pane')" :key="item.header">
                <span v-if="item.isHeader" class="grid-header">{{ item.header }}</span>
                <span v-else class="grid-value">{{ payment && item.value ? item.value(payment) : '' }}</span>
              </template>
            </div>
          </div>
        </template>
      </div>
    </div>
  </ContentCard>
</template>